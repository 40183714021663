import { Call, ApiUrl, ParseGeneralResponse, PageParams } from './ApiUtils';
import AppConfig from '../config/AppConfig';

export function fetchUsersList(
  page = 1,
  perPage = AppConfig.perPage,
  name='',
  id = '',
) {
  const url = ApiUrl(
    `/unytag/superAdmin/user?${PageParams(
      page,
      perPage,
    )}&search=${encodeURIComponent(name)}&sortBy=${id}`,
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}

export function fetchTripHistoryList(id,search, page = 1,
  perPage = AppConfig.perPage,orderName = '',order='') {
  const url = ApiUrl(`/unytag/trip/details/${id}?&${PageParams(page, perPage)}&search=${search}&sortBy=${orderName}&sortOrder=${order}`);
  return Call('post', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta',adminsCount:'adminsCount' },
  });
}

export function deleteUserById(id) {
  const url = ApiUrl(`/unytag/superAdmin/user/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function getDashboardsListApi() {
  const url = ApiUrl(`/unytag/dashboard`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function getUserDetails(id) {
  const url = ApiUrl(`/unytag/superAdmin/user/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}
export function updateUserDetails(formData, id) {
  const url = ApiUrl(`/unytag/superAdmin/user/${id}`);
  return Call('put', url, formData, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function registerNewUser(formData,token) {
  const url = ApiUrl(`/unytag/superAdmin/user?token=${token}`);
  return Call('post', url, formData, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  },false,);
}

export function checkRegisteredMailStatus(email) {
  const url = ApiUrl(`/unytag/invite/status?email=${email}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  },false,);
}
export function verifyRegisteredUser(email,id,type) {
  const url = ApiUrl(`/unytag/user/invite/validate?email=${email}&companyId=${id}&accountType=${type}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function fetchNotifications(id, page, size) {
  const url = ApiUrl(`/unytag/messages/${id}/?page=${page}&size=${size}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function markNotificationAsRead(id) {
  const url = ApiUrl(`/unytag/messages/${id}`);
  return Call('put', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function deleteNotification(id) {
  const url = ApiUrl(`/unytag/messages/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export const handleNotification = (notifications, clearMessages) => {
    return {
        type: 'NOTIFICATION',
        payload: notifications,
        clearMessages: clearMessages
    }
}
export const handleNotifCount = (count, add) => {
    return {
        type: 'NOTIF_COUNTER',
        payload: count,
        add: add
    }
}
export const handleBreadcrumbLinks = (links) => {
    return {
        type: 'BREADCRUMB_LINKS',
        payload: links
    }
}
import React, { useEffect } from 'react';
import { Input, Icon } from '../../common/AntdUiComponents';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const ReuseInputField = ({
  type,
  placeholder,
  icon,
  width,
  className,
  onChange,
  value,
  height,
  onBlur,
  disabled,
  min,
  addonBefore,
  addonAfter
}) => {
  const [secure, setSecure] = React.useState(true);
  const [inputType, setInputType] = React.useState('');

  useEffect(() => {
    setInputType(type);
  }, []);

  const handleChange = (event) => {
    // eslint-disable-next-line no-shadow
    const { value } = event.target;
    if (onChange) onChange(value);
  };

  const handlePasswordToggle = () => {
    setInputType(secure? 'text' : 'password');
    setSecure(!secure);
  }

  const handlePasswordIcon = () => {
      if (secure) {
        return <EyeOutlined className='password-icon' onClick={() => handlePasswordToggle()}/>
      } else {
        return <EyeInvisibleOutlined className='password-icon' onClick={() => handlePasswordToggle()}/>
      }
  }

  return (
    <div style={{ display: 'inline' }}>
      <Input
        type={inputType}
        autoComplete='new-password'
        placeholder={placeholder}
        prefix={<Icon type={icon} style={{ fontSize: 13 }} />}
        suffix={ type == 'password' && handlePasswordIcon()}
        style={{ width: `${width}`, height: `${height}` }}
        className={`Input-Field ${className}`}
        value={value}
        defaultValue={value}
        disabled={disabled}
        onBlur={onBlur}
        min={min}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        onChange={handleChange}
      />
    </div>
  );
};

export default ReuseInputField;

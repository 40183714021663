import React from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import Dashboard from './containers/Dashboard';
import 'antd/dist/antd.css';
import './stylesheets/localfonts.css';
import './stylesheets/application.scss';
import Login from './containers/Login';
import Profile from './containers/Profile';

// window.onbeforeunload = function() {
//   localStorage.clear();
// }
function App() {
  const location = useLocation();

  const isAuth = localStorage.getItem('token'); // i will fetch stored token

  return (
    <div className="App">
      {isAuth && location.pathname !== '/unytag/web/profile' && <Dashboard />}
      {isAuth && location.pathname === '/unytag/web/profile' && <Profile />}

      {!isAuth && <Login />}
    </div>
  );
}

export default App;

/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Divider,
  Button,
  Spin,
  Input,
} from '../../../common/AntdUiComponents';
import BaseModal from '../BaseModal';
import ReuseButton from '../ReuseButton';
import Card from '../AddCard';
import {
  addPayments,
  fetchPayments,
  updatePayments,
  deletePayment,
  setPrimaryPayment
} from '../../../api/enterprise/enterprise';
import { alertMessage } from '../../../common/Common';
import visa from '../../../images/cards/visa.png'
import master from '../../../images/cards/master.png'
import diners from '../../../images/cards/Dinners.png'
import unionpay from '../../../images/cards/union.png'
import discover from '../../../images/cards/Discover.png'
import cartes from '../../../images/cards/cartes.png'
import jcb from '../../../images/cards/jcb.png'
import amex from '../../../images/cards/amex.png'

const PaymentMethod = ({ type }) => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  // const [showUpdateModal,setShowUpdateModal]=useState(false)
  const [inProgress, setInProgress] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [error, showError] = useState(false);
  const [yearError, showYearError] = useState(false);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const params = useParams();
  const user = localStorage.getItem('user');
  const userDetails = user !== '' ? JSON.parse(user) : {};

  const getPayments = () => {
    if(type=='enterprise'){
      setInProgress(true);
    fetchPayments(userDetails.userType==='UnyTag'?params.id:userDetails.companyId).then((result) => {
      if (result.success) {
        setInProgress(false);
        setPaymentData(result.data);
      } else {
        alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setInProgress(false);
        setPaymentData([])
      }
    });
  }
  else {
    setInProgress(false)
    setPaymentData([])
  }
  };

  useEffect(() => {
    getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewCard = () => {
    setShowViewModal(true);
  };
  const updateCard = (id) => {
    setMonth(paymentData.filter((x) => x.id == id).map((y) => y.expMonth))
    setYear(paymentData.filter((x) => x.id == id).map((y) => y.expYear))
    // setShowUpdateModal(true);
    setShowViewModal(true);
    setisUpdate(true);
    setPaymentId(id);
  };
  const handleToken = (tokenObj) => {
    const formData = {};
    const user = localStorage.getItem('user');
    const userDetails = user !== '' ? JSON.parse(user) : {};
    formData.paymentMethodId = tokenObj.id;
    // formData.customerId = type === 'enterprise' ? params.id : 0;
    formData.customerId = userDetails.companyId;
    if (!isUpdate) {
      addPayments(formData).then((result) => {
        if (result.success) {
          getPayments();
          alertMessage('CARD HAS BEEN ADDED SUCCESSFULLY', 'success', 3);
          setPaymentId('');
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
          setPaymentId('');
        }
      });
    }
    else {
      updatePayments(paymentId, formData).then((result) => {
        if (result.success) {
          getPayments();
          alertMessage('CARD HAS BEEN UPDATED SUCCESSFULLY', 'success', 3);
          setPaymentId('');
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
          setPaymentId('');
        }
      });
    }
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
  };

  // const handleOnUpdateClose = () => {
  //   setShowUpdateModal(false);
  // };

  // const monthChange = (e) => {
  //   setMonth(e.target.value);
  //   if (e.target.value > 12) {
  //     showError(true);
  //   } else {
  //     showError(false);
  //   }
  // };
  // const yearChange = (e) => {
  //   setYear(e.target.value);
  //   if (e.target.value < moment().year()) {
  //     showYearError(true);
  //   } else {
  //     showYearError(false);
  //   }
  // };

  const checkCard = (card) => {
    switch (true) {
      case card === 'visa':
        return <img className='cardImage' src={visa} alt='visa' />
      case card === 'mastercard':
        return <img className='cardImage' src={master} alt='master' />
      case card === 'amex':
        return <img className='cardImageAmex' src={amex} alt='amex' />
      case card === 'discover':
        return <img className='cardImage' src={discover} alt='amex' />
      case card === 'diners':
        return <img className='cardImage' src={diners} alt='amex' />
      case card === 'jcb':
        return <img className='cardImage' src={jcb} alt='amex' />
      case card === 'unionpay':
        return <img className='cardImage' src={unionpay} alt='amex' />
    }
  }

  // const handleSubmit = () => {
  //   setInProgress(true);
  //   const formData = {};
  //   formData.enterpriseId = type === 'enterprise' ? params.id : 0;
  //   formData.expMonth = month;
  //   formData.expYear = year;
  //   formData.userId = type === 'enterprise' ? 0 : params.id;
  //   updatePayments(formData).then((result) => {
  //     if (result.success) {
  //       setInProgress(false);
  //       alertMessage('CARD HAS BEEN UPDATED SUCCESSFULLY', 'success', 3);
  //       // handleOnUpdateClose();
  //       getPayments();
  //     } else{
  //       alertMessage(
  //         result.errors.message.errorMessage
  //           .substr(0, result.errors.message.errorMessage.indexOf('.'))
  //           .toUpperCase(),
  //         'error',
  //         3,
  //       );
  //       setInProgress(false);
  //     }
  //   });
  // };
  const deleteCard = (id) => {
    setInProgress(true)
    deletePayment(id).then((result) => {
      if (result.success) {
        setInProgress(false);
        alertMessage('CARD HAS BEEN DELETED SUCCESSFULLY', 'success', 3);
        getPayments();
      } else {
        alertMessage(
          result.errors.message.errorMessage
            .substr(0, result.errors.message.errorMessage.indexOf('.'))
            .toUpperCase(),
          'error',
          3,
        );
        setInProgress(false);
      }
    })
  }

  const setCardasPrimary = (id) => {
    setInProgress(true);
    const formData = {};
    const user = localStorage.getItem('user');
    const userDetails = user !== '' ? JSON.parse(user) : {};
    formData.customerId = userDetails.companyId;
    formData.paymentId = id;
    setPrimaryPayment(formData).then((result) => {
      if (result.success) {
        setInProgress(false);
        alertMessage('PRIMARY CARD HAS BEEN UPDATED SUCCESSFULLY', 'success', 3);
        // handleOnUpdateClose();
        getPayments();
      } else {
        alertMessage(
          result.errors.message.errorMessage
            .substr(0, result.errors.message.errorMessage.indexOf('.'))
            .toUpperCase(),
          'error',
          3,
        );
        setInProgress(false);
      }
    });
  }


  const renderViewModel = () => {
    return (
      <BaseModal width="40%" onCancel={() => handleOnModalClose()}>
        <Card closeModal={handleOnModalClose} sendToken={handleToken} isUpdate={isUpdate} />
      </BaseModal>
    );
  };
  // const renderUpdateModel = () => {
  //   return (
  //     <BaseModal width="40%" onCancel={() => handleOnUpdateClose()}>
  //       <Row className="swal-footer">
  //         <Col lg={24} className="cardHeader">
  //           Update Expiry Date
  //         </Col>
  //         <Col lg={24}>
  //           <b> Expiry Details :</b>
  //           &nbsp;
  //           <Input
  //             type="text"
  //             placeholder="MM"
  //             autoComplete='new-password'
  //             maxLength="2"
  //             value={month}
  //             style={{ width: '10%', marginLeft: '20px', marginRight: '20px' }}
  //             onChange={monthChange}
  //           />
  //           <Input
  //             type="text"
  //             placeholder="YYYY"
  //             autoComplete='new-password'
  //             maxLength="4"
  //             value={year}
  //             style={{ width: '14%', marginRight: '20px' }}
  //             onChange={yearChange}
  //           />
  //         </Col>
  //         <Col lg={24} className="errorMessage pad-top-15">
  //           {error && <span>Month can't be greater than 12</span>} &nbsp;
  //           {yearError && <span>Year can't be less than current year</span>}
  //         </Col>
  //         <Col lg={24}>
  //           <ReuseButton
  //             text="UPDATE"
  //             disabled={year === '' || month === '' || error || yearError}
  //             className="btn-style  enterpriseOptionbuttons btn-success"
  //             onClick={() => {
  //               handleSubmit();
  //             }}
  //           />
  //         </Col>
  //       </Row>
  //     </BaseModal>
  //   );
  // };

  return (
    <div className="Basecontainer">
      <Spin spinning={inProgress}>
        <Row gutter={30}>
          <Col offset={6} span={12} className="tablePadding mar-top-20">
            <p className="TitleText mar-bottom-20">
              {params.id == userDetails.companyId ?
                " View your payment information here." :
                ` ${type === 'enterprise'
                  ? " View the enterprise's payment information here."
                  : " View the users's payment information here."}`
              }

            </p>
          </Col>
          {!(userDetails.userType==='UnyTag') &&  <Col span={6} className="tablePadding mar-top-20 mar-bottom-20">
            <p className="TitleText mar-bottom-20">
              <Button
                className="floatRight btn-style enterpriseOptionbuttons btn-info mar-lt-10"
                onClick={addNewCard}
              >
                ADD PAYMENT METHOD
             </Button>
              {/* {(paymentData.message === undefined && params.id == userDetails.companyId && params.name == userDetails.company ) && (
                <Button
                  className="floatRight btn-style  enterpriseOptionbuttons btn-success"
                  onClick={updateCard}
                >
                  UPDATE CARD DETAILS
                </Button>
              )}
              {(paymentData.message === undefined && params.id == userDetails.id && params.name == userDetails.firstName + ' ' + userDetails.lastName) && (
                <Button
                  className="floatRight btn-style  enterpriseOptionbuttons btn-success"
                  onClick={updateCard}
                >
                  UPDATE CARD DETAILS
                </Button>
              )}
              {(paymentData.message !== undefined && (params.id == userDetails.companyId &&  params.name == userDetails.company)) && (
               <Button
               className="floatRight btn-style  enterpriseOptionbuttons btn-info"
               onClick={addNewCard}
             >
               ADD PAYMENT METHOD
             </Button>
              )}
              {(paymentData.message !== undefined &&  (params.id == userDetails.id && params.name == userDetails.firstName + ' ' + userDetails.lastName)) && (
               <Button
               className="floatRight btn-style  enterpriseOptionbuttons btn-info"
               onClick={addNewCard}
             >
               ADD PAYMENT METHOD
             </Button>
              )} */}
            </p>
          </Col>}
          <Col lg={24} className="tablePadding">
            {!(paymentData.length == 0) ? paymentData.map((x) => (
              <>
                <Col lg={24} className="paymentPadding paymentDetailsContainer">
                  <div>
                    <p className="payments">
                      Payment Method: {checkCard(x.brand)}  {paymentData.length > 1 && x.is_primary && <span style={{ color: "#BA1F0E" }}>Primary</span>}
                    </p>
                    <p className="payments">
                      Cardholder Name:{' '}
                      {/* {type === 'enterprise' ? ` ${paymentData.enterprise && paymentData.enterprise.name}` : ` ${paymentData.user && paymentData.user.firstName}  ${paymentData.user && paymentData.user.lastName !== null ? ' ' + paymentData.user.lastName : ''} `} */}
                      {x.name}
                    </p>
                    <p className="payments">
                      Card Number: **** **** ****{' '}
                      {x.last4}
                    </p>
                    <p className="payments">
                      Card Exp: {x.expMonth} /{' '}
                      {x.expYear}
                    </p>
                  </div>
                  {!(userDetails.userType=='UnyTag') &&  <div className={"paymentButtons"}>
                    <Button
                      className="btn-style enterpriseOptionbuttons btn-success entButtonsForUser"
                      onClick={() => updateCard(x.id)}
                    >
                      UPDATE CARD DETAILS
                </Button>
                    {paymentData.length > 1 && <Button
                      className="btn-style  enterpriseOptionbuttons btn-freeze entButtonsForUser"
                      onClick={() => setCardasPrimary(x.id)}
                      disabled={x.is_primary}
                    >
                      SET AS PRIMARY
                </Button>}

                    <Button
                      className="btn-style  enterpriseOptionbuttons btn-danger entButtonsForUser"
                      onClick={() => deleteCard(x.id)}
                    >
                      DELETE
                </Button>

                  </div>}
                </Col>
                <Divider style={{ border: '' }} />
              </>
            ))
              : (
                <Col lg={24} className="LoginLayout text-center paymentText paymentPadding1">
                  {(params.id == userDetails.companyId || (params.id == userDetails.id && params.name == userDetails.firstName + ' ' + userDetails.lastName)) ?
                    'PLEASE ADD A PAYMENT METHOD IN ORDER TO USE YOUR TAG(S).' :
                    'NO PAYMENT METHOD HAS BEEN ADDED YET'
                  }

                </Col>
              )}
            {/* {paymentData.message === undefined && <Divider style={{ border: '2px solid #EEEEEE' }} />} */}
          </Col>
          {showViewModal && renderViewModel()}
          {/* {showUpdateModal && renderUpdateModel()} */}
        </Row>
      </Spin>
    </div>
  );
};

export default PaymentMethod;

/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useCallback } from 'react';
import _, { has } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { Row, Col, Form, Select, Divider, Button, Modal, message } from '../../common/AntdUiComponents';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { useState } from 'react';
import TollPlazaGoogleMap from '../tollAuthority/TollPlazaGoogleMap';
import { saveTollPlaza } from '../../api/tollPlaza';
import { alertMessage } from '../../common/Common';
import AddressForm from '../../common/Addressform';

const TollPlazaDetailsForm = ({ tollPlazaData, redirectBack }) => {
  const [form] = Form.useForm();
  const [disableFields, setDisableFields] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [hasSavedChanges, setHasSavedChanges] = useState(false);
  const boundsData = [
    {key: 'NRT', val: 'North'}, 
    {key: 'SOT', val: 'South'}, 
    {key: 'WST', val: 'West'},
    {key: 'EST', val: 'East'}, 
    {key: 'NWST', val: 'North West'}, 
    {key: 'NEST', val: 'North East'},
    {key: 'SWST', val: 'South West'}, 
    {key: 'SEST', val: 'South East'}, 
  ];
  const pricingData = ['Fixed', 'Variable'];
  const [state, setState] = useState({});

  useEffect(() => {
    setIntialState();
  }, [tollPlazaData]);

  const setIntialState = () => {
    setState({
      id: tollPlazaData.id,
      tollPlazaName: tollPlazaData.tollPlazaName,
      plazaLocationLogitude: tollPlazaData.plazaLocationLogitude,
      plazaLocationLatitude: tollPlazaData.plazaLocationLatitude,
      tollFacilityId: tollPlazaData.tollFacilityId,
      tollPricing: tollPlazaData.tollPricing? tollPlazaData.tollPricing.tollPricing : null,
      laneType: tollPlazaData.laneType? tollPlazaData.laneType.laneType : null,
      gantry: tollPlazaData.gantry,
      address: {
        address_line1: tollPlazaData.address1,
        address_line2: tollPlazaData.address2,
        state: tollPlazaData.state? tollPlazaData.state.stateAbbr : null,
        city: tollPlazaData.city,
        zipcode: tollPlazaData.zipCode,
        country: tollPlazaData.country? tollPlazaData.country.country : null,
      },
      phone: tollPlazaData.phone,
      bound: tollPlazaData.bound,
      order: tollPlazaData.location.order,
      mainGeofence: tollPlazaData.location.polygon,
      latitude: tollPlazaData.location.latitude,
      longitude: tollPlazaData.location.longitude
    });
    form.setFieldsValue({
      tollPlazaName: tollPlazaData.tollPlazaName,
      order: tollPlazaData.location.order.toString(),
      pricing: tollPlazaData.tollPricing? tollPlazaData.tollPricing.tollPricing : null,
      phone: tollPlazaData.phone? tollPlazaData.phone : '',
      bound: tollPlazaData.bound,
    });
  }

  const handleInputChange = key => value => {
    setHasChanges(true);
    setState({
      ...state,
      [key]: value,
    });
  }

  const editPlaza = () => {
    if (hasChanges) {
      if (showCancelModal) {
        setIntialState();
        setShowCancelModal(false);
        setDisableFields(true);
        setHasChanges(false);
      } else {
        setShowCancelModal(true);
      }
    } else {
      setDisableFields(!disableFields);
    }
  }

  const returnToPlazaList = () => {
    redirectBack(hasSavedChanges);
  }

  const submitForm = () => {
    let data = {
      id: state.id,
      tollPlaza: state.tollPlazaName,
      // plazaLocationLogitude: state.plazaLocationLogitude,
      // plazaLocationLatitude: state.plazaLocationLatitude,
      tollFacilityId: state.tollFacilityId,
      tollPricing: state.tollPricing,
      laneType: state.laneType,
      gantry: state.gantry,
      state: state.address.state,
      country: state.address.country,
      address1: state.address.address_line1,
      address2: state.address.address_line2,
      city: state.address.city,
      zipCode: state.address.zipcode,
      phone: state.phone,
      bound: state.bound,
      location: {
        order: state.order,
        mainGeofence: state.mainGeofence,
        latitude: state.latitude,
        longitude: state.longitude
      }
    }

    saveTollPlaza(data, 'put').then(result => {
      if (result.success) {
          message.success({
          content: `${state.tollPlazaName} HAS BEEN SAVED!`,
          className: 'bg-maroon',
          duration: 5,
          });
          setDisableFields(!disableFields);
          setHasChanges(false);
          setHasSavedChanges(true);
      } else {
        alertMessage(result.errors.message.message, 'error', 3);
      }
    });
  }

  const onEditPlazaFence = (polygon) => {
    var stateClone = {...state};
    stateClone.mainGeofence = polygon;
    setState(stateClone);
    setHasChanges(true);
  }

  const onEditPlazaMarker = (marker) => {
    var stateClone = {...state};
    stateClone.latitude = marker.latLng.lat();
    stateClone.longitude = marker.latLng.lng();
    setState(stateClone);
    setHasChanges(true);
  }

  const handleOnAddressChange = (addressType, element, value, obj) => {
    var stateClone = {...state};
    stateClone.address = {
      address_line1: obj.address_line1? obj.address_line1.trim() : null,
      address_line2: obj.address_line2? obj.address_line2.trim() : null,
      city: obj.city? obj.city.trim() : null,
      state: obj.state? obj.state.trim() : null,
      country: obj.country? obj.country.trim() : null,
      zipcode: obj.zipcode? obj.zipcode.trim() : null
    }
    setState(stateClone);
    setHasChanges(true);
  }

  const renderModalCancelForm = () => (
    <Modal
      className="toll-authority"
      title="Proceed with cancel?"
      visible={showCancelModal}
      footer={null}
      onCancel={() => setShowCancelModal(false)}
    >
      <div className="modalDescrition">
        <p>Disclaimer:</p>
        <p>
          If you cancel without saving, all changes will be lost.
        </p>
      </div>
      <Row type="flex" justify="center">
        <ReuseButton
          text="PROCEED"
          className="btn-style btn-danger buttonWidth1"
          onClick={editPlaza}
        />
      </Row>
    </Modal>
  );

  return (
    <Form layout='vertical' form={form}>
      <div className='addtolls'>
        <br/>
        <br/>
      <Row style={{paddingLeft: '10px' }} gutter={20}>
        <span className='TagModalTitleHead'>TOLL PLAZA DETAILS : ID#{state.id}</span>
        <div style={{marginLeft: 'auto', marginTop: -20}}>
          {disableFields? (
            <>
              <Button
                className='btn-style btn-warn SaveButton'
                onClick={() => returnToPlazaList()}>
                BACK
              </Button>
              <ReuseButton
                text="EDIT"
                className="btn-style btn-info SaveButton"
                onClick={() => {editPlaza(false)}}
              />
            </>
          ) : (
            <div>
              <Button
                className='btn-style btn-warn SaveButton'
                onClick={() => editPlaza()}>
                CANCEL
              </Button>
              <Button
                className='btn-style btn-success SaveButton'
                onClick={submitForm}
                disabled={!hasChanges}>
                SAVE
              </Button>
            </div>
          )}
        </div>
      </Row>
      <Divider className='dividercustom'></Divider>
      <Row className='mt-10' gutter={20}>
        <Col span={24}>
          <Form.Item
            name='tollPlazaName'
            validateTrigger='onBlur'
            rules={[
              {
                required: true,
                message: 'Please enter a toll plaza name!',
                validationTrigger: 'onBlur',
              },
              () => ({
                validator(rule, value) {
                  if (value === undefined) {
                    return Promise.resolve();
                  }
                  if (value.length === 0 && value.trim() === '') {
                    return Promise.resolve();
                  }
                  if (value.length > 0 && value.trim() !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Please enter a valid toll toll plaza name.')
                  );
                },
              }),
            ]}
            label='TOLL PLAZA NAME'
            className='authlabels'>
            <ReuseInputField
              className='TagInput'
              width='100%'
              placeholder='Toll Plaza Name'
              value={state.tollPlazaName}
              onChange={handleInputChange('tollPlazaName')}
              disabled={disableFields}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className='mt-10' gutter={20}>
      {disableFields? (
        <Col span={12}>
          <Form.Item
            name='bound'
            validateTrigger='onBlur'
            rules={[
              {
                required: true,
                message: 'Please enter bound!',
                validationTrigger: 'onBlur',
              },
              () => ({
                validator(rule, value) {
                  if (value === undefined) {
                    return Promise.resolve();
                  }
                  if (value.length === 0 && value.trim() === '') {
                    return Promise.resolve();
                  }
                  if (value.length > 0 && value.trim() !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Please enter bound.')
                  );
                },
              }),
            ]}
            label='BOUND'
            className='authlabels'>
            <ReuseInputField
              className='TagWebInput'
              width='100%'
              value={state.bound}
              disabled={disableFields}
              onChange={handleInputChange('bound')}
            />
          </Form.Item>
        </Col>
      ) : (
        <Col span={12}>
          <span className='authlabels1'>BOUND</span>
          <Select
            placeholder='Select Bound'
            value={state.bound}
            style={{ width: '100%' }}
            onChange={handleInputChange('bound')}
            filterOption={(input, option) => {
              return (
                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.val.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
            >
            {boundsData.map(bound => (
              <Select.Option key={bound.key} value={bound.key}>
                {bound.val}
              </Select.Option>
            ))}
          </Select>       
        </Col>    
        )}
        <Col span={12}>
          <Form.Item
            name='order'
            validateTrigger='onBlur'
            rules={[
              {
                required: true,
                message: 'Please enter order!',
                validationTrigger: 'onBlur',
              },
              () => ({
                validator(rule, value) {
                  if (value === undefined) {
                    return Promise.resolve();
                  }
                  if (value.length === 0 && value.trim() === '') {
                    return Promise.resolve();
                  }

                  if (value.length > 0 && value.trim() !== '') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Please enter order.'));
                },
              }),
            ]}
            label='ORDER'
            className='authlabels'>
            <ReuseInputField
              className='TagWebInput'
              width='100%'
              disabled={disableFields}
              value={state.order}
              onChange={handleInputChange('order')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className='mt-10' gutter={20}>
      {disableFields? (
        <Col span={12}>
          <Form.Item
            name='pricing'
            validateTrigger='onBlur'
            label='PRICING'
            className='authlabels'>
            <ReuseInputField
              className='TagWebInput'
              width='100%'
              value={state.tollPricing}
              disabled={disableFields}
              onChange={handleInputChange('tollPricing')}
            />
          </Form.Item>
        </Col>
        ) : (
        <Col span={12}>
          <span className='authlabels1'>PRICING</span>
          <Select
            placeholder='Select Pricing'
            value={state.tollPricing}
            style={{ width: '100%' }}
            onChange={handleInputChange('tollPricing')}
            >
            {pricingData.map(price => (
              <Select.Option key={price} value={price}>
                {price}
              </Select.Option>
            ))}
          </Select>       
        </Col> 
        )}
        <Col span={12}>
          <Form.Item
            name='phone'
            validateTrigger='onBlur'
            label='PHONE NUMBER'
            className='authlabels'>
            <PhoneInput
              country='us'
              onlyCountries={['us']}
              specialLabel=''
              disabled={disableFields}
              onChange={handleInputChange('phone')}
              value={state.phone}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row className='mt-10' gutter={20}>
        <Col
          span={24}
          className={`companytolladdress addressFieldTop`}>
          <span className='TagModalTitle'>ADDRESS</span>
          <br />
          <AddressForm
            autoComplete
            twoRows
            address={state.address}
            disabled={disableFields}
            onChange={(element, value, obj, addressLine) =>
              handleOnAddressChange(
                'address',
                [element],
                value,
                obj,
                addressLine
              )
            }
            addressType='company'
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: '2%', paddingLeft: '10px' }} gutter={20}>
        <span className='TagModalTitleHead'>
          {' '}
          TOLL PLAZA GEOMAP LOCATION
        </span>
      </Row>
      <Divider className='dividercustom'></Divider>
      <TollPlazaGoogleMap isMarkerShown={true} 
        tollPlaza={state} 
        editable={!disableFields}
        onEditPolygon={onEditPlazaFence}
        onEditMarker={onEditPlazaMarker}>
      </TollPlazaGoogleMap>
      <br/>
      <br/>
    </div>
    <br/>
    {showCancelModal && renderModalCancelForm()}
    </Form>
  )

};

export default TollPlazaDetailsForm;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchtollAuthorityUserById } from '../../api/tollAuthority';
import { Row, Col, Table } from '../../common/AntdUiComponents';
import {sorters} from '../../common/Common'

const UsersList = () => {
    const [userData, setData] = useState('');
    const [pagination, setPagination] = useState({});
    const [perPage,setPerPage] = useState(20);
    const [sortKey,setSortKey] = useState('');
    const [sortOrder,setSortOrder] = useState('');
    const params = useParams()

    let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const fetchTollUsers = (value,pageSizeCount,sortkey,sorter) => {
    const page = tableOptions.pagination.current;
    fetchtollAuthorityUserById(value, page, pageSizeCount,sortkey,sorter).then(result => {
      if (result.success) {
        setData(result.records);
        setPagination(result.pagination);
      } else {
        setData([]);
        setPagination(null);
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
      }
    });
  };

  useEffect(() => {
    const page = tableOptions.pagination.current;

    fetchTollUsers(page,params.entName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'contactName',
      key: 'contactName',
      render: (text, objData) => (
        <span>
          {(objData.contactName)} &nbsp; {objData.contactName!==null && "-"}  &nbsp;  {objData && <span>{(objData.email)}</span>}
        </span>
      ),
    },
  ];


  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize)
    let sorterOrder='';
    let sorterkey=''
    sorter.field = sorter.field === 'name' ? 'firstName':sorter.field
        sorterOrder = sorters(sorter.order);
      sorterkey = sorters(sorter.order)===''?'': sorter.field;
       setSortKey(sorters(sorter.order)===''?'': sorter.field);
       setSortOrder(sorters(sorter.order))
       fetchTollUsers(pagination.pageSize,sorterkey,sorterOrder);
  };


  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding mar-top-10">
          <p className="TitleText mar-bottom-20">
            Manage all of your users here.
          </p>
          <Table
            className="BaseTable userCategories tollUserPadding"
            dataSource={userData}
            columns={columns}
            showSizeChanger={false}
            showHeader={false}
            pagination={pagination}
            onChange={(pagination, filters, sorter) =>
                handleTableChange(pagination, filters, sorter)
              }
          />
        </Col>
      </Row>
    </div>
  );
};

export default UsersList;


const initialState = {
    notifications: [],
}

function notificationReducer (state = initialState, action) {
    switch (action.type) {
        case "NOTIFICATION" :
            var messages = [];
            if (action.clearMessages) {
                messages = action.payload;
            } else {
                messages = state.notifications.concat(action.payload);
            }
            return { ...state, notifications: Array.from(messages) };
        default :
            return state;
    }
}

export default notificationReducer;

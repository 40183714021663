const initialState = {
    notification_count: 0
}

function notificationCounterReducer (state = initialState, action) {
    switch (action.type) {
        case "NOTIF_COUNTER" :
            var finalCount;
            switch (action.add) {
                case -1:
                    finalCount = state.notification_count - 1;
                    break;
                case 1:
                    finalCount = state.notification_count + 1;
                    break;
                default:
                    finalCount = action.payload;
                    break
            }
            return { ...state, notification_count: finalCount };
        default :
            return state;
    }
}

export default notificationCounterReducer;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import List from '../components/users/List';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import { fetchUsersList } from '../api/user';
import { Spin } from '../common/AntdUiComponents';

const Users = (props) => {
  const history = useHistory();
  const [usersData, setUsersData] = useState([{}]);
  const [inProgress, setInProgress] = useState(false);
  const [pagination, setPagination] = useState({});
  const [searchField, setSearchField] = useState('');
  const [perPage,setPerPage] = useState(20)

  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const getUsersList = (search,PageSizeCount) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    fetchUsersList(page, PageSizeCount, search).then((result) => {
      if (result.success) {
        setPagination(result.pagination);
        setUsersData(result.records);
        setInProgress(false);
      } else {
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setUsersData([]);
        setPagination(null);
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    getUsersList('',perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-shadow
  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter)
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize)
    getUsersList(searchField,pagination.pageSize);
  };

  const handleTitle = (langValue, nextState, id) => {
      history.push(`/unytag/web/users/${nextState}/${langValue}/${id}`);
  };

  const handleSearch = (value) => {
    setSearchField(value);
    getUsersList(value,perPage);
  };

  return (
    <div>
      <Spin spinning={inProgress}>
        <p className="ContainerTitleHeader">
          <span className="PageTitle">Users ({pagination == null ? 0 : pagination.totalCount})</span>
          <ReuseInputField
            placeholder="Search by name..."
            className="SearchBar"
            onChange={handleSearch}
            value={searchField}
          />
        </p>
        <List
          data={usersData}
          pagination={
            pagination !== null
              ? {
                  total: parseInt(pagination.totalCount, 10),
                  current: parseInt(pagination.currentPage, 10),
                  pageSize: parseInt(pagination.perPage, 10),
                  pageSizeOptions: [5,10,20],

                }
              : {}
          }
          // eslint-disable-next-line no-shadow
          tableChange={(pagination, filters, sorter) =>
            handleTableChange(pagination, filters, sorter)
          }
          onSelectTitle={handleTitle}
        />
      </Spin>
    </div>
  );
};

export default Users;

/* eslint-disable camelcase */
import React, { Component } from 'react';
import { Row, Col, Form } from 'antd';
import * as qs from 'query-string';
import swal from 'sweetalert';
import logo1 from '../../images/Unytaglogo.png';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { resetPassword } from '../../api/login';

const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = {
      password: '',
      confirm_password: '',
      parsed: qs.parse(location.search),
      disableBtn: false,
    };
  }

  componentDidMount(){
    this.clearStorage()
  }

  clearStorage = () => {
    localStorage.clear();
  }

  handleChange = key => value => {
    this.setState({ [key]: value });
  };

  hasErrors = fieldsError =>
    Object.keys(fieldsError).some(field => fieldsError[field]);

  forgetPassword = e => {
    const { form } = this.props;
    e.preventDefault();
    form.validateFields(err => {
      if (!err) {
        console.log(err);
      }
    });
  };

  handleOnChange = (element, value) => {
    const { form } = this.props;
    form.setFieldsValue({
      [element]: value,
    });
  };

  handleSubmit = e => {
      const { password, parsed } = this.state;
      const { history } = this.props;
      e.preventDefault();
      const formData = {};
      formData.password = password;
      if (re.test(String(password))) {
        resetPassword(parsed.token, parsed.email, formData).then(result => {
          if (result.success) {
            this.setState({ disableBtn: true });
            swal({
              title: 'Success!',
              text: 'PASSWORD HAS BEEN RESET SUCCESSFULLY.',
              icon: 'success',
              button: 'OK',
            }).then(() => {
              // localStorage.setItem('token', '');
              // localStorage.setItem('user', '');
              localStorage.clear();
              history.push(`/unytag/web`);
            });
          } else {
            this.setState({ disableBtn: false });
            if(result.errors.message.message === 'Please provide a valid token'){
              swal({
                title: 'Oops!',
                text: 'Your password reset link has expired. Please reset again.',
                icon: 'error',
                button: 'RESET',
              }).then(() => {
                localStorage.clear();
                history.push(`/unytag/web/forgot_password`);
              })
            }
            else{
              swal('Oops!',  result.errors.message.message, 'error');
            }

          }
        });
      } else {
        this.setState({ disableBtn: false });
        swal(
          'Oops!',
          'Password must contain minimum 8 characters, at least 1 letter, 1 number and 1 special character.',
          'error'
        );
      }
  };

  render() {
    const { password, confirm_password } = this.state;
    return (
      <div className='LoginLayout'>
        <div className='LoginContentSize'>
          <Row className='forgetContent'>
            <Col lg={3} className='logofPass'>
              <img
                src={logo1}
                alt='logo'
                style={{ height: '18px', width: '30px' }}
              />
            </Col>
            <Col lg={14} style={{ margin: 'auto auto', textAlign: 'center' }}>
              <Col lg={15} offset={6}>
                <p className='passNote'>Please enter your new password</p>

                <Form onSubmit={this.handleSubmit} layout='vertical'>
                  <Form.Item
                    name='password'
                    rules={[
                      { required: true, message: 'Please enter password' },
                    ]}
                    label='PASSWORD'
                    className='authlabels'>
                    <ReuseInputField
                      type='password'
                      height='52px'
                      width='200px'
                      value={password}
                      onChange={this.handleChange('password')}
                    />
                  </Form.Item>
                  <Form.Item
                    name='confirm_password'
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Passwords do not match.')
                          );
                        },
                      }),
                    ]}
                    label='CONFIRM PASSWORD'
                    className='authlabels'>
                    <ReuseInputField
                      type='password'
                      height='52px'
                      value={confirm_password}
                      onChange={this.handleChange('confirm_password')}
                    />
                  </Form.Item>
                  <br />
                  <ReuseButton
                    text='SET NEW PASSWORD'
                    className='center1authPass'
                    onClick={this.handleSubmit}
                    disabled={
                      password === '' ||
                      confirm_password === '' ||
                      password !== confirm_password ||
                      this.state.disableBtn
                      // !re.test(String(password))
                    }
                  />
                </Form>
              </Col>
              <Col lg={6} />
            </Col>
            <Col lg={5} />
          </Row>
        </div>
      </div>
    );
  }
}

export default ResetPassword;

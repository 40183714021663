import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Icon } from '../../common/AntdUiComponents';

const CategoryList = ({ onSelectTitle, dataValue,recordName }) => {
  const [data, setData] = useState([]);
  const dataSource = [
    {
      key: '1',
      name: 'Toll Facilities',
      age: 32,
    },
    {
      key: '2',
      name: 'Profile',
      age: 42,
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: () => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon type="arrow-right" style={{ fontSize: '30px' }} />
        </span>
      ),
      align: 'right',
    },
  ];

  useEffect(() => {
    if (dataValue !== '') {
      const filteredData = dataSource.filter(
        (datas) => datas.name.toLowerCase().includes(dataValue.toLowerCase()),
      );
      setData(filteredData);
    } else {
      setData(dataSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);

  const handleTitleChange = (name) => {
    if(name==='Profile'){
      onSelectTitle(name, 'facilities');
    }else{
      onSelectTitle(recordName, 'facilities');
    }

  };

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding  mar-top-10">
          <p className="TitleText mar-bottom-20">
            View everything that has happened with this authority here.
          </p>
          <Table
            className="BaseTable userCategories"
            dataSource={data}
            columns={columns}
            showHeader={false}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleTitleChange(record.name);
                },
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CategoryList;

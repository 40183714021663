import React, { useEffect } from 'react';
import { Row, Col, Table, Icon } from '../../common/AntdUiComponents';
import { useParams } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import { handleBreadcrumbLinks } from '../../reducer/actions/actions';
import { useSelector, useDispatch} from 'react-redux';

const List = ({ onSelectTitle, data, pagination, tableChange, handleEditEnterpriseProfile }) => {

  const handleTitleChange = (name, role, id) => {
    onSelectTitle(name, 'info', 'Edit Profile', role, id);
  };

  const handleEditEnterPrise = () => {
    handleEditEnterpriseProfile(true)
  }

  const history = createBrowserHistory();

  const params = useParams();

  const dispatch = useDispatch();

  useEffect(() => {  
    var links = ['Overview', 'Account', 'Profile'];
    dispatch(handleBreadcrumbLinks(links));
    return history.listen(location => {
      if (history.action === 'POP') {
        if(window.location.pathname === '/unytag/web/enterprise/profilesList'){
                      
        }
      }
    })
  }, [])
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, objData) => {
        const { contactName } = objData;
        const name = `${contactName}`;
        return (
          <span>
            {name}({objData.role})
          </span>
        );
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, objData) => {
        const { contactName } = objData;
        const name = `${contactName}`;
        return (
          <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
            <Icon
              type="arrow-right"
              style={{ fontSize: '30px' }}
            />
          </span>
        );
      },
      align: 'right',
    },
  ];

  return (
    <div className="Basecontainer">
      <Row>
      <Col span={24}>
          <p className="TitleText mar-bottom-20">
            Here are all the profiles associated with this enterprise customer.
          </p>
        </Col>
      <Col span={24} className="enterpiseProfile" onClick={() => handleEditEnterPrise()}>
          <div className="pl-25" style={{ display: 'flex' }}>
            <span> {params.name} {" "}</span>
            <span> (Enterprise)</span>
          </div>
          <div className="pr-25">
            <Icon
              type="arrow-right"
              onClick={() => handleEditEnterPrise()}
              style={{ fontSize: '30px' }} />
          </div>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24} className="tablePadding">
          <Table
            className="BaseTable userCategories"
            dataSource={data}
            columns={columns}
            showHeader={false}
            pagination={pagination}
            onChange={tableChange}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => { handleTitleChange(record.contactName, record.role, record.id) }, 
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default List;

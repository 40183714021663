/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Modal } from '../../common/AntdUiComponents';

class BaseModal extends Component {
  render() {
    const { title, destroyOnClose, onCancel, width, children } = this.props;
    return (
      <Modal
        title={title}
        visible
        destroyOnClose={destroyOnClose}
        footer={null}
        onCancel={onCancel}
        width={width}
        maskClosable={false}
      >
        {children}
      </Modal>
    );
  }
}

export default BaseModal;

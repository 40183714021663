import React from 'react';
import { Row, Col, Table, Icon } from '../../common/AntdUiComponents';

const List = ({ data, onSelectTitle, tableChange, pagination }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'contactName',
      key: 'contactName',
      render: (text, objData) => (
        <span>
          {(objData.contactName)} &nbsp; {objData.contactName!==null && "-"}  &nbsp;  {objData && <span>{(objData.email)}</span>}
        </span>
      ),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: () => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon type="arrow-right" style={{ fontSize: '30px' }} />
        </span>
      ),
      align: 'right',
    },
  ];

  const handleTitleChange = (name, id) => {
    onSelectTitle(name, 'category', id);
  };

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding mar-top-10">
          <p className="TitleText mar-bottom-20">
            Manage all of your users here.
          </p>
          <Table
            className="BaseTable userCategories"
            dataSource={data}
            columns={columns}
            showSizeChanger={false}
            showHeader={false}
            pagination={pagination}
            onChange={tableChange}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleTitleChange(
                    `${record.contactName}`,
                    record.id,
                  );
                },
              };
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default List;

import _ from 'lodash';
import { Call, ApiUrl, ParseGeneralResponse, PageParams } from './ApiUtils';
import AppConfig from '../config/AppConfig';

export function fetchTollAuthorities(
  page = 1,
  perPage = AppConfig.perPage,
  name = '',
  id = ''
) {
  const url = ApiUrl(
    `/unytag/toll?${PageParams(page, perPage)}&search=${encodeURIComponent(
      name
    )}&sortBy=${id}`
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}

export function searchTollAuthority(name) {
  const url = ApiUrl(`/unytag/toll/search?search=${name}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { record: '' },
  });
}

export function fetchtollAuthorityById(id) {
  const url = ApiUrl(`/unytag/toll/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { record: '' },
  });
}

export function saveTollAuthority(isNew, data, id) {
  const result = isNew ? _.omit(data, ['id']) : data;
  const url = isNew ? ApiUrl('/unytag/toll') : ApiUrl(`/unytag/toll/${id}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function saveTollAuthorityUser(isNew, data, tollAuthName) {
  const url = isNew ? ApiUrl('/toll/user') : ApiUrl(`/toll/user/${tollAuthName}`);
  const method = isNew ? 'post' : 'put';
  return Call(method, url, data, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function fetchTollAuthorityUsers(
  page = 1,
  perPage = AppConfig.perPage,
  name = '',
  id = ''
) {
  const url = ApiUrl(
    `/toll/user?${PageParams(
      page,
      perPage
    )}&tollAuthority=${encodeURIComponent(name)}&sortBy=${id}`
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}

export function deleteTollAuthUser(name, id) {
  const url = ApiUrl(`/unytag/toll/user/${id}?tollAuthority=${name}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function fetchtollAuthorityUserById(
  page = 1,
  perPage = AppConfig.perPage,
  tollAuthName
) {
  const url = ApiUrl(
    `/toll/user?${PageParams(page, perPage)}&tollAuthority=${tollAuthName}`
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records' },
  });
}
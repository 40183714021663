import { Call, ApiUrl, ParseGeneralResponse, PageParams } from './ApiUtils';
import AppConfig from '../config/AppConfig';

export function fetchTransactions(
  startDate,
  endDate,
  search='',
  page = 1,
  perPage =AppConfig.perPage,
  id = '',
  order=''

) {
  const url = ApiUrl(
    `/unytag/transactions?endDate=${endDate}&startDate=${startDate}&${PageParams(
      page,
      perPage ,
    )}&sortBy=${id}&search=${encodeURIComponent(search)}&sortBy=${id}&sortOrder=${order}`,
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}
export function paymentIssues() {
  const url = ApiUrl(
    `/unytag/failed/Payments`, 
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: '', pagination: 'meta' },
  });
}

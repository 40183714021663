/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Row,
  Col,
  Table,
  Modal,
  message,
  Button,
  Upload,
} from '../../common/AntdUiComponents';
import { alertMessage } from '../../common/Common';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { deleteTollPlaza } from '../../api/tollPlaza';
import ExportCSV from '../../utils/reusableComponents/ExportCSV';
import { uploadTollPlazaCSV } from '../../api/tollPlaza';
import TollPlazaDetailsForm from './TollPlazaDetailsForm';
import AddTollPlazaDetailsForm from './AddTollPlazaDetailsForm';
import { handleBreadcrumbLinks } from '../../reducer/actions/actions';
import { useDispatch} from 'react-redux';
let fileObj = {};
let files = [];

const Plazas = ({ tollFacility, plazaData, refreshPlazasData, pagination, tableChange }) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [fileName, setfileName] = useState('');
  const [visible, setVisible] = useState(false);
  const [tollPlaza, setTollPlaza] = useState({});
  const [csvFile, setCsvFile] = useState(null);
  const [currentPage, setCurrentPage] = useState("tollPlazaList");
  const dispatch = useDispatch();

  useEffect(() => {
    var links = ['Overview', 'Toll Authorities', 'Facilities', 'Plazas'];
    dispatch(handleBreadcrumbLinks(links));
  }, [])

  const handleSubmit = (e, obj) => {
    e.preventDefault();
    setVisible(!visible);
  };

  const handleCloseModal = () => {
    setVisible(false);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => a.id - b.id,
      render: (text, data) => {
        const { id } = data;
        return <span>{id? id: '-'}</span>},
    },
    {
      title: 'Name',
      dataIndex: 'tollPlazaName',
      key: 'tollPlazaName',
      width: 400,
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => {
        a = a.tollPlazaName || '';
        b = b.tollPlazaName || '';
        return a.localeCompare(b);
      },
      render: (text,data) => {
        const { tollPlazaName } = data;
        return <span>{tollPlazaName !== "" ? tollPlazaName : '-'}</span>},
    },
    {
      title: 'Bound',
      dataIndex: 'bound',
      key: 'bound',
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => {
        a = a.bound || '';
        b = b.bound || '';
        return a.localeCompare(b);
      },
      render: (text,data) => {
        const { bound } = data;
        return <span>{bound !== "" ? bound : '-'}</span>},
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      sortDirections: ['ascend','descend','ascend'],
      sorter: (a, b) => a.location.order - b.location.order,
      render: (text,data) => {
        const { location } = data;
        return <span>{location? location.order : '-'}</span>},
    },
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      render: (text, objData) => {
        return (
          <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
            <ReuseButton
              text="DETAILS"
              className="btn-style btn-success enterpriseOptionbuttons entButtonsForUser"
              onClick={(e) => {
                setTollPlaza(objData);
                redirectToDetails(objData);
              }}
            />
            <ReuseButton
              text="DELETE"
              className="btn-style btn-danger enterpriseOptionbuttons entButtonsForUser"
              onClick={(e) => {
                setVisible(true);
                setTollPlaza(objData);
              }}
            />
          </span>
        );
      },
      align: 'right',
    },
  ];

  const userDummyData = [
    {
      "Polygon": '[[-95.557146,29.730327],[-95.557113,29.733756],[-95.557349,29.733765],[-95.557339,29.730309],[-95.557146,29.730327]]',
      "Entry Point": '-95.557242,29.730355',
      "Center Point": '-95.555595,29.921163',
      "Name": 'North Park Toll Plaza',
      "Order": 1,
      "Bound": "NRT"
    },
  ];

  const remove = () => { 
    deleteTollPlaza(tollPlaza.id).then(result => {
      if (result.success) {
          message.success({
          content: `${tollPlaza.tollPlazaName} HAS BEEN DELETED!`,
          className: 'bg-maroon',
          duration: 5,
          });
          handleCloseModal();
          refreshPlazasData(tollFacility);
      } else {
        alertMessage(result.errors.message.message, 'error', 3);
      }
    });
  };

  const renderModalDelete = () => (
    <Modal
      className="toll-authority"
      title="Do you want to delete the plaza?"
      visible={visible}
      footer={null}
      onCancel={handleSubmit}
    >
      <p className="text-center">{tollPlaza.tollPlazaName}</p>
      <div className="modalDescrition">
        <p>Disclaimer:</p>
        <p>
          If you remove the plaza all users will no longer be able to use
          Unytag for this specific plaza.
        </p>
      </div>
      <Row type="flex" justify="center">
        <ReuseButton
          text="DELETE"
          className="btn-style btn-danger buttonWidth1"
          onClick={remove}
        />
      </Row>
    </Modal>
  );

  const showUploadTollPlazaModal = (e) => {
    e.preventDefault();
    setCsvFile(null);
    setShowUploadModal(true);
  };

  const handleOnModalClose = () => {
    setShowUploadModal(false);
  };

  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      setfileName(info.file.name);
      fileObj = info.fileList[0].originFileObj;
      files = info.fileList;
    }
  };

  const uploadTollPlaza = () => {
    
    uploadTollPlazaCSV(tollFacility.id, csvFile).then(() => {
      message.success({
        content: `PLAZAS SUCCESFULLY SAVED!`,
        className: 'bg-maroon',
        duration: 5,
      });
      refreshPlazasData(tollFacility);
    });
    setCsvFile(null);
    setShowUploadModal(false);
  }

  const redirectToDetails = () => {
    setCurrentPage('tollDetails');
  }

  const addTollPlaza = () => {
    setCurrentPage('addTollPlaza');
  }

  const redirectToPlazaList = (refresh) => {
    setCurrentPage('tollPlazaList');
    if (refresh) {
      refreshPlazasData(tollFacility);
    }
  }

  const renderUploadModel = () => {
    return (
      <BaseModal
        width="550px"
        onCancel={() => handleOnModalClose()}
        style={{ textAlign: 'center' }}
      >
        <Col lg={24} className="uplaodForm">
          <Upload
            showUploadList={false}
            onChange={onChange}
            beforeUpload={(file) => {
              const reader = new FileReader();
              reader.onload = (e) => {};
              reader.readAsText(file);
              setCsvFile(file);
              return false;
            }}
          >
            <Col lg={24} className="password">
              UPLOAD EXCEL DOC
            </Col>
            <ReuseInputField
              type="text"
              placeholder=""
              // icon="Mail"
              height="52px"
              width="59%"
              value={fileName}
            />
            <Button text="BROWSE" className="center1auth uploadFacility">
              BROWSE
            </Button>
          </Upload>

          <Col lg={24} className="uploadContainer">
            <ReuseButton text="UPLOAD" className="center1upload" onClick={uploadTollPlaza}/>
          </Col>
        </Col>
      </BaseModal>
    );
  };

  return (
    <div className="Basecontainer">
      {currentPage == 'tollPlazaList' && (
        <Row gutter={30}>
          <Col span={24} className='tablePadding mar-top-10'>
            <Row className='tablePadding'>
              <Col lg={8} className='userTmp'>
                <br></br>
                <ExportCSV
                  className='leftFloat btn-style btn-info enterpriseOptionbuttons templateText templateBtn'
                  csvData={userDummyData}
                  fileName='Toll Plaza Template'
                  name='DOWNLOAD PLAZA TEMPLATE'>
                </ExportCSV>
                <Button
                  className='btn-style btn-success enterpriseOptionbuttons uploadAdjust'
                  onClick={showUploadTollPlazaModal}>
                  UPLOAD PLAZA
                </Button>
              </Col>
              <Col lg={8} className='text-center entUserText'>
                Here are all the users associated with this account.
              </Col>
              <Col lg={8}>
                <br></br>
                <Button
                  className='rightFloat btn-style btn-info enterpriseOptionbuttons addEntnewUser'
                  onClick={addTollPlaza}>
                  ADD NEW PLAZA
                </Button>
              </Col>
            </Row>
            <Table
              className='BaseTable'
              dataSource={plazaData}
              columns={columns}
              showHeader
              pagination={pagination}
              onChange={tableChange}
            />
          </Col>
        </Row>
      )}
      {currentPage == 'tollDetails' && (
        <TollPlazaDetailsForm
          tollPlazaData={tollPlaza}
          redirectBack={(refresh) => redirectToPlazaList(refresh)}
        >
        </TollPlazaDetailsForm>
      )}
      {currentPage == 'addTollPlaza' && (
        <AddTollPlazaDetailsForm
          facilityId={tollFacility.id}
          redirectBack={(refresh) => redirectToPlazaList(refresh)}
        >
        </AddTollPlazaDetailsForm>
      )}
      {visible && renderModalDelete()}
      {showUploadModal && renderUploadModel()}
    </div>
  );
};

export default Plazas;

/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import { Row, Col, Form, Select, Divider } from '../../common/AntdUiComponents';
import AddressForm from '../../common/Addressform';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TollForm = ({
  handleChange,
  handleOnAddressChange,
  handleRoleChange,
  submitForm,
  state,
  roles,
  tollAdmins,
  hideCreate,
  disableFields,
  error,
  errors,
  disableBtn,
  onFocus,
  handleTollAdminChange,
  isEditForm
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const setEmail = () => {
      form.setFieldsValue({
        email: state.email,
        tollAuthority: state.tollAuthority,
        role: state.role,
        website: state.website,
        name: state.name,
        telephone: state.telephone,
        phone: state.phone,
      });
    };
    setEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, error, errors]);

  const { Option } = Select;

  const selectBefore = (
    <Select
      defaultValue='http://'
      value={state.pref}
      onChange={handleChange('pref')}
      className='select-before'>
      <Option value='http://'>http://</Option>
      <Option value='https://'>https://</Option>
    </Select>
  );
  const selectAfter = (
    <Select
      defaultValue='.com'
      value={state.suff}
      onChange={handleChange('suff')}
      className='select-after'>
      <Option value='.com'>.com</Option>
      <Option value='.jp'>.us</Option>
      <Option value='.org'>.org</Option>
    </Select>
  );

  return (
    <Form layout='vertical' form={form}>
      <div className='addtolls'>
        <Row style={{ paddingTop: '5%', paddingLeft: '10px' }} gutter={20}>
          <span className='TagModalTitleHead'> TOLL AUTHORITY DETAILS</span>
        </Row>
        <Divider className='dividercustom'></Divider>
        <Row className='mt-10' gutter={20}>
          <Col span={24}>
            <Form.Item
              name='tollAuthority'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please enter a toll authority name!',
                  validationTrigger: 'onBlur',
                },
                () => ({
                  validator(rule, value) {
                    if (value === undefined) {
                      return Promise.resolve();
                    }
                    if (value.length === 0 && value.trim() === '') {
                      return Promise.resolve();
                    }

                    if (value.length > 0 && value.trim() !== '') {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('Please enter a valid toll authority name.')
                    );
                  },
                }),
              ]}
              label='TOLL AUTHORITY NAME'
              className='authlabels'>
              <ReuseInputField
                className='TagInput'
                width='100%'
                placeholder='Toll Authority Name'
                value={state.name}
                onChange={handleChange('tollAuthority')}
                disabled={disableFields}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className='mt-10' gutter={20}>
          <Col span={12}>
            <Form.Item
              name='telephone'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please enter a telephone number!',
                  validationTrigger: 'onBlur',
                },
                () => ({
                  validator(rule, value) {
                    if (value === undefined) {
                      return Promise.resolve();
                    }
                    if (value.length === 0 && value.trim() === '') {
                      return Promise.resolve();
                    }

                    if (value.length > 0 && value.trim() !== '') {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('Please enter a telephone number.')
                    );
                  },
                }),
              ]}
              label='PHONE NUMBER'
              className='authlabels'>
              <PhoneInput
                country='us'
                onlyCountries={['us']}
                specialLabel=''
                value={state.telephone}
                disabled={disableFields}
                onChange={handleChange('telephone')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='website'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please enter a website!',
                  validationTrigger: 'onBlur',
                },
                () => ({
                  validator(rule, value) {
                    if (value === undefined) {
                      return Promise.resolve();
                    }
                    if (value.length === 0 && value.trim() === '') {
                      return Promise.resolve();
                    }

                    if (value.length > 0 && value.trim() !== '') {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error('Please enter a website.'));
                  },
                }),
              ]}
              label='WEBSITE'
              className='authlabels'>
              <ReuseInputField
                className='TagWebInput'
                width='100%'
                value={state.website}
                disabled={disableFields}
                addonBefore={selectBefore}
                onChange={handleChange('website')}
                addonAfter={selectAfter}
                defaultValue='mysite'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className='mt-10' gutter={20}>
          <Col
            span={24}
            className={`companytolladdress addressFieldTop ${error ? 'errorAddress' : ''
              }`}>
            <span className='TagModalTitle'>
              COMPANY ADDRESS <span className='star-required'>*</span>{' '}
            </span>
            <br />
            <AddressForm
              autoComplete
              twoRows
              showReqFields
              address={state.address}
              onFocus={onFocus}
              disabled={disableFields}
              onChange={(element, value, obj, addressLine) =>
                handleOnAddressChange(
                  'address',
                  [element],
                  value,
                  obj,
                  addressLine
                )
              }
              showError={error}
              errors={errors}
              addressType='company'
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: '2%', paddingLeft: '10px' }} gutter={20}>
          <span className='TagModalTitleHead'>
            {' '}
            TOLL AUTHORITY ADMIN DETAILS
          </span>
        </Row>
        <Divider className='dividercustom'></Divider>

        <Row className='mt-10' gutter={20}>
          <Col span={12}>
            <Form.Item
              name='name'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please enter a name!',
                  validationTrigger: 'onBlur',
                },
                () => ({
                  validator(rule, value) {
                    if (value === undefined) {
                      return Promise.resolve();
                    }
                    if (value.length === 0 && value.trim() === '') {
                      return Promise.resolve();
                    }

                    if (value.length > 0 && value.trim() !== '') {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('Please enter a valid name.')
                    );
                  },
                }),
              ]}
              label='FULL NAME'
              className='authlabels'>
              {!isEditForm
                ? <ReuseInputField
                  className='TagInput'
                  width='100%'
                  placeholder='Full Name'
                  value={state.name}
                  onChange={handleChange('name')}
                  disabled={disableFields}
                /> :
                <Select
                  placeholder='Select an admin'
                  value={state.name}
                  style={{ width: '100%' }}
                  disabled={disableFields}
                  onChange={e => handleTollAdminChange(e)}>
                  {tollAdmins.map(admin => (
                    <Select.Option
                      key={admin.contactName}
                      value={admin.contactName}>
                      {admin.contactName}
                    </Select.Option>
                  ))}
                </Select>}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='email'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please enter email!',
                  validationTrigger: 'onBlur',
                },
                {
                  type: 'email',
                  message: 'Please provide a valid email address!',
                },
              ]}
              label='WORK EMAIL ADDRESS'
              className='authlabels'>
              <ReuseInputField
                className='TagInput'
                width='100%'
                type='email'
                placeholder='johndoe@mail.com'
                value={state.email}
                disabled={disableFields}
                onChange={handleChange('email')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className='mt-10' gutter={20}>
          <Col span={12}>
            <span className='TagModalTitle'>PHONE NUMBER</span>
            <br />
            <PhoneInput
              country='us'
              onlyCountries={['us']}
              specialLabel=''
              value={state.phone}
              disabled={disableFields}
              onChange={handleChange('phone')}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              name='role'
              validateTrigger='onBlur'
              rules={[
                {
                  required: true,
                  message: 'Please select a role!',
                  validationTrigger: 'onBlur',
                },
              ]}
              label='ROLE'
              className='authlabels'>
              <Select
                placeholder='Select one'
                value={state.role}
                style={{ width: '100%' }}
                disabled={disableFields}
                onChange={e => handleRoleChange('role', e)}>
                {roles.map(role => (
                  <Select.Option key={role} value={role}>
                    {role}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
      {!hideCreate && (
        <div className='createWrapper'>
          <ReuseButton
            text='CREATE'
            className={
              state.tollAuthority === '' ||
                state.name === '' ||
                state.email === '' ||
                state.address === '' ||
                state.telephone === '' ||
                state.website === '' ||
                error ||
                state.role === ''
                ? 'CreateTag'
                : 'AddCreateTag'
            }
            disabled={
              state.tollAuthority.trim() === '' ||
              state.name.trim() === '' ||
              state.email === '' ||
              error ||
              state.address === '' ||
              state.telephone === '' ||
              state.website === '' ||
              state.role === '' ||
              disableBtn ||
              !re.test(String(state.email).toLowerCase())
            }
            onClick={submitForm}
          />
        </div>
      )}
    </Form>
  );
};

export default TollForm;

import React from 'react';
import { Button } from '../../common/AntdUiComponents';

const ReuseButton = ({
  type,
  text,
  className,
  onClick,
  disabled,
  htmlType,
}) => {
  return (
    <Button
      className={`ReuseButton ${className}`}
      type={type}
      onClick={(event) => onClick(event)}
      disabled={disabled}
      htmlType={htmlType}
    >
      {text}
    </Button>
  );
};

export default ReuseButton;

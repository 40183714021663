/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Col, Row } from '../common/AntdUiComponents';
import List from '../components/allTransactions/List';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';


const roles = ['User', 'Admin'];
const user = localStorage.getItem('user');
const userDetails = user!==''? JSON.parse(user) : { };

const AllTransactions = () => {
  const [search,setSearch] = useState('')

  const handleSearch = (value) => {
    setSearch(value)
  };

  return (
    <div>
      <p className='ContainerTitleHeader'>
        <Row>
          <Col lg={6}>
            <span className='PageTitle'>All Transactions</span>
          </Col>

          <Col lg={18} className='alignRight '>
            <ReuseInputField
              placeholder='Search by name,authority,number...'
              className='SearchBar'
              onChange={handleSearch}
              search={search}
            />
          </Col>
        </Row>
      </p>
      <List userType={userDetails && userDetails.userType}  search={search}/>
    </div>
  );
};

export default AllTransactions;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { UserOutlined } from '@ant-design/icons';
import {
  Form,
  Col,
  Row,
  Select,
  Carousel,
  Avatar,
} from '../common/AntdUiComponents';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../utils/reusableComponents/ReuseButton';
import { userLogin } from '../api/login';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Login extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      email: '',
      password: '',
      company: '',
      user: {},
      showError: false,
      errorMessage: '',
      default: false,
      disableBtn: false,
    };
  }

  handleChange = key => value => {
    this.setState({ [key]: value });
  };

  handleSubmit = () => {
      const { user } = this.state;
      if (user && user.id) {
        this.setState({ showError: false, errorMessage: '',disableBtn: true }, () => {
          this.loginWithPassword();
        });
      }
  };

  handleBlur = () => {
    this.login();
  };

  handleCompanyChange = (e, value) => {
    const { users } = this.state;
    this.setState({ [e]: value });
    const user = users.filter(usr => usr.id === value);
    this.setState({ user: user[0] });
  };

  loginWithPassword = () => {
    const { user, email, password } = this.state;
    const data = {
      id: user.id,
      email,
      password,
    };
    userLogin(data).then(result => {
      const { history } = this.props;
      if (result.success) {
        this.setState({ disableBtn: true });
        localStorage.setItem('token', result.data.jwttoken);
        const userData = {
          id: result.data.id,
          userType: result.data.accountType.accountType,
          userTypeId: result.data.accountType.id,
          company: result.data.company,
          companyId: result.data.companyId,
          contactName:result.data.contactName,
        };
        localStorage.setItem('user', JSON.stringify(userData));
        history.push('/unytag/web/overview');
        window.location.reload();
      } else if (result.errors.message.message === 'INVALID_CREDENTIALS') {
        this.setState({ disableBtn: false });
        swal('Oops!', 'INVALID CREDENTIALS', 'error');
      } else {
        this.setState({ disableBtn: false });
        swal(
          'Oops!',
          result.errors.message.message,
          'error'
        );
      }
    });
  };

  login = () => {
    const { email } = this.state;
    const data = { email };
    userLogin(data).then(result => {
      if (result.success) {
        if (result.data.length > 0) {
          this.setState({ users: result.data }, () => {
            if (this.state.users.length >0) {
              this.setState(
                {
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  user: this.state.users[0],
                  default: true,
                  errorMessage: '',
                  showError: false,
                },
                () => {
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  this.setState({ company: this.state.users[0].id });
                  this.formRef.current.setFieldsValue({
                    company: this.state.users[0].id,
                  });
                }
              );
            }
          });
        } else {
          this.setState(
            {
              users: [],
              errorMessage: 'Please enter a registered email',
              showError: true,
            },
            () => {
              this.setState({ user: {}, default: false }, () => {
                this.setState({ company: '' });
                this.formRef.current.setFieldsValue({
                  company: '',
                });
              });
            }
          );
        }
      } else if (result.errors.message.message === 'User does not exist with given email.') {
        this.setState({ disableBtn: false });
        swal('Oops!', 'User does not exist with given email.', 'error');
      }
      else {
        this.setState({
          users: [],
        });
      }
    });
  };

  render() {
    const { email, password } = this.state;
    return (
      <div className='LoginLayout'>
        {console.log(window.google)}
        <div>
          <Row>
            <Col lg={12} className='loginContent'>
              <Col className='loginPageTitle'> Unytag</Col>
              <Col className='authContentCarousel carouselMover'>
                <Carousel>
                  <div>
                    <h3 className='contentStyle'>
                      <span>
                        “Unytag{' '}
                        <span className='highlightedReview'>
                          {' '}
                          does everything for you.
                        </span>{' '}
                        All I have to do is connect the tag to my phone, pass
                        through the toll and I’m done.
                        <p className='pad-40'>
                          {' '}
                          <Avatar
                            shape='square'
                            size={64}
                            icon={<UserOutlined />}
                          />{' '}
                          <span className='customerExcDetails'>
                            {' '}
                            <span className='highlightedReview'>
                              {' '}
                              RYAN JONES{' '}
                            </span>{' '}
                            CEO, POOL SERVICE OF AMERICA
                          </span>{' '}
                        </p>
                      </span>{' '}
                    </h3>
                  </div>
                  <div>
                    <h3 className='contentStyle' >Content yet to be published</h3>
                  </div>
                  <div>
                    <h3 className='contentStyle'>Content yet to be published</h3>
                  </div>
                </Carousel>
              </Col>
            </Col>
            <Col lg={12} style={{ backgroundColor: 'white', height: '700px' }}>
              <Col className='authHeaders'>
                {' '}
                <p className='authContent1'>On-Demand Tolling Made Easy</p>
                <p className='authContent2'>
                  No more monthly down payments. Pay as you go!
                </p>
              </Col>
              <Col lg={20} className='authsubmit'>
                <Form
                  onSubmit={this.handleSubmit}
                  layout='vertical'
                  ref={this.formRef}>
                  <Form.Item
                    name='email'
                    validateTrigger='onBlur'
                    rules={[
                      {
                        required: true,
                        message: 'Please provide your email address',
                        validationTrigger: 'onBlur',
                      },
                      {
                        type: 'email',
                        message: 'Please provide a valid email address',
                        validationTrigger: 'onBlur',
                      },
                    ]}
                    label='WORK EMAIL ADDRESS'
                    className='authlabelsemail'>
                    <ReuseInputField
                      className={`${this.state.showError?'textboxErrror':''}`}
                      type='text'
                      placeholder='john@mainstreet.com'
                      height='52px'
                      value={email}
                      onBlur={this.handleBlur}
                      onChange={this.handleChange('email')}
                    />
                  </Form.Item>
                  {this.state.showError &&
                    re.test(String(email).toLowerCase()) && (
                      <p className='errorMessageReg'>
                        <span>{this.state.errorMessage}</span>
                      </p>
                    )}
                  <p style={{ marginBottom: '24px' }}></p>
                  {/*<Form.Item*/}
                  {/*  name='company'*/}
                  {/*  rules={[*/}
                  {/*    { required: true, message: 'Please select a company' },*/}
                  {/*  ]}*/}
                  {/*  label='COMPANY'*/}
                  {/*  className='authlabels'>*/}
                  {/*  {!this.state.default ? (*/}
                  {/*    <Select*/}
                  {/*      placeholder='Select one'*/}
                  {/*      value={this.state.company}*/}
                  {/*      showSearch*/}
                  {/*      style={{ width: '100%' }}*/}
                  {/*      filterOption={(input, option) =>*/}
                  {/*        option.props.children*/}
                  {/*          .toLowerCase()*/}
                  {/*          .indexOf(input.toLowerCase()) >= 0*/}
                  {/*      }*/}
                  {/*      onChange={e => this.handleCompanyChange('company', e)}>*/}
                  {/*      {this.state.users.map(usr => (*/}
                  {/*        <Select.Option key={usr.id} value={usr.id}>*/}
                  {/*          {usr.company}*/}
                  {/*        </Select.Option>*/}
                  {/*      ))}*/}
                  {/*    </Select>*/}
                  {/*  ) : (*/}
                  {/*    <Select*/}
                  {/*      placeholder='Select one'*/}
                  {/*      value={this.state.company}*/}
                  {/*      showSearch*/}
                  {/*      style={{ width: '100%' }}*/}
                  {/*      filterOption={(input, option) =>*/}
                  {/*        option.props.children*/}
                  {/*          .toLowerCase()*/}
                  {/*          .indexOf(input.toLowerCase()) >= 0*/}
                  {/*      }*/}
                  {/*      onChange={e => this.handleCompanyChange('company', e)}>*/}
                  {/*      {this.state.users.map(usr => (*/}
                  {/*        <Select.Option key={usr.id} value={usr.id}>*/}
                  {/*          {usr.company}*/}
                  {/*        </Select.Option>*/}
                  {/*      ))}*/}
                  {/*    </Select>*/}
                  {/*  )}*/}
                  {/*</Form.Item>*/}
                  <p className='forgotPassword'>
                    <Link
                      className='forgotPasswordLink'
                      to='/unytag/web/forgot_password'>
                      Forgot your password?
                    </Link>
                  </p>
                  <Form.Item
                    name='password'
                    rules={[
                      {
                        required: true,
                        message: 'Please provide your password',
                      },
                    ]}
                    label='PASSWORD'
                    className='authlabels passwordHeader'>
                    <ReuseInputField
                      type='password'
                      height='52px'
                      value={password}
                      onChange={this.handleChange('password')}
                    />
                  </Form.Item>
                  {/* } */}
                  {/* <p className="errorMessage">
                    {this.state.showError && (
                      <span>{this.state.errorMessage}</span>
                    )}
                  </p> */}
                  <Form.Item>
                    <ReuseButton
                      text='LOG IN'
                      htmlType='submit'
                      className='center1auth'
                      onClick={this.handleSubmit}
                      disabled={this.state.disableBtn}
                    />
                  </Form.Item>
                </Form>
                <p className='createNew'>
                  Don’t have an account?{' '}
                  <a href='https://appstoreconnect.apple.com/apps/1538270508/recent/activity/ios/builds/' target="_blank">
                    {' '}
                    <span className='dwnldClr'>Download the app first!</span>
                  </a>
                </p>
              </Col>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Tabs } from '../common/AntdUiComponents';
import HeaderLinks from '../layouts/HeaderLinks';
import ProfileTab from '../components/profile/Profile';
import PasswordTab from '../components/profile/Password';
import TeamManagement from '../components/profile/TeamManagement';

const { TabPane } = Tabs;

const ProfileManagement = () => {
  const location = useLocation();
  const [name, setName] = useState('');

  const setUserName = (_name) => {
    setName(_name);
  };
  return (
    <div>
      <div>
        <HeaderLinks path={location.pathname} />
        <Col
          lg={24}
          style={{
            padding: '0px 128px 0px',
            backgroundColor: '#0057C1',
            color: 'white',
          }}
        >
          <span className="profileBorder" />
          <span className="profileName"> {name.toUpperCase()}</span>
        </Col>
        <Tabs defaultActiveKey="1" className="profileTabs">
          <TabPane tab="PROFILE" key="1">
            <ProfileTab sendName={setUserName} />
          </TabPane>
          <TabPane tab="PASSWORD" key="2">
            <PasswordTab />
          </TabPane>
          <TabPane tab="TEAM MANAGEMENT" key="3">
            <TeamManagement />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ProfileManagement;

/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  message,
  Select,
  Checkbox,
  FormItem
} from '../../common/AntdUiComponents';
import AddressForm from '../../common/Addressform';
import { alertMessage, validateAddressForm, isEmpty } from '../../common/Common';

const roles = ['User', 'Admin'];
const Profile = () => {
  const params = useParams();
  const [validateElements, setValidateElements] = useState([]);
  const role = roles.filter((userRole) => userRole === params.type);
  const [state, setState] = useState({
    fullName: '',
    companyName: '',
    email: '',
    company: '',
    billing: '',
    shipping: '',
    phone: '',
    role: '',
  });
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [isSameAddress , setIsSameAddress] = useState(false);
  const [companyErrors, setCompanyErrors] = useState([]);
  const [companyError, showCompanyError] = useState(false);
  const [shippingErrors, setShippingErrors] = useState([]);
  const [shippingError, showShippingError] = useState(false);
  const [addressComObj, setaddressComObj] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [addressShipObj, setaddressShipObj] = useState({});
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  useEffect(() => {
    const isEdit = () => {
      if (params.name !== undefined) {
        setIsEditProfile(true);
      } else {
        setIsEditProfile(false);
      }
    };
    isEdit();
  }, [params]);

  const handleProfile = e => {
    setIsEditProfile(!isEditProfile);
    setInProgress(true)
    //e.preventDefault();
      // message.success({
      //   content: 'ALL CHANGES HAVE BEEN SAVED!',
      //   className: 'bg-green',
      //   duration: 5,
      // });
    const formData = {};
    if (state.fullName && state.fullName.trim().length !== 0  ) {
      if (re.test(String(state.email).toLowerCase())) {
      if(state.companyName && state.companyName.trim().length !== 0){
      if(state.phone && state.phone.trim().length > 8){
      if(state.role && state.role.trim().length !== 0){
      formData.fullName = state.fullName;
      formData.companyName = state.companyName;
      formData.phone = state.phone.includes('+') ? state.phone : '+' + state.phone;
      formData.role = state.role;
      formData.billingAddress1 =
      state.company.address_line1.trim();
    formData.billingAddress2 =
      state.company.address_line2.trim();
    formData.billingCity =
      state.company.city.trim();
    formData.billingCountry =
      state.company.country.trimLeft();
    formData.billingState =
      state.company.state.trim();
    formData.billingZipCode =
      state.company.zipcode.trim();
    if (isSameAddress) {
      formData.shippingAddress1 =
        state.company.address_line1.trim();
      formData.shippingAddress2 =
        state.company.address_line2.trim();
      formData.shippingCity =
        state.company.city.trim();
      formData.shippingCountry =
        state.company.country.trimLeft();
      formData.shippingState =
        state.company.state.trim();
      formData.shippingZipCode =
        state.company.zipcode.trim();
      
    console.log(formData);
    }
    else {
      if(state.shipping !== ""){
      formData.shippingAddress1 =
        state.shipping.address_line1.trim();
      formData.shippingAddress2 =
        state.shipping.address_line2.trim();
      formData.shippingCity =
        state.shipping.city.trim();
      formData.shippingCountry =
        state.shipping.country.trimLeft();
      formData.shippingState =
        state.shipping.state.trim();
      formData.shippingZipCode =
        state.shipping.zipcode.trim();
      console.log(formData);
      } else {
        setInProgress(false);
      alertMessage('PLEASE ENTER SHIPPING ADDRESS', 3);
      }
    }
  } else {
    setInProgress(false);
    alertMessage('PLEASE SELECT ROLE', 3);
  }
  } else {
    setInProgress(false);
    alertMessage('PLEASE ENTER VALID PHONE NUMBER', 3);
  }
  } else {
    setInProgress(false);
    alertMessage('PLEASE ENTER COMPANY NAME', 'error', 3);
  }
} else {
  setInProgress(false)
  alertMessage('PLEASE ENTER VALID EMAIL ADDRESS', 'error', 3);
}
    } else {
      setInProgress(false)
      alertMessage('PLEASE ENTER FULL NAME', 'error', 3);
    }
  };

  const handleOnChange = (key, value) => {
    //console.log(key, value);
    setState({
      ...state,
      [key]: value,
    });
  }  

  const handleOnAddressChange = (addressType, element, value, obj) => {
    const arr = [...new Set([...element, ...validateElements])];
    setValidateElements(arr);
    const addrressError = validateAddressForm(arr, obj);
    const errors = [];
    if (addressType === 'company') {
      setaddressComObj(obj)
      if (addrressError) {
        errors.push(`${addrressError}`);
        setCompanyErrors(errors);
        showCompanyError(true);
      } else {
        showCompanyError(false);
      }
    } else {
      setaddressShipObj(obj)
      if (addrressError) {
        errors.push(`${addrressError}`);
        setShippingErrors(errors);
        showShippingError(true);
      } else {
        showShippingError(false);
      }
    }

    setState({
      ...state,
      [addressType]: obj,
    });
    // setaddressObj(obj)
  };

  const onFocus = (type) => {
    const validateFields = ['address_line1', 'city', 'state', 'country', 'zipcode'];
    if (type === 'company') {
      handleOnAddressChange(type, validateFields, "", addressComObj)
    } else {
      handleOnAddressChange(type, validateFields, "", addressShipObj)
    }
  }

  const handleCheckboxChange = (defaultvalue = false) => {
    setIsSameAddress(defaultvalue);
  }

  //const profileObj = Object.values(profile);
  return (
    <div>
      <p className="PageTitle">
        <Row type="flex" justify="space-between">
          {params.name !== 'admin' && (
            <span className="profileTitle">{params.name}</span>
          )}
          {/* {isEditProfile ? (
            <Button
              className="AddButton"
              onClick={() => {
                handleProfile('edit');
              }}
            >
              {params.name !== undefined ? 'EDIT PROFILE' : 'ADD PROFILE'}
            </Button>
          ) : ( */}
            <Button
              className="btn-style btn-success SaveButton"
              onClick={() => {
                handleProfile('save');
              }}
            >
              SAVE
            </Button>
          {/* )} */}
        </Row>
      </p>
      <Row className="Basecontainer" gutter={20}>
          <div className="profile-container">
            <Form>
              <Col md={12}>
              <Form.Item>
                <div className="ant-form-item-label">
                  <label>FULL NAME<span className='authlabels'>*</span></label>
                </div>
                <Input
                        className="input-field"
                        type='text'
                        value={state.fullName}
                        placeholder='Full Name'
                        onChange={e => handleOnChange('fullName', e.target.value)}
                        style={{ marginBottom: 20 }}
                  />
                </Form.Item>
                </Col>
                <Col md={12}>
                <Form.Item>
                <div className="ant-form-item-label">
                  <label>WORK EMAIL ADDRESS (username)<span className='authlabels'>*</span></label>
                </div>
                <Input
                        className="input-field"
                        type='email'
                        value={state.email}
                        placeholder='Email'
                        onChange={(e) =>
                          handleOnChange('email', e.target.value)
                        }
                        style={{ marginBottom: 20 }}
                />
                </Form.Item>
                </Col>
                <Col md={12}>
              <Form.Item>
                <div className="ant-form-item-label">
                  <label>COMPANY NAME<span className='authlabels'>*</span></label>
                </div>
                <Input
                        className="input-field"
                        type='text'
                        value={state.companyName}
                        placeholder='Company Name'
                        onChange={(e) =>
                          handleOnChange('companyName', e.target.value)
                        }
                        style={{ marginBottom: 20 }}
                  />
                </Form.Item>
                </Col>
                <Col md={12}>
              <Form.Item>
                <div style={{ margin: '3em 0 5px 8px' }}>
                          <Checkbox
                            onChange={e => handleCheckboxChange(e.target.checked)}
                            checked={isSameAddress}
                            style={{ marginTop: '5px' }}
                          />
                          <span>&nbsp;Shipping address is same as billing address</span>
                        </div>
                </Form.Item>
                </Col>
                <Col md={12}>
                  <div className="ant-form-item-label">
                    <label>BILLING ADDRESS<span className='authlabels'>*</span></label>
                  </div>
                  <AddressForm
                autoComplete
                twoRows
                showReqFields
                address={state.company}
                onFocus={() => onFocus('company')}
                onChange={(element, value, obj) =>
                  handleOnAddressChange('company', [element], value, obj)
                }
                showError={companyError}
                errors={companyErrors}
                addressType='biiling'
                //disabled={isEdit && editSaveBtn}
              />
                </Col>
                {
              !isSameAddress &&
                <Col md={12}>
                  <div className="ant-form-item-label">
                    <label>SHIPPING ADDRESS<span className='authlabels'>*</span></label>
                  </div>
                  <AddressForm
                  autoComplete
                  twoRows
                  showReqFields
                  onFocus={() => onFocus('shipping')}
                  address={state.shipping}
                  onChange={(element, value, obj) =>
                    handleOnAddressChange('shipping', [element], value, obj)
                  }
                  showError={shippingError}
                  errors={shippingErrors}
                  addressType='shipping'
                  //disabled={isEdit && editSaveBtn}
                />
                </Col>
}
                <Col md={12}>
                  <div className="ant-form-item-label">
                    <label>PHONE NUMBER<span className='authlabels'>*</span></label>
                  </div>
                    <PhoneInput
                      country='us'
                      onlyCountries={['us', 'in']}
                      specialLabel=''
                      countryCodeEditable
                      value={state.phone}
                      //disabled={isEdit}
                      onChange={e =>
                        handleOnChange('phone', e)
                      }
                    />
                </Col>
                <Col md={12}>
                <div className="ant-form-item-label">
                    <label>ROLE<span className='authlabels'>*</span></label>
                  </div><br/>
                  <Select
                        placeholder="Select Role"
                        value={state.role}
                        disabled={isEditProfile}
                        style={{marginTop: '0.5em', width: '100%'}}
                        onChange={e =>
                          handleOnChange('role', e)
                        }
                      >
                        {roles.map((Userrole) => (
                          <Select.Option key={Userrole} value={Userrole}>
                            {Userrole}
                          </Select.Option>
                        ))}
                      </Select>
                </Col>
            </Form>
          </div>
      </Row>
    </div>
  );
};

export default Profile;

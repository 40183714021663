import React from 'react'
import { GoogleMap, DrawingManager, useLoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const AddTollPlazaGoogleMap = ({
    coords,
    onEditPolygon,
    onEditMarker
}) => {

    const onDrawPolygonComplete = (polygon) => {
        const coordsArr = polygon.getPath().getArray().map((a) => [a.lng(), a.lat()]);
        onEditPolygon(JSON.stringify(coordsArr));
    }

    const onEditPolygonLocal = (polygon) => {
        const coordsArr = polygon.getPath().getArray().map((a) => [a.lng(), a.lat()]);
       
    }

    const onDrawMarkerComplete = (marker) => {
        onEditMarker(marker);
    }

    const { isLoaded } = useLoadScript({
        id: 'google-map-scripto',
        googleMapsApiKey: "AIzaSyD7lLQ2s8LcNXvZuUPdHiWyepbaUrbkKjs",
        libraries: ["drawing"]
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: coords.lat, lng: coords.lng }}
            zoom={10}
        >
            <DrawingManager
                options={{
                    markerOptions: {
                        draggable: true,
                    },
                    polygonOptions: {
                        draggable: true,
                        editable: true,
                    }
                }}
                drawingMode={["polygon", "marker"]}
                onPolygonComplete={(pol) => onDrawPolygonComplete(pol)}
                onMarkerComplete={(mar) => onDrawMarkerComplete(mar)}
            />
        </GoogleMap>
    ) : <></>
    }

    export default AddTollPlazaGoogleMap;
import React, { useState } from 'react';
import 'react-phone-input-2/lib/material.css';
import { Button, Col,Icon, Row, Table} from '../../common/AntdUiComponents';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import { EnterpriseProfileForm } from './EnterpriseProfileForm';

const List = ({
  onSelectTitle,
  data,
  pagination,
  tableChange,
  loadEnterPrise,
  pageSizeOptions
}) => {
  const [showViewModal, setShowViewModal] = useState(false);

  const handleTitleChange = (name, id) => {
    onSelectTitle(name, 'category', id);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'customer',
      key: 'customer',
      render: text => {
        return (
          <span>{text && text.charAt(0).toUpperCase() + text.slice(1)}</span>
        );
      },
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, objData) => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon
            type='arrow-right'
            onClick={() => handleTitleChange(objData.customer, objData.id)}
            style={{ fontSize: '30px' }}
          />
        </span>
      ),
      align: 'right',
    },
  ];


  const addEnterprise = () => {
    setShowViewModal(true);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
  };

  const renderViewModel = () => {
    return (
      <BaseModal width='70%' onCancel={() => handleOnModalClose()}>
        <EnterpriseProfileForm loadEnterPrise={loadEnterPrise} handleOnModalClose={handleOnModalClose} isEdit={false} />
      </BaseModal>
    );
  };

  return (
    <div className='Basecontainer'>
      <Row gutter={30}>
        <Col span={24} className='tablePadding mar-top-20'>
          <p className='TitleText mar-bottom-20'>
            Manage all of your enterprise customers here.
          </p>
          <p className='TitleText mar-bottom-20 addTollAuthBtn'>
            <Button className='AddButton' onClick={addEnterprise}>
              ADD ENTERPRISE
            </Button>
          </p>
          <Table
            className='BaseTable userCategories'
            dataSource={data}
            columns={columns}
            showHeader={false}
            pagination={pagination}
            onChange={tableChange}
            pageSizeOptions={pageSizeOptions}
            onRow={record => {
              return {
                onClick: () => {
                  handleTitleChange(record.customer, record.id);
                },
              };
            }}
          />
        </Col>
      </Row>
      {showViewModal && renderViewModel()}
    </div>
  );
};

export default List;

import notificationReducer from './notificationReducer';
import notificationCounterReducer from './notificationCounterReducer';
import breadcrumbReducer from './breadcrumbReducer';
import {combineReducers} from 'redux'

const allReducers = combineReducers({ 
    notifications: notificationReducer, 
    notification_count: notificationCounterReducer,
    breadcrumb_links: breadcrumbReducer
})

export default allReducers;
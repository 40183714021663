import _ from 'lodash';
import { message } from './AntdUiComponents';

function alertMessage(messageToShow, type, duration = 2) {
  switch (type) {
    case 'warning': {
      message.warning({
        content: messageToShow,
        className: 'warning-class',
        style: {
          margin: '50px 0vh 2vh 180px',
        },
        duration,
      });
      return;
    }
    case 'error': {
      message.error({
        content: messageToShow,
        className: 'error-class',
        style: {
          margin: '50px 0vh 2vh 180px',
        },
        duration,
      });
      return;
    }
    case 'info': {
      message.error({
        content: messageToShow,
        className: 'info-class',
        style: {
          margin: '50px 0vh 2vh 180px',
        },
        duration,
      });
      return;
    }
    default: {
      message.success({
        content: messageToShow,
        className: 'success-class',
        style: {
          margin: '50px 0vh 2vh 180px',
        },
        duration,
      });
    }
  }
}

const checkTextValidation = (value) => {
  return value !== '' && value !== undefined;
};

const userMeetsPolicy = (userType, role) => {
  return _.includes(role,userType);
}

function validateAddressForm(addressReqFields = [], obj = {}) {
  let errors = [];
  addressReqFields && addressReqFields.length > 0 && addressReqFields.forEach((x) => {
    if (_.isNil(obj[x]) || !obj[x]) {
      if(x!=='address_line2'){
        x= x.charAt(0).toUpperCase() + x.slice(1)
        errors.push(x);
      errors = errors.filter(item => item);
      }
    }
  });

  if (errors.length > 0) {
      return `${errors.join(', ').charAt(0).toUpperCase() +  errors.join(', ').slice(1)} ${errors.length === 1 ? ' is' : ' are'}  required`;
    }
  // if (errors.length > 0 && errors.length<5) {
  //   return `${errors.join(', ').charAt(0).toUpperCase() +  errors.join(', ').slice(1)} ${errors.length === 1 ? ' is' : ' are'}  required`;
  // }
  // if(errors.length === 5){
  //   return `Address is required`;
  // }
  return '';
}

function sorters(type) {
  switch(true){
    case type==='ascend':
      return 'asc';
    case type==='descend':
      return 'desc';
    case type=== null:
      return ''
    default:
      return '';
}
}

function isEmpty(value){
  if(value==="" || value===null || value===undefined) 
   return true;
  else
   return false;
}


export { _, alertMessage, checkTextValidation,userMeetsPolicy,validateAddressForm,sorters,isEmpty };

import { Call, ApiUrl, ParseGeneralResponse, PageParams } from './ApiUtils';
import AppConfig from '../config/AppConfig';

export function orderTag(data) {
  const method = 'post';
  const url = ApiUrl('/unytag/enterprise/tag');
  const dataParameters = data;
  return Call(method, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function getAllTags(value='',page=1,perPage = AppConfig.perPage,id='',order='') {
  const url = ApiUrl(`/unytag/tag?search=${encodeURIComponent(value)}&${PageParams(
    page,
    perPage,
  )}&sortBy=${id}&sortOrder=${order}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}

export function getTags(name, id, page = 1, perPage = AppConfig.perPage) {
  const url = ApiUrl(
    `/unytag/enterprise/user/taglist?enterprise=${encodeURIComponent(name)}&${PageParams(
      page,
      perPage,
    )}&sortBy=${id}`,
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}

export function getOrderedTags(search='', page = 1, perPage = 5,searchStatus='',id='',order='',tagOrderStatus=false) {
  const url = ApiUrl(`/unytag/tag/tagStatus?pageNo=${page}&pageSize=${perPage}&search=${search}&searchStatus=${searchStatus}&sortBy=${id}&sortOrder=${order}&tagOrderStatus=${tagOrderStatus}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}
import React, { useEffect, useState } from 'react';
import { Badge, Avatar, Menu, Dropdown } from '../common/AntdUiComponents';
import { ArrowLeftOutlined, BellOutlined, DownOutlined } from '@ant-design/icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { useSelector, useDispatch} from 'react-redux';
import { handleNotification, handleNotifCount } from '../reducer/actions/actions';
import { ApiUrl } from '../api/ApiUtils';


const HeaderLinks = () => {
  let history = useHistory();
  let location = useLocation();
  
  const dispatch = useDispatch();
  const user = localStorage.getItem('user');
  const userDetails = user!==''? JSON.parse(user) : { };
  const notifCount = useSelector(state => state.notification_count.notification_count);

  useEffect(() => {
    var sock = new SockJS('https://demo.unytag.com/unytag/ws');
    let stompClient = Stomp.over(sock);
    sock.onopen = function() {
      console.log('open');
    }
    stompClient.connect({}, function (frame) {
      stompClient.subscribe('/topic/notifications', function (notifications) {
        const body = JSON.parse(notifications.body);
        const clearMessages = true;
        dispatch(handleNotification(body, clearMessages));
        dispatch(handleNotifCount(notifCount, 1));
      });
    });
  }, []);

  const menu = (
    <Menu onClick={event => handleMenuClick(event)}> 
    {!(location.pathname === '/unytag/web/overview') &&
      <Menu.Item key='1'>Home</Menu.Item>}
      <Menu.Item key='2'>Logout</Menu.Item>
      {!(location.pathname === '/unytag/web/profile') && 
      <Menu.Item key='3'>
        View Profile
      </Menu.Item>}
    </Menu>
  );

  const handleMenuClick = e => {
    if (e.key === '2') {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      history.push('/unytag/web/');
      window.location.reload(false);
    }
    else if (e.key === '3') {
      history.push('/unytag/web/profile');
      window.location.reload(false);
    }
else {
  history.push('/unytag/web/overview');
  window.location.reload(false);
}
  };


  const displayAvatar =(displayName)=>{
    if(displayName.length===1){
   return displayName.join().toUpperCase().repeat(2).split('').join(' ');
    }
    else {
      return displayName.join(" ").toUpperCase();
    }
   }
   
  return (
    <div
      className={
        location.pathname === '/unytag/web/profile'
          ? 'HeaderDispaly1'
          : 'HeaderDispaly'
      }>
        <div onClick={()=>history.goBack()} style={{cursor:"pointer"}}>
       {location.pathname === '/unytag/web/profile' && <ArrowLeftOutlined style={{color:"#fff", margin:"0.5rem",fontSize:"1rem"}} />}
       </div>
       <div>
      <Badge count={notifCount} className='NotificationBadge' offset={[-10, 10]}>
        <Avatar
          className={
            location.pathname === '/unytag/web/profile'
              ? 'NotificationIconProfile'
              : 'NotificationIcon'
          }
          size='large'
          icon={<BellOutlined />}
        />
      </Badge>
      
      <span className='AvatarIcon'>
        <Avatar shape='square' className= {
            location.pathname === '/unytag/web/profile'
              ? 'avtarBackground'
              : 'avtarBackgroundBlue'
          }>
{userDetails &&  displayAvatar(userDetails.contactName.match(/\b(\w)/g))} 
        </Avatar>
      </span>
      <span></span>
      <Dropdown overlay={menu}>
        <Avatar
          className={
            location.pathname === '/unytag/web/profile'
              ? 'DropdownBtnListProfile'
              : 'DropdownBtnList'
          }
          size='large'
          icon={<DownOutlined />}
        />
      </Dropdown>
      </div>
    </div>
  );
};

export default HeaderLinks;

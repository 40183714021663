import React, { useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Row, Col } from '../../common/AntdUiComponents';
import { alertMessage } from '../../common/Common';

import useResponsiveFontSize from './useResponsiveFontSize';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      hidePostalCode: true,
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  );

  return options;
};

const CardForm = ({ closeModal, sendToken, isUpdate }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [visible, setVisible] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  const handleSubmit = async (event) => {
    setDisableBtn(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if (payload.error === undefined) {
      sendToken(payload.paymentMethod);
      closeModal();
      setDisableBtn(false)
    } else {
      alertMessage(payload.error.message, 'error', 3);
      setDisableBtn(false)
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          <Row>
            <Col lg={24} className="cardHeader">
              Card details
            </Col>
          </Row>
          <Col lg={24} className="cardHeader">
            <CardElement
              options={options}
              onReady={() => {
              }}
              onChange={(event) => {
                if (
                  event.error === undefined &&
                  event.value.postalCode !== ''
                ) {
                  setVisible(true);
                }
              }}
              onBlur={(event) => {
                if (event.error === undefined) {
                  setVisible(true);
                }
              }}
              onFocus={() => {
              }}
            />
          </Col>
        </label>
        <Col lg={24} className="cardHeader">
          <button
            type="submit"
            className="ant-btn btn-style btn-info  center1auth SaveButton"
            disabled={!stripe || !visible || disableBtn}
          >
            {isUpdate ? "Update" : "Save"}
          </button>
        </Col>
      </form>
    </div>
  );
};

export default CardForm;

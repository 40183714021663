/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import EnterpriseCategory from './CategoryList';
import { Spin,Row,Col } from '../../common/AntdUiComponents';
import UserAccounts from './UserAccounts';
import Profile from '../tollAuthority/Profile';
import { fetchEnterpriseUsers } from '../../api/enterprise/enterpriseUser';
import ProfileList from './ProfilesList';
import TripHistory from '../../utils/reusableComponents/tripHistory/TripHistory';
import PaymentMethod from '../../utils/reusableComponents/payments/PaymentMethod';
import { sorters, isEmpty } from '../../common/Common';
import { EnterpriseProfileForm } from './EnterpriseProfileForm';
import EnterpriseUserForm from './EnterPriseUserForm';
import createBrowserHistory from 'history/createBrowserHistory';

const Enterprises = () => {
  const params = useParams();
  const location = useLocation();
  //const history = useHistory();
  const [title, setTitle] = useState(params.name);
  const [userId, setId] = useState(params.id);
  const [profileId, setProfileId] = useState('');
  const [listVisible, setListVisible] = useState('category');
  const [enterpriseName, setEnterpriseName] = useState('Enterprise');
  const [userData, setEnterprisesUserData] = useState([]);
  const [searchField, setSearchField] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [pagination, setPagination] = useState({});
  const [perPage,setPerPage] = useState(20)
  const [adminsCount,setAdminsCount] = useState('')
  const [usersType,setUserType] = useState('');
  const [sortKey,setSortKey] = useState('')
  const [sortOrder,setSortOrder] = useState('')
  const [isEditEnterpriseProfile,setIsEditEnterpriseProfile]=useState(false);

  let tableOptions = { pagination: {}, filters: {}, sorter: {} };
  const user = localStorage.getItem('user');
  const userDetails = user!==''? JSON.parse(user) : { };

  const getEnterpriseUsers = (value,pageSizeCount,sortkey,sorter) => {
    setInProgress(true);

    if (location.pathname === '/unytag/web/accounts') {
      setEnterpriseName(userDetails.company);
      setTitle(userDetails.company);
      setListVisible('');
    }

    const page = tableOptions.pagination.current;
    fetchEnterpriseUsers(
      userDetails.userType === 'Enterprise' ? userDetails.company : title,
      page,
      pageSizeCount,value,sortkey,sorter
    ).then(result => {
      if (result.success) {
        if (result.data.records !== null) {
          setPagination(result.pagination);
          setAdminsCount(result.adminsCount)
          setEnterprisesUserData(result.data.records);
          setInProgress(false);
          setUserType(result.data.records[0].accountType)
        } else {
          setPagination({});
          setEnterprisesUserData([]);
          setInProgress(false);
        }
      } else {
        setPagination({})
        setEnterprisesUserData([]);
        setInProgress(false);
      }
    });
  };

  const history = createBrowserHistory();
  useEffect(() => {
    const prevScreen = localStorage.getItem('prevScreen');
    if (prevScreen != null) {
      getEnterpriseUsers('',perPage);
      setListVisible(prevScreen);
      setEnterpriseName(title);
      localStorage.removeItem("prevScreen");
      return;
    }
    if(userDetails.userType === 'Enterprise' && params.payment === 'Payment Method'){
      setListVisible('Payment Method')
      setTitle('Payment Method');
    }else{
      getEnterpriseUsers('',perPage);
    };
    if(params.category === 'profileList') {
      setListVisible('profileList');
    }else{
      setListVisible('category');
    }
    return history.listen(location => {
      if (history.action === 'POP') {
        window.location.reload(false);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-shadow
  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize)
    let sorterOrder='';
    let sorterkey=''
    sorter.field = sorter.field === 'name' ? 'firstName':sorter.field
    console.log(sorters(sorter.order))
        sorterOrder = sorters(sorter.order);
      sorterkey = sorters(sorter.order)===''?'': sorter.field;
       setSortKey(sorters(sorter.order)===''?'': sorter.field);
       setSortOrder(sorters(sorter.order))
    getEnterpriseUsers(searchField,pagination.pageSize,sorterkey,sorterOrder);
  };

  const handleEntUserSearch = value => {
    setSearchField(value)
    getEnterpriseUsers(value,perPage);
  };

  const handleSearch = value => {
    if (listVisible === 'category' || listVisible === 'Trip History & Statements') {
      setSearchField(value);
    }
    if (listVisible === 'Profile') {
      getEnterpriseUsers(value,perPage);
    }
  };

  const loadEntUsers = () => {
    getEnterpriseUsers('',perPage);
  };

  const handleEditEnterpriseProfile =value =>{
    setIsEditEnterpriseProfile(value)
  }

  const handleTitle = (langValue, info, nextState, role, id) => {
    setProfileId(id);
    if (nextState === 'Users') {
      setTitle(langValue);
    } else if (nextState === 'Trip History & Statements') {
      setTitle('Trip History');
    } else if (nextState === 'Payment Method') { 
      setTitle('Payment Method');
    }
    setListVisible(nextState);
    if (listVisible === 'list') {
      setEnterpriseName(langValue);
    }
    if (nextState === 'Users' || nextState === 'Profile') {
      setEnterpriseName(langValue);      
    }
    if(nextState === 'Edit Profile'){
      setIsEditEnterpriseProfile(false);
      setListVisible('Edit Profile');
    } 
    else {
      if(nextState === 'Profile'){
        history.push(`/unytag/web/enterprise/profileList/${langValue}/${userId}`);
      } 
      else {
        setEnterpriseName(langValue);
        history.push(`/unytag/web/enterprise/info/${langValue}/${userId}`);
      }
    }
  };
  
  return (
    <div>
      <Spin spinning={inProgress}>
        {(listVisible === 'list' ||
          listVisible === 'category' ||
          listVisible === 'Profile' ||
          listVisible === 'Payment Method') && (
          <p className='ContainerTitleHeader'>
            {(listVisible === 'Profile' && !isEditEnterpriseProfile) && (
              <span className='PageTitle'>User Profiles for </span>
            )}{' '}
            {isEditEnterpriseProfile && <span className='PageTitle'>Edit{" "}</span>}
            <span className='PageTitle'>
              {title && title.charAt(0).toUpperCase() + title.slice(1)}{' '} 
            </span>
           {!isEditEnterpriseProfile && 
           <ReuseInputField
              placeholder='Search...'
              className='SearchBar'
              onChange={handleSearch}
            />}
          </p>
        )}
        {listVisible === 'category' ? (
          <EnterpriseCategory
            entName={title}
            onSelectTitle={handleTitle}
            dataValue={searchField}
            userId={userId}
          />
        ) : listVisible === 'Profile' || params.category === 'profileList' ? (
            isEditEnterpriseProfile ? 
            <div className='Basecontainer'>
            <Row gutter={30}>
              <Col span={24} className='tablePadding mar-top-20'>      
              <EnterpriseProfileForm isEdit={true} enterpriseId={params.id}/>
              </Col>
              </Row>
              </div>
    : 
          <ProfileList onSelectTitle={handleTitle} data={userData}
          handleUserSearch={handleEntUserSearch}
          adminsCount={adminsCount}
          handleEditEnterpriseProfile={handleEditEnterpriseProfile}
          pagination={
            pagination !== null
              ? {
                  total: parseInt(pagination.totalCount, 10),
                  current: parseInt(pagination.currentPage, 10),
                  pageSize: parseInt(pagination.perPage, 10),
                  pageSizeOptions: [5,10,20],
                }
              : {}
          }
          // eslint-disable-next-line no-shadow
          tableChange={(pagination, filters, sorter) =>
            handleTableChange(pagination, filters, sorter)
          } />
        ) : listVisible === 'Edit Profile' ? (
        <div className='Basecontainer'>
              <Row gutter={30}>
                <Col span={24} className='tablePadding mar-top-20'>      
                <EnterpriseUserForm isEdit={true} id={profileId} enterpriseId={params.id}/>
                </Col>
                </Row>
                </div> 
        )
        : listVisible === 'userProfile' ? (
          <Profile />
        ) : listVisible === 'Trip History & Statements' ? (
          <TripHistory  searchField={searchField}/>
        ) : listVisible === 'Payment Method' ? (
          <PaymentMethod type='enterprise' />
        ) : listVisible === 'profileList' ? (
          <ProfileList onSelectTitle={handleTitle} data={userData}
          handleUserSearch={handleEntUserSearch}
          adminsCount={adminsCount}
          handleEditEnterpriseProfile={handleEditEnterpriseProfile}
          pagination={
            pagination !== null
              ? {
                  total: parseInt(pagination.totalCount, 10),
                  current: parseInt(pagination.currentPage, 10),
                  pageSize: parseInt(pagination.perPage, 10),
                  pageSizeOptions: [5,10,20],
                }
              : {}
          }
          // eslint-disable-next-line no-shadow
          tableChange={(pagination, filters, sorter) =>
            handleTableChange(pagination, filters, sorter)
          } />
        ) : (
          <UserAccounts
          usersType={usersType}
            name={enterpriseName}
            adminsCount={adminsCount}
            onSelectTitle={handleTitle}
            id={params.id !== undefined ? params.id : userDetails.companyId}
            userData={userData}
            loadEntUsers={loadEntUsers}
            data={userData}
            handleUserSearch={handleEntUserSearch}
            pagination={
              pagination !== null
                ? {
                    total: parseInt(pagination.totalCount, 10),
                    current: parseInt(pagination.currentPage, 10),
                    pageSize: parseInt(pagination.perPage, 10),
                    pageSizeOptions: [5,10,20],
                  }
                : {}
            }
            // eslint-disable-next-line no-shadow
            tableChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
          />
        )}
      </Spin>
    </div>
  );
};

export default Enterprises;

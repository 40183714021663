/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import _ from 'lodash';
import List from '../components/orderedTags/List';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../utils/reusableComponents/ReuseButton';
import BaseModal from '../utils/reusableComponents/BaseModal';
import { Row, Col, AutoComplete, Spin, Form } from '../common/AntdUiComponents';
import AddressForm from '../common/Addressform';
import { alertMessage } from '../common/Common';
import { fetchEnterprices } from '../api/enterprise/enterprise';
import { orderTag, getOrderedTags } from '../api/tags';
import { validateAddressForm, sorters } from '../common/Common';
import AppConfig from '../config/AppConfig';
import { handleBreadcrumbLinks } from '../reducer/actions/actions';
import { useSelector, useDispatch} from 'react-redux';

const { Option } = AutoComplete;
const user = localStorage.getItem('user');
const userDetails = user !== '' ? JSON.parse(user) : {};

const OrderedTags = () => {
  const params = useParams();
  const history = useHistory();
  const [title, setTitle] = useState('Status of Ordered Tags');
  const [showViewModal, setShowViewModal] = useState(false);
  const [result, setResult] = useState([]);
  const [enterprisesData, setEnterprisesData] = useState([]);
  const [ParentAccount, setParentAccount] = useState('');
  const [userData, setUserData] = useState([]);
  const [inProgress, setInProgress] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [address, setAddress] = useState('');
  const [pagination, setPagination] = useState({});
  const [status, setStatus] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [perPage, setPerPage] = useState(20);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [validateElements, setValidateElements] = useState([]);
  const [tagOrderStatus,setTagOrderStatus]=useState(false)
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const breadcrumbLinks = useSelector(state => state.breadcrumb_links.links);

  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const getAllOrderedTags = (value, tagStatus, pageCount, sortkey, sorter,tagOrderStatus) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    getOrderedTags(value, page, pageCount, tagStatus, sortkey, sorter,tagOrderStatus).then(
      result => {
        if (result.success) {
          setUserData(result.records);
          setPagination(result.pagination);
          setInProgress(false);
          // setUserData(result.records);
        } else {
          setUserData([]);
          setPagination(null);
          setInProgress(false);
        }
      }
    );
  };

  useEffect(() => {
    var links = [];
    if (breadcrumbLinks.length != 0) {
      links = ['Overview', 'Account', 'Users', 'Ordered Tags'];
    } else {
      links = ['Overview', 'Ordered Tags'];
    }
    dispatch(handleBreadcrumbLinks(links));
   
    if(params !== undefined){
      getAllOrderedTags(params.id, status, AppConfig.perPage,'','',tagOrderStatus);
    } else {
      getAllOrderedTags('', status, AppConfig.perPage,'','',tagOrderStatus);
    }
    const redirectScreen = history.location.state.prevScreen;
    localStorage.setItem('prevScreen', redirectScreen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      if (breadcrumbLinks.includes('Users')) {
        var prevlinks = ['Overview', 'Account', 'Users'];
        dispatch(handleBreadcrumbLinks(prevlinks, null));
      } else {
        dispatch(handleBreadcrumbLinks([]));
      }
    }
  }, []);


  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize);
    let sorterOrder = '';
    let sorterkey = '';
    sorter.field =
      sorter.field === 'tagIdentificationNumber' ? 'tagId' : sorter.field;
    sorterOrder = sorters(sorter.order);
    sorterkey = sorters(sorter.order) === '' ? '' : sorter.field;
    setSortKey(sorters(sorter.order) === '' ? '' : sorter.field);
    setSortOrder(sorters(sorter.order));
    getAllOrderedTags(
      searchValue,
      status,
      pagination.pageSize,
      sorterkey,
      sorterOrder,
      tagOrderStatus
    );
  };

const checkTagOrderStatus =(tagOrderStatus)=>{
  getAllOrderedTags('', status, AppConfig.perPage,'','',tagOrderStatus);
setTagOrderStatus(tagOrderStatus);
}

  const handleTitle = langValue => {
    setTitle(langValue);
  };

  const handlebySearch = value => {
    setSearchValue(value);
    getAllOrderedTags(
      value,
      status,
      AppConfig.perPage,
      '',
      '',
      tagOrderStatus
    );
  };

  const handleSubmit = e => {
    setEnterprisesData([]);
    e.preventDefault();
    setDisableBtn(false);
    setShowViewModal(true);
    setAddress('');
    form.setFieldsValue({
      account: '',
    });
  };

  const handleClick = e => {
    if (e.target.value === '') {
      setEnterprisesData([]);
      handleSearch(' ');
    }
  };

  const handleSearch = value => {
      setParentAccount(value);
      // eslint-disable-next-line no-shadow
      fetchEnterprices(1, 1000,value).then(result => {
        if (result.success) {
                    setEnterprisesData(result.data.records);
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
  };

  const onFocus = (event, newAddress) => {
    const validateFields = [
      'address_line1',
      'city',
      'state',
      'country',
      'zipcode',
    ];
    if (newAddress === '' || newAddress === undefined) {
      if (address !== '') {
        address.address_line1 =
          address.address_line1 !== undefined ? address.address_line1 : '';
        address.address_line2 =
          address.address_line2 !== undefined ? address.address_line2 : '';
      }
      const addrressError = validateAddressForm(validateFields, address);

      if (addrressError) {
        const errors = [];
        errors.push(`${addrressError}`);
        setErrors(errors);
        showError(true);
      } else {
        showError(false);
      }
      handleOnAddressChange('', validateFields, '', address);
    } else {
      if (newAddress !== '') {
        newAddress.address_line1 =
          newAddress.address_line1 !== undefined
            ? newAddress.address_line1
            : '';
        newAddress.address_line2 =
          newAddress.address_line2 !== undefined
            ? newAddress.address_line2
            : '';
      }
      const addrressError = validateAddressForm(validateFields, newAddress);
      if (addrressError) {
        const errors = [];
        errors.push(`${addrressError}`);
        setErrors(errors);
        showError(true);
      } else {
        showError(false);
      }
    }
  };

  const handleOrder = e => {
    setDisableBtn(true);
    e.preventDefault();
    const formData = {};
    formData.enterpriseName = ParentAccount;
    // eslint-disable-next-line no-shadow
    orderTag(formData).then(result => {
      if (result.success) {
        setShowViewModal(false);
        setParentAccount('');
        setAddress('');
        alertMessage('A TAG HAS BEEN ORDERED!', 'success', 3);
      } else {
        setShowViewModal(false);
        setParentAccount('');
        setAddress('');
        if (Array.isArray(result.errors.message.message)) {
          alertMessage(
            result.errors.message.message.toString().toUpperCase(),
            'error',
            3
          );
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      }
    });
  };

  const onSelect = (value, _result) => {
    setParentAccount(value);
    fetchEnterprices(1, 5, value).then(result => {
      if (result.success) {
        if (result.data.records !== null) {
          const entData = result.data.records[0];
          const address = {
            address_line1: entData.shippingAddress1,
            address_line2: entData.shippingAddress2,
            state: entData.shippingState,
            city: entData.shippingCity,
            zipcode: entData.shippingZipcode,
            country: entData.shippingCountry,
          };
          setAddress(address);
          onFocus('', address);
        } else {
          setEnterprisesData([]);
        }
      } else {
        setEnterprisesData([]);
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
      }
    });
  };

  const checkStatus = tagstatus => {
    setStatus(tagstatus);
    getAllOrderedTags(searchValue, tagstatus, perPage, sortKey, sortOrder);
  };

  const handleOnAddressChange = (
    addressType,
    element,
    value,
    obj,
    addressLine
  ) => {
    setAddress(obj);
    const arr = [...new Set([...element, ...validateElements])];
    setValidateElements(arr);
    const addrressError = validateAddressForm(arr, obj);
    if (addrressError) {
      const errors = [];
      errors.push(`${addrressError}`);
      setErrors(errors);
      showError(true);
    } else {
      showError(false);
    }
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
    setErrors([]);
    showError(false);
  };

  const renderViewModel = () => {
    return (
      <BaseModal width='65%' onCancel={() => handleOnModalClose()}>
        <Form layout='vertical' form={form}>
          <Row className='createTagsOrdered tagOrdersInline' gutter={20}>
            <Col span={24}>
              {/* <span className="TagModalTitle">PARENT ACCOUNT <span className='star-required'>*</span></span>
            <br /> */}
              <Form.Item
                name='account'
                validateTrigger='onBlur'
                rules={[
                  {
                    required: true,
                    message: 'Please select an account!',
                    validationTrigger: 'onBlur',
                  },
                ]}
                label='PARENT ACCOUNT'
                className='authlabels'>
                <AutoComplete
                  onSelect={value => onSelect(value, result)}
                  onSearch={handleSearch}
                  onClick={handleClick}
                  placeholder='Search Account'>
                  {enterprisesData.map(data => (
                    <Option key={data.id} value={data.customer}>
                      {data.customer}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
            <Col
              span={24}
              className={`addressFieldTop ${error ? 'errorAddress' : ''}`}>
              <span className='TagModalTitle'>
                SHIPPING ADDRESS <span className='star-required'>*</span>
              </span>
              <AddressForm
                autoComplete
                twoRows
                showReqFields
                onFocus={onFocus}
                address={address}
                onChange={(element, value, obj, addressLine) =>
                  handleOnAddressChange(
                    'address',
                    [element],
                    value,
                    obj,
                    addressLine
                  )
                }
                showError={error}
                errors={errors}
                addressType='shipping'
              />
            </Col>
          </Row>
          <Row />
          <div className='createWrapper'>
            <ReuseButton
              text='ORDER'
              className={
                ParentAccount === '' || address === ''
                  ? 'CreateTag'
                  : 'AddCreateTag'
              }
              disabled={
                ParentAccount === '' || address === '' || disableBtn || error
              }
              onClick={e => {
                handleOrder(e);
              }}
            />
          </div>
        </Form>
      </BaseModal>
    );
  };

  return (
    <div>
      <Spin spinning={inProgress}>
        <p className='ContainerTitleHeader'>
          <Row>
            <Col lg={10}>
              {' '}
              <span className='PageTitle'>
                {title} ({pagination !== null && pagination.totalCount})
              </span>
            </Col>
            <Col lg={14} className='alignRight'>
              {userDetails && userDetails.userType === 'UnyTag' && (
                <ReuseButton
                  text='MANUALLY ORDER TAG'
                  className='AddOrderTag'
                  onClick={e => {
                    handleSubmit(e);
                  }}
                />
              )}{' '}
              <ReuseInputField
                placeholder='Search by address, number..'
                className='SearchBarOrdered'
                width='800px'
                onChange={handlebySearch}
              />
            </Col>
          </Row>
        </p>
        <List
          onSelectTitle={handleTitle}
          data={userData}
          sendStatus={checkStatus}
          checkTagOrderStatus={checkTagOrderStatus}
          pagination={
            pagination !== null
              ? {
                  total: parseInt(pagination.totalCount, 10),
                  current: parseInt(pagination.currentPage, 10),
                  pageSize: parseInt(pagination.perPage, 10),
                  pageSizeOptions: [5, 10, 20],
                }
              : {}
          }
          // eslint-disable-next-line no-shadow
          tableChange={(pagination, filters, sorter,tagOrderStatus) =>
            handleTableChange(pagination, filters, sorter,tagOrderStatus)
          }
        />
        {showViewModal && renderViewModel()}
      </Spin>
    </div>
  );
};

export default OrderedTags;

import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { addEnterprises, getEnterpriseById,editEnterprise } from '../../api/enterprise/enterprise';
import AddressForm from '../../common/Addressform';
import { Checkbox, Col, Form, Row, Spin } from '../../common/AntdUiComponents';
import { alertMessage, validateAddressForm, isEmpty } from '../../common/Common';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import { useParams , useHistory} from 'react-router-dom';
import { handleBreadcrumbLinks } from '../../reducer/actions/actions';
import { useSelector, useDispatch} from 'react-redux';

export const EnterpriseProfileForm = ({ loadEnterPrise, handleOnModalClose, isEdit, enterpriseId }) => {
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [form] = Form.useForm();
  const [companyErrors, setCompanyErrors] = useState([]);
  const [listVisible, setListVisible] = useState('');
  const [companyError, showCompanyError] = useState(false);
  const [shippingErrors, setShippingErrors] = useState([]);
  const [shippingError, showShippingError] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [addressComObj, setaddressComObj] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [addressShipObj, setaddressShipObj] = useState({});
  const [editSaveBtn, seteditSaveBtn] = useState(true)
  const history = useHistory();
  const [state, setState] = useState({
    customer: '',
    company: '',
    shipping: '',
    billing: '',
    phone: '',
    tagCount: '',
  });
  const params = useParams();
  const dispatch = useDispatch();
  const breadcrumbLinks = useSelector(state => state.breadcrumb_links.links);

  const [validateElements, setValidateElements] = useState([]);
  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };


  const handleOnAddressChange = (addressType, element, value, obj) => {
    const arr = [...new Set([...element, ...validateElements])];
    setValidateElements(arr);
    const addrressError = validateAddressForm(arr, obj);
    const errors = [];
    if (addressType === 'company') {
      setaddressComObj(obj)
      if (addrressError) {
        errors.push(`${addrressError}`);
        setCompanyErrors(errors);
        showCompanyError(true);
      } else {
        showCompanyError(false);
      }
    } else {
      setaddressShipObj(obj)
      if (addrressError) {
        errors.push(`${addrressError}`);
        setShippingErrors(errors);
        showShippingError(true);
      } else {
        showShippingError(false);
      }
    }

    setState({
      ...state,
      [addressType]: obj,
    });
    // setaddressObj(obj)
  };

  const onFocus = (type) => {
    const validateFields = ['address_line1', 'city', 'state', 'country', 'zipcode'];
    if (type === 'company') {
      handleOnAddressChange(type, validateFields, "", addressComObj)
    } else {
      handleOnAddressChange(type, validateFields, "", addressShipObj)
    }
  }

  const submitForm = e => {
    setInProgress(true)
    e.preventDefault();
    if (state.customer.trim().length !== 0) {
      if (state.phone.length >= 11) {
        const formData = {};
        formData.billingCity =
          state.company.city;
        formData.customer = state.customer.trimLeft();
        formData.customerTypeId = 2;
        formData.billingAddress1 =
          state.company.address_line1;
        formData.billingAddress2 =
          state.company.address_line2;
        formData.billingCity =
          state.company.city;
        formData.billingCountry =
          state.company.country.trimLeft();
        formData.billingState =
          state.company.state;
        formData.billingZipCode =
          state.company.zipcode;
        if (isSameAddress) {
          formData.customer = state.customer.trimLeft();
          formData.shippingAddress1 =
            state.company.address_line1;
          formData.shippingAddress2 =
            state.company.address_line2;
          formData.shippingCity =
            state.company.city;
          formData.shippingCountry =
            state.company.country.trimLeft();
          formData.shippingState =
            state.company.state;
          formData.shippingZipCode =
            state.company.zipcode;
        }
        else {
          formData.shippingAddress1 =
            state.shipping.address_line1;
          formData.shippingAddress2 =
            state.shipping.address_line2;
          formData.shippingCity =
            state.shipping.city;
          formData.shippingCountry =
            state.shipping.country.trimLeft();
          formData.shippingState =
            state.shipping.state;
          formData.shippingZipCode =
            state.shipping.zipcode;
        }
        if(!isEmpty(state.tagCount)){
          formData.tagCount = state.tagCount;
          }
        if(!isEdit){
        addEnterprises(formData).then(result => {
          if (result.success) {
            setInProgress(false)
            setDisableBtn(true);
            alertMessage(
              `${state.customer.toUpperCase()} HAS BEEN ${isEdit ? 'UPDATED' : 'ADDED'}!`,
              'success',
              3
            );
          isEdit &&  handleOnModalClose();
            loadEnterPrise();
            form.setFieldsValue({
              customer: '',
              phone: '',
            });
          } else {
            setInProgress(false)
            setDisableBtn(false);
            if (Array.isArray(result.errors.message.message)) {
              alertMessage(
                result.errors.message.message.toString().toUpperCase(),
                'error',
                3
              );
            } else {
              setDisableBtn(false);
              alertMessage(
                result.errors.message.message.toUpperCase(),
                'error',
                3
              );
            }
          }
        });}
        else {
          editEnterprise(params.id,formData).then(result => {
            if (result.success) {
              setInProgress(false)
              setDisableBtn(true);
              alertMessage(
                `${state.customer.toUpperCase()} HAS BEEN ${isEdit ? 'UPDATED' : 'ADDED'}!`,
                'success',
                3
              );
              handleOnModalClose();
              history.push(`/unytag/web/enterprise`);
              loadEnterPrise();
              form.setFieldsValue({
                customer: '',
                phone: '',
              });
            } else {
              setInProgress(false)
              setDisableBtn(false);
              if (Array.isArray(result.errors.message.message)) {
                alertMessage(
                  result.errors.message.message.toString().toUpperCase(),
                  'error',
                  3
                );
              } else {
                setDisableBtn(false);
                alertMessage(
                  result.errors.message.message.toUpperCase(),
                  'error',
                  3
                );
              }
            }
          });
        }
      } else {
        setInProgress(false)
        alertMessage('PLEASE ENTER A CORRECT PHONE NUMBER', 'error', 3);
      }
    } else {
      setInProgress(false)
      alertMessage('PLEASE PROVIDE A VALID COMPANY NAME', 'error', 3);
    }
  };

  const handleEditProfile = ()=>{
    seteditSaveBtn(false)
  }
   
  const handleCheckboxChange = (defaultvalue = false) => {
    setIsSameAddress(defaultvalue);
    if (defaultvalue === true) {
      setState({
        customer: state.customer,
        company: state.company,
        shipping: state.company,
        billing: state.billing,
        phone: state.phone,
        tagCount: state.tags,
      });
      showShippingError(false)
    }
    setState({
      customer: state.customer,
      company: state.company,
      shipping: '',
      billing: state.billing,
      phone: state.phone,
      tagCount: state.tags,
    });
  }
  useEffect(() => {
    var links = ['Overview', 'Account', 'Profile', 'Edit Enterprise Profile'];
    dispatch(handleBreadcrumbLinks(links));
    if (isEdit) {
      getEnterpriseById(enterpriseId).then((res) => {
        const billingAddress = {
          address_line1: res.data.billingAddress1,
          address_line2: res.data.billingAddress2,
          city: res.data.billingCity,
          country: res.data.billingCountry,
          state: res.data.billingState,
          zipcode: res.data.billingZipcode
        };
        const shippingAddress = {
          address_line1: res.data.shippingAddress1,
          address_line2: res.data.shippingAddress2,
          city: res.data.shippingCity,
          country: res.data.shippingCountry,
          state: res.data.shippingState,
          zipcode: res.data.shippingZipcode
        }
        setState({
          ...res.data,
          company: billingAddress,
          shipping: shippingAddress,
          phone: "11111111111"
        });
        const name = res.data.customer;
        const phone = "1111111111";
        form.setFieldsValue({
          name, phone
        });
        setaddressComObj({ billingAddress })
      })
    }
    localStorage.setItem('prevScreen', 'Profile');
    return history.listen(location => {
      var links = ['Overview', 'Account', 'Profile'];
      dispatch(handleBreadcrumbLinks(links));
      if (history.action === 'POP') {
        setListVisible('profileList')
      }
    })
  }, [])
  return (
    <>         
    {isEdit && <ReuseButton
    text={editSaveBtn?'Edit Profile':'Save'}
    className={
      `${state === {} ||
        state.customer === '' ||
        (!isSameAddress && state.shipping === '') ||
        companyError ||
        shippingError ||
        state.company === '' ||
        state.phone === ''
        ? 
        'CreateTag enterpriseEditProfilebtn'
        : 'AddCreateTag enterpriseEditProfilebtn'}
        ${editSaveBtn ? "AddButton": "btn-success SaveButton"}`
    }
    onClick={editSaveBtn ? handleEditProfile: submitForm}
    disabled={
      state === {} ||
      state.customer.trim() === '' ||
      (!isSameAddress && state.shipping === '') ||
      companyError ||
      shippingError ||
      state.company === '' ||
      state.phone === '' ||
      !!state.phone && state.phone.length < 11 ||
      state.company.zipcode.length< 5 ||
      disableBtn
    }
  />}
        <Spin spinning={inProgress}>
      <Form layout='vertical' form={form}>
        <div className='addtolls addEnts'>
          <Row className='WrapperPads pb-0' gutter={20}>
            <Col span={12}>
              <Form.Item
                name='name'
                validateTrigger='onBlur'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a name!',
                    validationTrigger: 'onBlur',
                  },
                  () => ({
                    validator(rule, value) {
                      if (value === undefined) {
                        return Promise.resolve();
                      }
                      if ((value.length === 0) && value.trim() === '') {
                        return Promise.resolve();
                      }

                      if ((value.length > 0) && value.trim() !== '') {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Please enter a valid name.')
                      );
                    },
                  }),
                ]}
                label='COMPANY NAME'
                className='authlabels'>
                <ReuseInputField
                  className='TagInput'
                  width='100%'
                  placeholder='Company Name'
                  value={state.customer}
                  disabled={isEdit && editSaveBtn}
                  onChange={handleChange('customer')}
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ margin: '0 0 5px 8px' }}>
                <Checkbox
                  onChange={e => handleCheckboxChange(e.target.checked)}
                  checked={isSameAddress}
                  style={{ marginTop: '5px' }}
                  disabled={isEdit && editSaveBtn}
                />
                <span>&nbsp;Shipping address is same as billing address</span>
              </div>
            </Col>
          </Row>
          <Row className='pb-0 mt-10' gutter={20}>
            <Col
              span={12}
              className={`${companyError ? 'errorAddress' : ''}`}>
              <span className='TagModalTitle'>
                BILLING ADDRESS <span className='star-required'>*</span>
              </span>
              <br />
              <AddressForm
                autoComplete
                twoRows
                showReqFields
                address={state.company}
                onFocus={() => onFocus('company')}
                onChange={(element, value, obj) =>
                  handleOnAddressChange('company', [element], value, obj)
                }
                showError={companyError}
                errors={companyErrors}
                addressType='biiling'
                disabled={isEdit && editSaveBtn}
              />
            </Col>
            {
              !isSameAddress &&
              <Col
                span={12}
                className={`${shippingError ? 'errorAddress' : ''}`}>
                <span className='TagModalTitle'>
                  SHIPPING ADDRESS <span className='star-required'>*</span>
                </span>
                <br />

                <AddressForm
                  autoComplete
                  twoRows
                  showReqFields
                  onFocus={() => onFocus('shipping')}
                  address={state.shipping}
                  onChange={(element, value, obj) =>
                    handleOnAddressChange('shipping', [element], value, obj)
                  }
                  showError={shippingError}
                  errors={shippingErrors}
                  addressType='shipping'
                  disabled={isEdit && editSaveBtn}
                />
              </Col>
            }
          </Row>
          <Row className='pb-0' gutter={20}>
            <Col span={24}>
              <Form.Item
                name='phone'
                validateTrigger='onBlur'
                rules={[
                  {
                    required: true,
                    message: 'Please enter a contact number!',
                    validationTrigger: 'onBlur',
                  },
                  () => ({
                    validator(rule, value) {
                      if (value === undefined || value.length === 11 || value.length === 12) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error('Please enter a valid phone number.')
                      );
                    },
                  }),
                ]}
                label='PHONE NUMBER (Primary contact)'
                className='authlabels'>
                <PhoneInput
                  country='us'
                  onlyCountries={['us', 'in']}
                  specialLabel=''
                  countryCodeEditable
                  value={state.phone}
                  disabled={isEdit}
                  onChange={handleChange('phone')}
                />
              </Form.Item>
            </Col>
          </Row>
          {!isEdit && <Row className='pb-0 mt-10' gutter={20}>
            <Col span={24}>
              <span className='TagModalTitle'>NUMBER OF TAGS TO ORDER</span>
              <br />
              <ReuseInputField
                className='TagInput'
                width='100%'
                type='number'
                min={0}
                placeholder='No. of Tags'
                value={state.tagCount}
                onChange={handleChange('tagCount')}
              />
            </Col>
            <Col offset={4} span={10}></Col>
          </Row>}
        </div>
        {!isEdit && <div className='createWrapper'>
          <ReuseButton
            text={'CREATE'}
            className={
              state === {} ||
                state.customer === '' ||
                (!isSameAddress && state.shipping === '') ||
                companyError ||
                shippingError ||
                state.company === '' ||
                state.phone === ''
                ? 'CreateTag'
                : 'AddCreateTag'
            }
            onClick={submitForm}
            disabled={
              state === {} ||
              state.customer.trim() === '' ||
              (!isSameAddress && state.shipping === '') ||
              companyError ||
              shippingError ||
              state.company === '' ||
              state.phone === '' ||
              !!state.phone && state.phone.length < 11 ||
              state.company.zipcode.length< 5 ||
              disableBtn
            }
          />
        </div>}
      </Form>
    </Spin>
    </>
  )
}

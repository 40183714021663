/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import { Row, Col, Button, Form, Select, Spin } from '../../common/AntdUiComponents';
import {
  addEnterpriseUser,
  fetchEnterpriseUserById, fetchEnterpriseUsers
} from '../../api/enterprise/enterpriseUser';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { checkTextValidation, alertMessage } from '../../common/Common';
import createBrowserHistory from 'history/createBrowserHistory';
import { useSelector, useDispatch} from 'react-redux';
import { handleBreadcrumbLinks } from '../../reducer/actions/actions';

const roles = ['10', '9'];
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const user = localStorage.getItem('user');
const userDetails = user !== '' ? JSON.parse(user) : {};

const EnterPriseUserForm = ({ newData, hideModal, loadUsers, userData, enterpriseId, id }) => {
  const params = useParams();
  //const history = useHistory();
  const history = createBrowserHistory();
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [listVisible, setListVisible] = useState('');
  const [disableBtn, setDisableBtn] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [adminsCount, setAdminsCount] = useState('')
  const [deleteRestriction, setDeleteRestriction] = useState(false)
  const [emailEdit, setEmailedit] = useState(false)
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    license: '',
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const getEnterpriseUsers = (value, pageSizeCount, sortkey, sorter) => {
    setInProgress(true);
    fetchEnterpriseUsers(
      userDetails.userType === 'Enterprise' ? userDetails.company : params.entName,
      1,
      pageSizeCount, value, sortkey, sorter
    ).then(result => {
      if (result.success) {
        if (result.data.records !== null) {
          setAdminsCount(result.adminsCount)
          setInProgress(false);
        } else {
          setInProgress(false);
        }
      } else {
        setInProgress(false);
      }
    });
  };

  const getEnterPriseUser = () => {
    fetchEnterpriseUserById(params.name, id).then(result => {
      if (result.success) {
        const {contactName} = result.data;
        const  name  = contactName;
        const { email } = result.data;
        const { phone } = result.data;
        let { role } = result.data;
        const license = result.data.identificationNumber;
        if(role==='Admin')
        {
          role ="9"
        }
        else{
          role = "10"
        }
        const values = {
          name, 
          email,
          phone,
          role,
          license,
        };
        setState(values);
        form.setFieldsValue({
          email,
          name,
          phone,
          role,
        });
      }
    });
  };

  useEffect(() => {
    var links = ['Overview', 'Account', 'Profile', 'Edit User Profile', ];
    dispatch(handleBreadcrumbLinks(links));
    const isEdit = () => {
      if (params.entName !== undefined) {
        setEmailedit(true)
      }
      if (params.name !== undefined && !newData) {
        setIsEditProfile(true);
        getEnterPriseUser();
        getEnterpriseUsers('', 1000)
      } else {
        setIsEditProfile(false);
      }
    };
    isEdit();
    localStorage.setItem('prevScreen', 'Profile');
    return history.listen(location => {
      if (history.action === 'POP') {
        setListVisible('profileList')
        // window.location.href = `/unytag/web/enterprise/profileList/${params.entName}/${params.id}`;
        // window.location.reload(false);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfile = type => {
    setIsEditProfile(!isEditProfile);
    if (type === 'save') {
      alertMessage(`${state.name.toUpperCase()} HAS BEEN SAVED!`, 'success', 3);
    }
  };

  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleRoleChange = (e, value) => {
    if (adminsCount === 1 && value === 'Admin') {
      setDeleteRestriction(true)
    }
    setState({
      ...state,
      [e]: value,
    });
  };

  const submitForm = e => {
    e.preventDefault();
    setInProgress(false)
    if (state.name.trim().length !== 0) {
      if (re.test(String(state.email).toLowerCase())) {
        if (state.phone.length >= 11) {
          const formData = {};
          formData.contactName = state.name;
          formData.email = state.email;
          if (newData) {
            formData.phone = state.phone.includes('+') ? state.phone : '+' + state.phone;
          } else {
            formData.phone = state.phone;
          }
          formData.roleId = Number(state.role);
          formData.customer =  params.name;
          if (!newData) {
            formData.id = params.id;
          }
          formData.password = 'string';
          formData.identificationNumber = state.license;

          formData.mobilePin = '';
          formData.accountTypeId = 3;
          const isNew = !!newData;
          let name = '';
          if (Object.entries(params).length > 0) {
            name = newData ? params.name : params.entName;
          } else {
            name = params.name;
          }
          setDisableBtn(true);
          addEnterpriseUser(isNew, formData, params.id).then(result => {
            if (result.success) {
              setInProgress(false)
              if (newData) {
                alertMessage(
                  `${state.name.toUpperCase()} HAS BEEN ADDED!`,
                  'success',
                  3
                );
                hideModal();
                loadUsers();
                setDisableBtn(false);
              } else {
                if (params.id == userDetails.id) {
                  userDetails.firstName = formData.firstName;
                  userDetails.lastName = formData.lastName;
                  localStorage.setItem('user', JSON.stringify(userDetails));
                  history.push(`/unytag/web/enterprise/add/${params.type}/${params.name}/${params.entName}/${params.id}`)

                }
                setDisableBtn(false);
                alertMessage(`ALL CHANGES HAVE BEEN SAVED!`, 'success', 3);
              }

              setIsEditProfile(true);
            } else {
              setInProgress(false)
              setDisableBtn(false);
              alertMessage(
                result.errors.message.message.toUpperCase(),
                'error',
                3
              );
            }
          });
        } else {
          setInProgress(false)
          alertMessage('PLEASE ENTER A CORRECT PHONE NUMBER', 'error', 3);
        }
      } else {
        setInProgress(false)
        alertMessage('PLEASE PROVIDE A VALID EMAIL ADDRESS', 'error', 3);
      }
    } else {
      setInProgress(false)
      alertMessage('PLEASE PROVIDE A VALID NAME', 'error', 3);
    }
  };

  return (
    <div>
      <Spin spinning={inProgress}>
        {!newData && (
          <p className='PageTitle'>
            <Row type='flex' justify='space-between'>
              {params.name !== 'admin' && (
                <span className='profileTitle'>{params.name}</span>
              )}
              {isEditProfile ? (
                <Button
                  className='AddButton'
                  onClick={() => {
                    handleProfile('edit');
                  }}>
                  {params.name !== undefined ? 'EDIT PROFILE' : 'ADD PROFILE'}
                </Button>
              ) : (
                <Button
                  className='btn-style btn-success SaveButton'
                  onClick={submitForm}
                  disabled={
                    state.name.trim() === '' ||
                    state.email === '' ||
                    state.phone === '' ||
                    state.role === '' ||
                    !re.test(String(state.email).toLowerCase()) ||
                    state.phone.length < 11
                  }>
                  SAVE
                </Button>
              )}
            </Row>
          </p>
        )}

        <div className={`${!newData && 'EntBasecontainer'}`}>
          <Col span={24} style={{ padding: '2%' }}>
            <div className='profile_height'>
              <Form form={form} layout='vertical'>
                <div className='addtolls fullWidth'>
                  <Row style={{ paddingTop: '5%', paddingBottom: '5%' }}>
                    <Col lg={10}>
                      <Form.Item
                        name='name'
                        validateTrigger='onBlur'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a name!',
                            validationTrigger: 'onBlur',
                          },
                          () => ({
                            validator(rule, value) {
                              if (value === undefined) {
                                return Promise.resolve();
                              }
                              if ((value.length === 0) && value.trim() === '') {
                                return Promise.resolve();
                              }

                              if ((value.length > 0) && value.trim() !== '') {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('Please enter a valid name.')
                              );
                            },
                          }),
                        ]}
                        label='FULL NAME'
                        className='authlabels'>
                        <ReuseInputField
                          className='TagInput'
                          width='100%'
                          placeholder='Full Name'
                          disabled={isEditProfile}
                          value={state.name}
                          onChange={handleChange('name')}
                        />
                      </Form.Item>
                    </Col>
                    <Col offset={4} lg={10}>
                      <Form.Item
                        name='email'
                        validateTrigger='onBlur'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter email!',
                            validationTrigger: 'onBlur',
                          },
                          {
                            type: 'email',
                            message: 'Please provide a valid email address!',
                          },
                        ]}
                        label='WORK EMAIL ADDRESS (username)'
                        className='authlabels'>

                        <ReuseInputField
                          className='TagInput'
                          width='100%'
                          disabled={isEditProfile || emailEdit}
                          type='email'
                          placeholder='xyz@ymail.com'
                          value={state.email}
                          onChange={handleChange('email')}
                        />
                        {/* <Input/> */}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        name='phone'
                        validateTrigger='onBlur'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter contact number!',
                            validationTrigger: 'onBlur',
                          },
                          () => ({
                            validator(rule, value) {
                              console.log(value)
                              if (value === undefined || value.length === 11 || value.length === 12) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('Please enter a valid phone number.')
                              );
                            },
                          }),
                        ]}
                        label='PHONE NUMBER'
                        className='authlabels'>
                        <PhoneInput
                          country='us'
                          onlyCountries={['us', 'in']}
                          specialLabel=''
                          value={state.phone}
                          onChange={handleChange('phone')}
                          disabled={isEditProfile}
                        />
                      </Form.Item>
                    </Col>
                    <Col offset={4} span={10} className='entUserAdd'>
                      <Form.Item
                        name='role'
                        validateTrigger='onBlur'
                        rules={[
                          {
                            required: true,
                            message: 'Please select a role!',
                            validationTrigger: 'onBlur',
                          },
                        ]}
                        label='ROLE'
                        className='authlabels'>
                        <Select
                          placeholder='Select one'
                          value={state.role}
                          disabled={isEditProfile || (adminsCount === 1 && state.role === 'Admin' && !deleteRestriction)}
                          style={{ width: '100%' }}
                          onChange={e => handleRoleChange('role', e)}>
                          {roles.map(role => (
                            <Select.Option key={role} value={role}>
                              {role==10?"User":"Admin"}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {(adminsCount === 1 && state.role === 'Admin' && !deleteRestriction) && <p className='captionss paddingformTop'>
                        <b>Note</b> - User role can't be changed if there is only one admin
                    </p>}
                      <p className='captionss paddingformTop'>
                        <b>Admin</b> - has access to this dashboard and team
                      management
                    </p>
                      <p className='captionss'>
                        <b>User</b> - cannot access dashboard; a user is just a
                      driver{' '}
                      </p>
                    </Col>
                  </Row>
                </div>
                {newData && (
                  <div className='createWrapper'>
                    <Form.Item>
                      <ReuseButton
                        text='CREATE'
                        className={
                          state === {} ||
                            state.name === '' ||
                            state.email === '' ||
                            state.phone === '' ||
                            state.role === ''
                            ? 'CreateTag'
                            : 'AddCreateTag'
                        }
                        htmlType='submit'
                        onClick={submitForm}
                        disabled={
                          state === {} ||
                          state.name.trim() === '' ||
                          state.email === '' ||
                          state.phone === '' ||
                          state.role === '' ||
                          disableBtn ||
                          !re.test(String(state.email).toLowerCase()) ||
                          state.phone.length < 11
                        }
                      />
                    </Form.Item>
                  </div>
                )}
              </Form>
            </div>
          </Col>
          <Col lg={2} />
        </div>
      </Spin>
    </div>
  );
};

export default EnterPriseUserForm;

import React, { useState, useEffect } from 'react';
import { useParams,useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import {
  Row,
  Col,
  Button,
  Form,
  message,
  Select,
  Spin,
} from '../../common/AntdUiComponents';
import AddressForm from '../../common/Addressform';
import { getUserDetails, updateUserDetails } from '../../api/user';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import { alertMessage, isEmpty } from '../../common/Common';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const user = localStorage.getItem('user');
let userDetails = user!==''? JSON.parse(user) : { };

const roles = ['User', 'Admin'];
const vehicles = ['Dry Van', 'Car', 'Reefer'];
const Profile = () => {
  const params = useParams();
  const history = useHistory();

  const [isEditProfile, setIsEditProfile] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    vehicleSelected: '',
    address: '',
  });
  const [validateElements, setValidateElements] = useState([]);
  const [form] = Form.useForm();

  const fetchUserDetails = () => {
    setProgress(true);
    getUserDetails(params.id).then(result => {
      if (result.success) {
        setProgress(false);
        const { firstName } = result.data;
        const { lastName } = result.data;
        const name = result.data.contactName.trim();
        const { email } = result.data;
        const vehicleSelected = result.data.vehicleTypeId;
        const phone = result.data.phone;
        const address = '';
        const { role } = result.data;
        const values = {
          name,
          email,
          phone,
          role,
          vehicleSelected,
          address,
        };
        const shipping = {
          address_line1: result.data.shippingAddress1,
          address_line2: result.data.shippingAddress2,
          city: result.data.city,
          country: result.data.country,
          state: result.data.state,
          zipcode: result.data.zipcode
        }
 
        setState({
          name: name,
          email: email,
          phone: phone,
          vehicleSelected: vehicleSelected,
          address: shipping,
          role: role,
        });
        form.setFieldsValue({
          email,
          name: name,
          phone:phone
        });
      } else {
        setProgress(false);
      }
    });
  };

  useEffect(() => {
    const isEdit = () => {
      if (params.name !== undefined) {
        setIsEditProfile(true);
      } else {
        setIsEditProfile(false);
      }
    };
    isEdit();
    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoleChange = (e, value) => {
    console.log(e, value);
    setState({
      ...state,
      [e]: value,
    });
  };

  const handleProfile = type => {
    setIsEditProfile(!isEditProfile);
    if (type === 'save') {
      message.success({
        content: 'ALL CHANGES HAVE BEEN SAVED!',
        className: 'bg-green',
        duration: 5,
      });
    }
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    const arr = [...new Set([...element,...validateElements])];
    setValidateElements(arr);
    console.log(obj);
    setState({
      ...state,
      [addressType]: obj,
    });
  };

  const submitForm = e => {
    e.preventDefault();
    setProgress(false)
    const formData = {};
    // eslint-disable-next-line prefer-destructuring
    const nameObj = state.name.trim().split(' ');
    if (nameObj.length === 1) {
      let firstName = nameObj[0];
      let lastName = '';
      formData.contactName = `${firstName}${lastName}`;
    } else {
      let firstName = nameObj[0];
      let lastnameObjArray = nameObj.slice(1);
      let lastnameObj = lastnameObjArray.join(' ');
      let lastName = lastnameObj;
      formData.contactName = `${firstName}${lastName}`;
    }
    formData.email = state.email;
    formData.id = params.id;
    formData.phone =  state.phone.includes('+') ?state.phone:'+' + state.phone;
    formData.role = state.role;
    formData.mobilePin = '';
    formData.userTypeId = 3;
    formData.vechicleTypeId = state.vehicleSelected;
    formData.shippingAddress1 = !isEmpty(state.address.address_line1) ? state.address.address_line1.trim() : '';
    formData.shippingAddress2 = !isEmpty(state.address.address_line2) ? state.address.address_line2.trim() : '';
    formData.shippingCity = !isEmpty(state.address.city) ? state.address.city.trim() : '';
    formData.shippingCountry = !isEmpty(state.address.country) ? state.address.country.trim() : '';
    formData.shippingState = !isEmpty(state.address.state) ? state.address.state.trim() : '';
    formData.shippingZipCode = !isEmpty(state.address.zipcode) ? state.address.zipcode.trim() : '';
  
    if (re.test(String(state.email).toLowerCase())) {
      if (state.phone.length >= 11) {
        updateUserDetails(formData, params.id).then(result => {
          if (result.success) {
            setProgress(false)
            alertMessage(`ALL CHANGES HAVE BEEN SAVED!`, 'success', 3);
            setIsEditProfile(true);
            if(params.id == userDetails.id){
              userDetails.firstName= formData.firstName;
              userDetails.lastName= formData.lastName;
              localStorage.setItem('user', JSON.stringify(userDetails));
              history.push(`/unytag/web/users/info/${params.name}/${params.id}`)
            }
          } else {
            setProgress(false)
            alertMessage(result.errors.message.message[0], 'error', 3);
          }
        });
      } else {
        setProgress(false)
        alertMessage('PLEASE ENTER A CORRECT PHONE NUMBER', 'error', 3);
      }
    } else {
      setProgress(false)
      alertMessage('PLEASE PROVIDE A VALID EMAIL ADDRESS', 'error', 3);
    }
  };

  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };

  return (
    <div>
      <Spin spinning={inProgress}>
        <p className='PageTitle'>
          <Row type='flex' justify='space-between'>
            {params.name !== 'admin' && (
              <span className='profileTitle'>{params.name}</span>
              )}
            {isEditProfile ? (
              <Button
                className='AddButton'
                onClick={() => {
                  handleProfile('edit');
                }}>
                {params.name !== undefined ? 'EDIT PROFILE' : 'ADD PROFILE'}
              </Button>
            ) : (
              <Button
                className='btn-style btn-success SaveButton'
                onClick={submitForm}
                disabled={
                  state.name.trim() === '' || state.email === '' || state.phone === ''|| state.phone.length<11|| !re.test(String(state.email).toLowerCase())
                }>
                SAVE
              </Button>
            )}
          </Row>
        </p>
        <Row className='EntBasecontainer'>
          <Col span={24} style={{ padding: '2%' }}>
            <div className='profile_height'>
              <Form form={form} layout='vertical' className='addUsers'>
                <div className='addtolls fullWidth'>
                  <Row className='mar-bottom-20' gutter={20}>
                    <Col lg={12}>
                      <Form.Item
                        name='name'
                        validateTrigger='onBlur'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a name!',
                            validationTrigger: 'onBlur',
                          },
                          () => ({
                            validator(rule, value) {
                              if (value===undefined) {
                                return Promise.resolve();
                              }
                              if ((value.length===0)&& value.trim() === '') {
                                return Promise.resolve();
                              }

                              if((value.length>0)&& value.trim() !== ''){
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('Please enter a valid name.')
                              );
                            },
                          }),
                        ]}
                        label='FULL NAME'
                        className='authlabels mb-0'>
                        <ReuseInputField
                          className='TagInput'
                          width='100%'
                          placeholder='Full Name'
                          disabled={isEditProfile}
                          value={state.name}
                          onChange={handleChange('name')}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        name='email'
                        validateTrigger='onBlur'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter email!',
                            validationTrigger: 'onBlur',
                          },
                          {
                            type: 'email',
                            message: 'Please provide a valid email address!',
                            validationTrigger: 'onBlur',
                          },
                        ]}
                        label='EMAIL ADDRESS (username)'
                        className='authlabels mb-0'>
                        <ReuseInputField
                          className='TagInput'
                          width='100%'
                          disabled={true}
                          type='email'
                          placeholder='johndoe@gmail.com'
                          value={state.email}
                          onChange={handleChange('email')}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mar-bottom-20' gutter={20}>
                    <Col span={12}>
                      <span className='TagModalTitle'>VEHICLE SELECTED </span>
                      <br />
                      <Select
                        placeholder='Select one'
                        value={state.vehicleSelected}
                        disabled={isEditProfile}
                        style={{ width: '100%' }}
                        onChange={e => handleRoleChange('vehicleSelected', e)}>
                        {/* {vehicles.map(vehicle => (
                          <Select.Option key={vehicle} value={vehicle}>
                            {vehicle}
                          </Select.Option>
                        ))} */}
                          <Select.Option value={1}>Car, motorcycle, Carpool</Select.Option>
                          <Select.Option value={2}>Truck</Select.Option>
                          <Select.Option value={3}>Bus</Select.Option>
                          <Select.Option value={4}>RV</Select.Option>
                      </Select>
                    </Col>
                    <Col span={12} className='entUserAdd'>
                      <Form.Item
                        name='phone'
                        validateTrigger='onBlur'
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a phone number!',
                            validationTrigger: 'onBlur',
                          },
                          () => ({
                            validator(rule, value) {
                              console.log(value)
                              if(value === undefined || value.length===11 || value.length===12){
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error('Please enter a valid phone number.')
                              );
                            },
                          }),
                        ]}
                        label='PHONE NUMBER'
                        className='authlabels mb-0'>
                        <PhoneInput
                          country='us'
                          onlyCountries={['us', 'in']}
                          specialLabel=''
                          value={state.phone}
                          onChange={handleChange('phone')}
                          disabled={isEditProfile}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row className='mar-bottom-20' gutter={20}>
                    <Col span={12}>
                      <span className='TagModalTitle'>SHIPPING ADDRESS </span>
                      <br />
                      <AddressForm
                        autoComplete
                        twoRows
                        showReqFields
                        disabled={isEditProfile}
                        address={state.address}
                        onChange={(element, value, obj) =>
                          handleOnAddressChange('address', [element], value, obj)
                        }
                        style={{ marginBottom: 20 }}
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </Col>
          <Col lg={2} />
        </Row>
      </Spin>
    </div>
  );
};

export default Profile;

import React,{useState,useEffect} from 'react';
import moment from 'moment-timezone';
import {
  Row,
  Col,
  Table,
  Icon,
} from '../../../common/AntdUiComponents';
import ExportCSV from '../../reusableComponents/ExportCSV'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const List = ({ data, pagination, tableChange, expandable, type,downloadStmnt }) => {
  const [exportData,setExportData] = useState([]);
  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sortDirections: ['ascend','descend','ascend'],
      width: 250,
      sorter: (a, b) => {
        a = a.fullName || '';
        b = b.fullName || '';
        return a.localeCompare(b);
    },
      render: (text, objData) => (
        <span>
          {objData.fullName}
        </span>
      ),
    },
    {
      title: 'Tag ID',
      dataIndex: 'tagId',
      key: 'tagId',
      sortDirections: ['ascend','descend','ascend'],
      width: 150,
      sorter: (a, b) => {
        a = a.tagId || '';
        b = b.tagId || '';
        return a.localeCompare(b);
    },
    },
    {
      title: 'Account Type',
      dataIndex: 'accountType',
      key: 'accountType',
      sortDirections: ['ascend','descend','ascend'],
      width: 150,
      sorter: (a, b) => {
        a = a.accountType || '';
        b = b.accountType || '';
        return a.localeCompare(b);
    },
    },
    {
      title: 'Trip Date',
      dataIndex: 'tripDate',
      key: 'tripDate',
      sortDirections: ['ascend','descend','ascend'],
      width: 190,
      sorter: (a, b) => {
        a = a.tripDate || '';
        b = b.tripDate || '';
        return a.localeCompare(b);
    },
      render: (text, objData) => {
        return <p>
          {(objData.detailedTransaction[0] && objData.detailedTransaction[0].timezone) && (
            <span>{moment(objData.detailedTransaction[0].tollPassTime).tz(objData.detailedTransaction[0].timezone).format('MM/DD/YYYY hh:mm A')}</span>
          )}
          {/* {!objData.detailedTransaction[0].timezone && (
            <span>{moment(objData.detailedTransaction[0].tollPassTime).format('MM/DD/YYYY hh:mm A')}</span>
          )} */}
          </p>
      },
    },
    {
      title: 'Trip Amount',
      dataIndex: 'charge',
      sortDirections: ['ascend','descend','ascend'],
      key: 'charge',
      width: 150,
      sorter: (a, b) => {
        a = a.charge || '';
        b = b.charge || '';
        return a.localeCompare(b);
    },
      render: (text) => <span>${text}</span>,
    },
    {
      title: 'Download',
      dataIndex: 'download',
      key: 'download',
      width: 150,
      render: (text, data) => (
        <span onClick={() =>singleReport([data], moment(data.detailedTransaction[0].tollPassTime)
                      .tz(data.detailedTransaction[0].timezone).format('MM-DD-YYYY'))} className='pointer'>
          <Icon
            type="download"
            style={{ color: '#0057C1', fontSize: '24px' }}
          />
        </span>
      ),
    },
  ];

  useEffect(() => {
      const exportData = data.map(item =>
      (
        {
          fullName : item.firstName + item.lastName,
          tagId : item.tagId,
          accountType: item.accountType,
          tripDate : item.detailedTollTransaction? moment(item.detailedTollTransaction[0].tollPassTime)
                      .tz(item.detailedTollTransaction[0].timezone).format('MM/DD/YYYY hh:mm A') : null,
          tripAmount : item.charge,
        }
      )
      )
     setExportData(exportData)
  }, [data])

  const singleReport = (csvData, fileName) => {
    const exportData = csvData[0].detailedTransaction.map(item =>
      (
        {
          fullName : csvData[0].fullName,
          tagId : csvData[0].tagId,
          facility: item.facility,
          plaza: item.plaza,
          tripDate : item.timezone? moment(item.tollPassTime).tz(item.timezone).format('MM/DD/YYYY hh:mm A') : moment(item.tollPassTime).format('MM/DD/YYYY hh:mm A'),
          tripAmount : item.charge,
        }
      )
      )
      const formattedCsvData = exportData.map( function( item ){
        delete item.key
        for(var key in item){
            if(key!=='key'){
                var upper = key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });
                // check if it already wasn't uppercase
                if( upper !== key ){
                    item[ upper ] = item[key];
                    delete item[key];
                }
            }
        }
        return item;
    });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(formattedCsvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <div className="Basecontainer">
      <Row gutter={30} className="tollsList">
        <Col span={24} className="tablePadding mar-top-20">
          <p className="TitleText mar-bottom-5">
            View all trips and transactions that have happened with this
            enterprise customer here.
          </p>

          <p className="TitleText mar-bottom-20 addTollEntUserBtn floatRight">
          {data.length!==0 &&
          <ExportCSV className="floatRight btn-style btn-info enterpriseOptionbuttons templateText templateBtn"
          csvData={exportData} fileName='TripHistory' type={type} name={type === 'user' ? 'DOWNLOAD ALL STATEMENTS' : 'DOWNLOAD REPORT'}>
          </ExportCSV>
          }
          </p>
          <Table
            className="BaseTable"
            dataSource={data}
            columns={columns}
            showHeader
            pagination={pagination}
            onChange={tableChange}
            expandable={expandable}
          />
        </Col>
      </Row>
    </div>
  );
};

export default List;

import { Call, ApiUrl, ParseGeneralResponse, PageParams } from '../ApiUtils';
import AppConfig from '../../config/AppConfig';
import axios from 'axios/index';

export function fetchEnterprices(
  page = 1,
  perPage = AppConfig.perPage,
  name = '',
  id = ''
) {
  const url = ApiUrl(
    `/unytag/enterprise?${PageParams(
      page,
      perPage
    )}&search=${encodeURIComponent(name)}&sortBy=${id}`
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '', pagination: 'meta' },
  });
}

export function fetchEnterpriceUsers(name) {
  const url = ApiUrl(`/unytag/enterprise/user?enterprise=${name}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function fetchEntTripHistoryList(id,search, page = 1,
  perPage = AppConfig.perPage,orderName = '',order='') {
  const url = ApiUrl(`/unytag/trip/details/enterprise/${id}?&${PageParams(page, perPage)}&search=${search}&sortBy=${orderName}&sortOrder=${order}`);
  return Call('post', url, {}, ParseGeneralResponse, {
    responseDataKeys: {records: 'records', pagination: 'meta',adminsCount:'adminsCount' },
  });
}

export function fetchEnterpriceUserById(id) {
  const url = ApiUrl(`/unytag/enterprise/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function deleteEnterpriceUserById(id) {
  const url = ApiUrl(`/unytag/enterprise/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function deleteUserById(id) {
  const url = ApiUrl(`/unytag/enterprise/user/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function freezeAccount(id, userId) {
  const url = ApiUrl(`/unytag/enterprise/togglefreeze/${id}/${userId}`);
  return Call('put', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function freezeUserTag(company, id) {
  const url = ApiUrl(
    `/unytag/enterprise/user/toggle/${id}`
  );
  return Call('put', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function shipTag(company, id) {
  const url = ApiUrl(
    `/unytag/enterprise/user/tag?enterpriseName=${company}&userId=${id}`
  );
  return Call('post', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function addEnterprises(data) {
  const method = 'post';
  const url = ApiUrl(`/unytag/enterprise`);
  const dataParameters = data;
  return Call(method, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}
export function editEnterprise(id,data) {
  const method = 'put';
  const url = ApiUrl(`/unytag/enterprise/${id}`);
  const dataParameters = data;
  return Call(method, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}


export function addPayments(data) {
  const method = 'post';
  const url = ApiUrl(`/paymentmethod/add`);
  const dataParameters = data;
  return Call(method, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function updatePayments(id,data) {
  const url = ApiUrl(`/paymentmethod/update/${id}`);
  const dataParameters = data;
  return Call('put', url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function setPrimaryPayment(data) {
  const url = ApiUrl(`/paymentmethod/update/primary`);
  const dataParameters = data;
  return Call('put', url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}


export function fetchPayments(entId) {
  const url = ApiUrl(
    `/paymentmethod/getpaymentmethoddetails/${entId}`
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}
export function deletePayment (id){
  const url = ApiUrl(
    `/paymentmethod/${id}`
  );
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });

}
export function registerNewEntUser(formData,company,usertype,token) {
  const url = ApiUrl(`/unytag/enterprise/user/register?company=${company}&userType=${usertype}&token=${token}`);
  return Call('post', url, formData, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  },false);
}


export function getEnterpriseById(id){
  const url = ApiUrl(`/unytag/enterprise/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: ''},
  });
}


export function uploadEnterpriseUsers(formData) {
  const method = 'post';
  const url = ApiUrl(`/unytag/enterprise/upload`);
  const data= new FormData()
  data.append('csv', formData)
  const config= {
    "headers": {
      "content-type": 'multipart/form-data;'
    }
  }
  const dataObj = {
    method,
    url,
    data,
    config
  }
  const token = localStorage.getItem('token');
  dataObj.headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios(dataObj)
      .then((response) => {
        return response;
      })
}
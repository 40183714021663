import React, { useEffect, useState, useRef, useCallback } from 'react'
import { GoogleMap, useJsApiLoader, Polygon, Marker, useLoadScript } from '@react-google-maps/api';
import { isEmpty } from 'lodash';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const TollPlazaGoogleMap = ({
    tollPlaza,
    editable,
    onEditPolygon,
    onEditMarker
}) => {
    const [state, setState] = useState({});
    const [polygon, setPolygon] = useState([]);
    const polygonRef = useRef(null);
    const markerRef = useRef(null);
    const polygonListenersRef = useRef([]);

    useEffect(() => {
        setState({
            longitude: tollPlaza.longitude,
            latitude: tollPlaza.latitude,
            editable: editable,
        });
        if (!isEmpty(tollPlaza)) {
            setPolygonFence();
        }
    }, [tollPlaza, editable])

    const setPolygonFence = () => {
        const coords = { lat: tollPlaza.longitude, lng: tollPlaza.latitude };
        setPolygon(coords);
      }

    const onPolygonEdit = () => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
            .getPath()
            .getArray()
            .map(latLng => {
                return { lat: latLng.lat(), lng: latLng.lng() };
            });
            updatePolygonFence(nextPath);
        }
    }

    const updatePolygonFence = (updatedPolygon) => {
        let coordsArr = updatedPolygon.map((pol) => {
            return [pol.lng, pol.lat];
        })
        onEditPolygon(JSON.stringify(coordsArr));
    }

    const onPolygonLoad = useCallback(
        polygon => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            polygonListenersRef.current.push(
            path.addListener("set_at", onPolygonEdit),
            path.addListener("insert_at", onPolygonEdit),
            path.addListener("remove_at", onPolygonEdit)
            );
    }, [onPolygonEdit]);

    const onPolygonUnmount = useCallback(() => {
        polygonListenersRef.current.forEach(lis => lis.remove());
        polygonRef.current = null;
    }, []);

    const onMarkerEdit = (marker) => {
        onEditMarker(marker);
    };

    const { isLoaded } = useLoadScript({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD7lLQ2s8LcNXvZuUPdHiWyepbaUrbkKjs",
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: state.latitude, lng: state.longitude }}
            zoom={15}
        >
            <Marker 
                ref={markerRef}
                position={{ lat: state.latitude, lng: state.longitude }} 
                draggable={state.editable}
                onDragEnd={(e) => onMarkerEdit(e)}
                />
            <Polygon
                ref={polygonRef}
                editable={state.editable}
                path={polygon}
                onMouseUp={onPolygonEdit}
                onDragEnd={onPolygonEdit}
                key={"poly"}
                onLoad={onPolygonLoad}
                onUnmount={onPolygonUnmount}
                options={{
                    fillColor: "#000",
                    fillOpacity: 0.4,
                    strokeColor: "#000",
                    strokeOpacity: 1,
                    strokeWeight: 1
                }} />
            <></>
        </GoogleMap>
    ) : <></>
    }

    export default TollPlazaGoogleMap;
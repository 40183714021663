import React, { useState } from 'react';
import List from '../components/tags/List';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';

const Tags = () => {
  const [search, setSearch] = useState('');

  const handleSearch = value => {
    setSearch(value);
  };
  return (
    <div>
      <p className='ContainerTitleHeader'>
        <span className='PageTitle'>Tags</span>
        <ReuseInputField
          placeholder='Search by name,number...'
          className='SearchBar'
          onChange={handleSearch}
        />
      </p>
      <List search={search} />
    </div>
  );
};

export default Tags;

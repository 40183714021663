/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useCallback } from 'react';
import swal from 'sweetalert';
import debounce from 'lodash.debounce';
import { Row, Col, Form, Avatar, Divider, Spin } from 'antd';
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import {
  fetchUsersList,
  deleteUserById,
  verifyRegisteredUser,
} from '../../api/user';
import {
  fetchEnterpriseUsers,
  deleteEnterpriseUserById,
} from '../../api/enterprise/enterpriseUser';
import { userLogin } from '../../api/login';
import {
  fetchTollAuthorityUsers,
  deleteTollAuthUser,
} from '../../api/tollAuthority';
import { sendInvite } from '../../api/profile';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TeamManagement = () => {
  const [usersData, setUsersData] = useState([{}]);
  const [email, setEmail] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [existingError, setExistingUserError] = useState(false);
  const [id, setId] = useState('');

  const [form] = Form.useForm();

  const tableOptions = { pagination: {}, filters: {}, sorter: {} };
  const user = localStorage.getItem('user');
  const userDetails = user !== '' ? JSON.parse(user) : {};
  const { userType } = userDetails;

  const getUsersList = () => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    if (userType === 'UnyTag') {
      fetchUsersList(page, 10000).then(result => {
        if (result.success) {
          setUsersData(result.records);
          setInProgress(false);
        } else {
          setUsersData([]);
          setInProgress(false);
          swal('Oops!', result.errors.message.message, 'error');
        }
      });
    } else if (userType === 'Enterprise') {
      fetchEnterpriseUsers(userDetails.company, page, 10000).then(result => {
        if (result.success) {
          setUsersData(result.data.records);
          setInProgress(false);
        } else {
          setUsersData([]);
          setInProgress(false);
          swal('Oops!', result.errors.message.message, 'error');
        }
      });
    } else if (userType === 'Toll Authority') {
      fetchTollAuthorityUsers(page, 10000, userDetails.company).then(result => {
        if (result.success) {
          setUsersData(result.records);
          setInProgress(false);
        } else {
          setUsersData([]);
          setInProgress(false);
          swal('Oops!', result.errors.message.message, 'error');
        }
      });
    }
  };

  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = () => value => {
    setEmail(value);
    debouncedSearch(value);
  };

  const debouncedSearch = useCallback(
    debounce(value => {
      setInProgress(true);
      verifyRegisteredUser(
        value,
        userDetails.companyId,
        userDetails.userTypeId
      ).then(result => {
        if (result.success) {
          setDisableBtn(false);
          setExistingUserError(false);
          setInProgress(false);
        } else {
          setDisableBtn(true);
          setExistingUserError(true);
          setInProgress(false);
        }
      });
    }, 300),
    [] // will be created only once initially
  );

  const handleSubmit = () => {
    const formData = {};
    formData.company = userDetails.company;
    formData.email = email;
    formData.userType = userDetails.userType;

    setDisableBtn(true);

    sendInvite(formData).then(result => {
      if (result.success) {
        setDisableBtn(false);
        setEmail('');
        form.setFieldsValue({
          email: '',
        });
        swal({
          title: 'Success!',
          text: result.data.message,
          icon: 'success',
          button: 'OK',
        });
      } else {
        setDisableBtn(false);
        swal('Oops!', 'Something Went Wrong', 'error');
      }
    });
  };
  const handleRemove = () => {
    setDisableBtn(true);
    if (userType === 'UnyTag') {
      deleteUserById(id).then(result => {
        if (result.success) {
          setDisableBtn(false);
          getUsersList();
          setId('');
          swal({
            title: 'Success!',
            text: result.data.message,
            icon: 'success',
            button: 'OK',
          });
        } else {
          swal('Oops!', 'Something Went Wrong', 'error');
        }
      });
    } else if (userType === 'Enterprise') {
      deleteEnterpriseUserById(userDetails.company, id).then(result => {
        if (result.success) {
          setDisableBtn(false);
          getUsersList();
          setId('');
          swal({
            title: 'Success!',
            text: result.data.message,
            icon: 'success',
            button: 'OK',
          });
        } else {
          swal('Oops!', 'Something Went Wrong', 'error');
        }
      });
    } else if (userType === 'TOLLAUTHORITY') {
      deleteTollAuthUser(userDetails.company, id).then(result => {
        if (result.success) {
          setDisableBtn(false);
          getUsersList();
          setId('');
          swal({
            title: 'Success!',
            text: result.data.message,
            icon: 'success',
            button: 'OK',
          });
        } else {
          swal('Oops!', 'Something Went Wrong', 'error');
        }
      });
    }
  };

  const selectUser = sid => {
    if (id === '') {
      setId(sid);
    } else {
      setId('');
    }
  };
  const displayAvatar = (displayName) => {
    if (displayName.length === 1) {
      return displayName.join().toUpperCase().repeat(2).split('').join(' ');
    }
    else {
      return displayName.join(" ").toUpperCase();
    }
  }

  return (
    <div className='LoginLayout'>
      <Spin spinning={inProgress}>
        <div className='LoginContentSize'>
          <Row className='teamContent'>
            <Col lg={17} style={{ margin: '10px auto', textAlign: 'center' }}>
              <Col lg={15} offset={8}>
                <p className='inviteText'>
                  Invite team members to create an account
                </p>

                <Form form={form} layout='vertical'>
                  <Form.Item
                    name='email'
                    rules={[
                      { required: true, message: 'Please enter Email!' },
                      {
                        type: 'email',
                        message: 'Please provide a valid email address!',
                        validationTrigger: 'onBlur',
                      },
                    ]}
                    label='ENTER EMAIL ADDRESS'
                    className='authlabels'>
                    <ReuseInputField
                      type='text'
                      placeholder='Invite by email address'
                      height='52px'
                      onChange={handleChange()}
                    />
                    {existingError && (
                      <p className='errorMessageReg'>
                        <span>A user with this email already exists</span>
                      </p>
                    )}
                  </Form.Item>

                  <div
                    className={`invites ${usersData.length > 2 ? 'invitesScroll' : ''
                      }`}>
                    {usersData.map(user => {
                      return (
                        <>
                          <Row
                            className='userInviteMargin'
                            onClick={() => selectUser(user.id)}>
                            <Col lg={3} className='alignStart'>
                              <span className='AvatarIcon1'>
                                <Avatar
                                  shape='square'
                                  className='avtarBackground'>
                                  {
                                    user.contactName  && displayAvatar(user.contactName.match(/\b(\w)/g))
                                  }
                                </Avatar>
                              </span>
                            </Col>
                            <Col lg={18}>
                              <Col
                                lg={24}
                                className={`alignStart ${user.id === id || user.status === 'INACTIVE'
                                    ? ''
                                    : 'userNameHeaders'
                                  }`}>
                                {user && user.contactName}{' - '}
                                {user.role !== null ? user.role : ''}
                              </Col>
                              <Col
                                lg={24}
                                className='alignStart userEmailsLabel'>
                                {user && user.status === 'ACTIVE' && user.email}
                                {user &&
                                  user.status === 'INACTIVE' &&
                                  'Invite sent! - Not yet responded.'}
                              </Col>
                            </Col>
                            <Col lg={3}>
                              <Col
                                className={`alignRight ${user.id === id
                                    ? 'userIconHeadersOnHold'
                                    : 'userIconHeaders'
                                  }`}>
                                {user.id === id ? (
                                  <PlusCircleOutlined />
                                ) : (
                                  <CheckCircleOutlined />
                                )}
                              </Col>
                            </Col>
                          </Row>
                          <Divider />
                        </>
                      );
                    })}
                  </div>

                  <Col lg={24} className='inviteButton'>
                    {id === '' ? (
                      <ReuseButton
                        text='SEND INVITE'
                        disabled={
                          email === '' ||
                          !re.test(String(email).toLowerCase()) ||
                          disableBtn
                        }
                        className={email === '' ? 'CreateTag' : 'center1auth'}
                        onClick={handleSubmit}
                      />
                    ) : (
                      <ReuseButton
                        text='REMOVE PERSON'
                        className='center1upload'
                        onClick={handleRemove}
                      />
                    )}
                  </Col>
                </Form>
              </Col>
              <Col lg={6} />
            </Col>
            <Col lg={5} />
          </Row>
        </div>
      </Spin>
    </div>
  );
};

export default TeamManagement;

import _, { toLower } from 'lodash';
import { Call, ApiUrl, ParseGeneralResponse, PageParams } from './ApiUtils';
import AppConfig from '../config/AppConfig';
import axios from 'axios/index';

export function fetchTollPlazas(
  page = 1,
  perPage = AppConfig.perPage,
  facilityId = '',
  id = '',
  order='',
  searchKey=''
) {
  const url = ApiUrl(
    `/unytag/toll/plaza?${PageParams(page, perPage)}&search=${searchKey}&sortBy=${id}&sortOrder=${order}&facilityId=${facilityId}`,
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}

export function saveTollPlaza(data, method) {
  let apimethod = toLower(method);
  const dataParameters = data;
  const url = ApiUrl(`/unytag/toll/plaza`);
  return Call(apimethod, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function deleteTollPlaza(id) {
  const url = ApiUrl(`/unytag/toll/plaza/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function getTollPlaza(id) {
  const url = ApiUrl(`/unytag/toll/plaza/${id}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function uploadTollPlazaCSV(facilityId, formData) {
  const method = 'post';
  const url = ApiUrl(`/unytag/toll/plaza/upload/${facilityId}`);
  const data= new FormData()
  data.append('csv', formData)
  const config= {
    "headers": {
      "content-type": 'multipart/form-data;'
    }
  }
  const dataObj = {
    method,
    url,
    data,
    config
  }
  const token = localStorage.getItem('token');
  dataObj.headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios(dataObj)
    .then((response) => {
      return response;
    })
}

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import EnterprisesList from '../components/enterprises/List';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import { fetchEnterprices } from '../api/enterprise/enterprise';
import { Spin } from '../common/AntdUiComponents';
import { alertMessage } from '../common/Common';

const Enterprises = () => {
  const history = useHistory();
  const [title, setTitle] = useState('Enterprises');
  const [listVisible, setListVisible] = useState('list');
  const [enterprisesData, setEnterprisesData] = useState([{}]);
  const [inProgress, setInProgress] = useState(false);
  const [pagination, setPagination] = useState({});
  const [perPage,setPerPage] = useState(20)
  const [search, setSearch] = useState('');
  const pagereload = useLocation().search;
  const user = localStorage.getItem('user');
  const userDetails = user!==''? JSON.parse(user) : { };


  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const getEnterpriseList = (name,pageSizeCount) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    fetchEnterprices(page, pageSizeCount, name).then((result) => {
      if (result.success) {
        setPagination(result.pagination);
        if (result.data.records !== null) {
          setEnterprisesData(result.data.records);
        } else {
          setPagination({});
          setEnterprisesData([]);
        }
        setInProgress(false);
      } else {
        setEnterprisesData([]);
        setPagination({});
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    const reload = new URLSearchParams(pagereload).get('reload');
    getEnterpriseList('',perPage);
    if(userDetails.userType === 'Enterprise'){
      setListVisible('category')
      setTitle(userDetails.company);
      history.push(`/unytag/web/enterprise/${userDetails.company}/${userDetails.companyId}`);
    }else{
      setListVisible('list')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-shadow
  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize)
    getEnterpriseList(search,pagination.pageSize);
  };

  const loadEnterPrise = () => {
    getEnterpriseList('',perPage);
  };
  const handleSearch = (value) => {
    setSearch(value)
    getEnterpriseList(value,perPage);
  };

  const handleTitle = (langValue, nextState, id) => {
    history.push(`/unytag/web/enterprise/${nextState}/${langValue}/${id}`);
  };

  return (
    <div>
      <Spin spinning={inProgress}>
        {(listVisible === 'list' ||
          listVisible === 'category' ||
          listVisible === 'Profile') && (
          <p className="ContainerTitleHeader">
            <span className="PageTitle">
              {title}{' '}
              {listVisible === 'list' ? `(${pagination.totalCount!==undefined ? pagination.totalCount:''})` : ''}
            </span>
            <ReuseInputField
              placeholder="Search by name..."
              className="SearchBar"
              onChange={handleSearch}
            />
          </p>
        )}
        {listVisible === 'list' && (
          <EnterprisesList
            onSelectTitle={handleTitle}
            data={enterprisesData}
            pagination={
              pagination !== null
                ? {
                    total: parseInt(pagination.totalCount, 10),
                    current: parseInt(pagination.currentPage, 10),
                    pageSize: parseInt(pagination.perPage, 10),
                    pageSizeOptions: [5,10,20],
                  }
                : {}
            }
            // eslint-disable-next-line no-shadow
            tableChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            loadEnterPrise={loadEnterPrise}
          />
        )}
      </Spin>
    </div>
  );
};

export default Enterprises;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Col, Row, Select } from '../common/AntdUiComponents';
import BaseModal from '../utils/reusableComponents/BaseModal';
import List from '../components/defectiveTags/List';
import ReuseButton from '../utils/reusableComponents/ReuseButton';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import { alertMessage } from '../common/Common';

const roles = ['User', 'Admin'];

const DefectiveTags = () => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [account, setAccount] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();

    setShowViewModal(true);
  };

  const handleOrder = (e) => {
    e.preventDefault();
    setShowViewModal(false);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
  };

  const renderViewModel = () => {
    return (
      <BaseModal width="65%" onCancel={() => handleOnModalClose()}>
        <Row className="createTagsOrdered" gutter={20}>
          <Col span={12}>
            <span className="TagModalTitle">ACCOUNT</span>
            <ReuseInputField
              className="TagInput"
              width="90%"
              value={account}
              placeholder="Search account"
            />
          </Col>
          <Col span={12}>
            <span className="TagModalTitle">ACCOUNT TYPE</span>
            <Select
              placeholder="Select Role"
              disabled
              className="authlabels1"
              style={{ width: '88%' }}
            >
              {roles.map((role) => (
                <Select.Option key={role} value={role}>
                  {role}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={12}>
            <span className="TagModalTitle">TAG ID</span>
            <ReuseInputField
              className="TagInput"
              width="90%"
              value={account}
              placeholder="Search account"
            />
          </Col>
          <Col span={12}>
            <span className="TagModalTitle">LAST CONNECTED</span>
            <ReuseInputField
              className="TagInput"
              width="90%"
              value={account}
              placeholder="Search account"
            />
          </Col>
        </Row>
        {/*   */}
        <div className="createWrapper">
          <ReuseButton
            text="ORDER"
            onClick={(e) => {
              handleOrder(e);
            }}
          />
        </div>
      </BaseModal>
    );
  };
  return (
    <div>
      <p className="ContainerTitleHeader">
        <Row>
          <Col lg={6}>
            <span className="PageTitle">Defective Tags (1)</span>
          </Col>

          <Col lg={18} className="alignRight ">
            <Button
              className=" btn-style btn-info enterprisebuttons createTagAdjustment"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              CREATE TICKET
            </Button>
            <ReuseInputField
              placeholder="Search by name, serial number.."
              className="SearchBar"
            />
          </Col>
        </Row>
      </p>
      <List />
      {showViewModal && renderViewModel()}
    </div>
  );
};

export default DefectiveTags;

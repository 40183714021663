/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from 'react';
import { Row, Col, Table, Button, Modal } from '../../common/AntdUiComponents';
import AddressForm from '../../common/Addressform';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import {validateAddressForm} from '../../common/Common';

const List = () => {
  const [selectableObj, setSelectableObj] = useState('');
  const [visible, setVisible] = useState(false);
  const [address] = useState('');
  const [addressType,setAddressType] = useState('')
  const [addressObj,setAddressObj] = useState({})
  const [validateElements, setValidateElements] = useState([]);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [state, setState] = useState({
    address: '',
  });
  const dataSource = [
    {
      key: '1',
      name: 'Alex Ajayi',
      tagid: 'AF1793AR7732HYKET64',
      lastconnected: '08/31/2020 03:32P.M',
      accounttype: 'Admin',
    },
    {
      key: '2',
      name: 'Jones Drew',
      tagid: 'DF134OI74832HYKET64',
      lastconnected: '08/31/2020 01:45P.M',
      accounttype: 'Admin',
    },
    {
      key: '3',
      name: 'Jimmy Tang',
      tagid: 'DF134OI74832HYY4235',
      lastconnected: '07/21/2020 12:13P.M',
      accounttype: 'User',
    },
  ];

  const handleSubmit = (e, obj, type, id) => {
    e.preventDefault();
    const newObj = { ...obj, type };
    setSelectableObj(newObj);
    setVisible(!visible);
  };

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'name',
      key: 'name',
      render: data => <span style={{ textAlign: 'right' }}>Jones Drew</span>,
      sorter: (a, b) => {
        a.name.localeCompare(b.name);
        return <span className='qedq' />;
      },
    },
    {
      title: 'Tag ID',
      dataIndex: 'tagid',
      key: 'tagid',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>DF34343FTFGTG54</span>,
    },
    {
      title: 'Account Type',
      dataIndex: 'lastconnected',
      key: 'lastconnected',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>Admin</span>,
    },
    {
      title: 'Ticket Created',
      dataIndex: 'accounttype',
      key: 'accounttype',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>08/12/2020 01:00P.M.</span>,
    },

    {
      title: 'Last Connected',
      dataIndex: 'accounttype',
      key: 'accounttype',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>08/10/2020 03:00P.M</span>,
    },

    {
      title: 'Resolve?',
      dataIndex: 'accounttype',
      key: 'accounttype',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '200px',
      align: 'center',
      render: data => (
        <>
          <Button
            className='btn-style btn-success enterpriseOptionbuttons'
            onClick={e => {
              handleSubmit(e, data, 'sendNewTag');
            }}>
            SEND NEW TAG
          </Button>
        </>
      ),
    },

    {
      title: 'Order Date',
      dataIndex: 'accounttype',
      key: 'accounttype',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>-</span>,
    },

    {
      title: 'Shipped Date',
      dataIndex: 'accounttype',
      key: 'accounttype',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>-</span>,
    },
    {
      title: 'Delivery Date',
      dataIndex: 'accounttype',
      key: 'accounttype',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>-</span>,
    },
    {
      title: 'Status',
      dataIndex: 'accounttype',
      key: 'accounttype',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: data => <span>-</span>,
    },
  ];

  const handleModalCancel = modalType => {
    setSelectableObj('');
    setVisible(!visible);
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    console.log(element,obj)
    setAddressType(addressType)
    setAddressObj(obj)
    console.log(obj)
    console.log(validateElements)

      const arr = [...new Set([...element])];
    setValidateElements(arr);
    console.log(arr)
    const addrressError = validateAddressForm(arr, obj);
    console.log(addrressError)
    if(addrressError){
      const errors = [];
      errors.push(`${addrressError}`);
      setErrors(errors)
      showError(true)
    }else{
      showError(false)
    }
    setState({
      ...state,
      [addressType]: obj,
      // [addressObj]:obj
    });
  };

  const onFocus = (event, newAddress) => {
    const validateFields = [
      'address_line1',
      'city',
      'state',
      'country',
      'zipcode',
    ];
    if (newAddress === '' || newAddress === undefined) {
      if (addressObj !== '') {
        addressObj.address_line1 =
        addressObj.address_line1 !== undefined ? addressObj.address_line1 : '';
        addressObj.address_line2 =
        addressObj.address_line2 !== undefined ? addressObj.address_line2 : '';
      }
      const addrressError = validateAddressForm(validateFields, addressObj);
      if (addrressError) {
        const errors = [];
        errors.push(`${addrressError}`);
        setErrors(errors);
        showError(true);
      } else {
        showError(false);
      }
      handleOnAddressChange('', validateFields, '', addressObj);
    } else {
      if (newAddress !== '') {
        newAddress.address_line1 =
          newAddress.address_line1 !== undefined
            ? newAddress.address_line1
            : '';
        newAddress.address_line2 =
          newAddress.address_line2 !== undefined
            ? newAddress.address_line2
            : '';
      }
      const addrressError = validateAddressForm(validateFields, newAddress);
      if (addrressError) {
        const errors = [];
        errors.push(`${addrressError}`);
        setErrors(errors);
        showError(true);
      } else {
        showError(false);
      }
    }
  }

  const handleModalType = (modalType, id) => {};

  const renderModal = () => {
    const title = 'Enter the shipping address to send the tag.';
    const description =
      'The shipping address is always auto-populated with the shipping address on file but you can change it if necessary.';
    const btnText = 'SHIP TAG';
    const btnType =
      'btn-style btn-success enterpriseOptionbuttons buttonWidth1';
    const shippingAddress = '1800 Lawn Lane, Austin, TX 77001';

    return (
      <Modal
        className='toll-authority'
        title={title}
        visible={visible}
        width='500px'
        footer={null}
        onCancel={() => {
          handleModalCancel(selectableObj.type);
        }}>
        {selectableObj.name && (
          <p className='text-center'>
            {selectableObj.type === 'deleteUser'
              ? `Admin: ${selectableObj.name}`
              : `User: ${selectableObj.name}`}
          </p>
        )}
        {shippingAddress && (
          <div>
            <p className='text-center'>Admin: Jones Drew</p>
            <div className='sendTagsPadding'>
              <span className='sendTagUser'>SHIPPING ADDRESS</span>

              <AddressForm
                autoComplete
                twoRows
                showReqFields
                address={state.address}
                onFocus={onFocus}
                onChange={(element, value, obj, addressLine) =>
                  handleOnAddressChange(
                    'address',
                    [element],
                    value,
                    obj,
                    addressLine
                  )
                }
                showError={error}
              errors={errors}
              addressType='company'
              />
            </div>
          </div>
        )}
        <div className='modalDescrition'>
          <p>Disclaimer:</p>
          <p>{description}</p>
        </div>
        <Row type='flex' justify='center'>
          <ReuseButton
            text={btnText}
            className={`btn-style ${btnType}`}
            onClick={e => {
              handleModalType(selectableObj.type, 'id');
            }}
          />
        </Row>
      </Modal>
    );
  };

  return (
    <div className='Basecontainer'>
      <Row gutter={30}>
        <Col span={24} className='tablePadding  mar-top-10 '>
          <p className='TitleText mar-bottom-20'>
            Here are all the tags that users have noted as defective/not
            working.
          </p>
          <Table
            className='BaseTable'
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
      {visible && renderModal()}
    </div>
  );
};

export default List;

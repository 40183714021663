/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import TollAuthoritiesList from '../components/tollAuthority/List';
import ReuseInputField from '../utils/reusableComponents/ReuseInputField';
import { fetchTollAuthorities, saveTollAuthority,saveTollAuthorityUser } from '../api/tollAuthority';
import { Spin } from '../common/AntdUiComponents';
import { alertMessage } from '../common/Common';

const TollAuthorities = () => {
  const history = useHistory();
  const [title] = useState('Toll Authorities');
  const [listVisible] = useState('list');
  const [tollAuthoritiesData, setTollAuthoritiesData] = useState([{}]);
  const [disableBtn, setDisableBtn] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowViewModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState('');
  const [stateCLear, setStateCLear] = useState(false);
  const [perPage, setPerPage] = useState(20);
  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  const getTollAuthoritiesList = (name, pageSizeCount) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    fetchTollAuthorities(page, pageSizeCount, name).then(result => {
      if (result.success) {
        if (result.pagination !== null) {
          setPagination(result.pagination);
          setTollAuthoritiesData(result.records);
        } else {
          setPagination({});
          setTollAuthoritiesData([]);
        }
        setInProgress(false);
      } else {
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setTollAuthoritiesData([]);
        setPagination({});
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    getTollAuthoritiesList('', perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize);
    getTollAuthoritiesList(search, pagination.pageSize);
  };

  const handleChange = value => {
    setSearch(value);
    getTollAuthoritiesList(value, perPage);
  };

  const handleTitle = (langValue, nextState, id) => {
    history.push(`/unytag/web/tollauthority/category/${langValue}/${id}`);
  };

  const handleSave = (status, data) => {
    setInProgress(true);
    const formData = {};
    formData.address1 = data.address.address_line1.trim();
    formData.address2 = data.address.address_line2.trim();
    formData.city = data.address.city.trim();
    formData.country = data.address.country.trim();
    formData.email = data.email;
    formData.id = data.id;
    // formData.stateId = data.address.state;
    formData.state = data.address.state.trim();
    formData.telephone = data.telephone;
    formData.tollAuthority = data.tollAuthority;
    formData.website = data.pref+data.website+data.suff;
    formData.zipCode = data.address.zipcode;
    saveTollAuthority(status, formData).then(result => {
      if (result.success) {
        const tollAuth ={};
        tollAuth.accountTypeId=2;
        tollAuth.roleId=9;
        tollAuth.country=formData.country;
        tollAuth.tollAuthorityId=result.data.id;
        tollAuth.tollAuthority=formData.tollAuthority;
        tollAuth.state = formData.state;
        tollAuth.email = formData.email;
        tollAuth.phone = data.phone;
        tollAuth.contactName = data.name;

        saveTollAuthorityUser(status,tollAuth).then(data =>{
          if(result.success){
            alertMessage('TOLL AUTHORITY CREATED SUCCESSFULLY');
            getTollAuthoritiesList('', 20);
            setInProgress(false);
            setShowViewModal(false);
            setStateCLear(true);
            setDisableBtn(true);
          }else{
            alertMessage(
              result.errors.message.message.toString().toUpperCase(),
              'error',
              3
            );
            setInProgress(false);
            setDisableBtn(false)
          }
        })

      } else {
        alertMessage(
          result.errors.message.message.toString().toUpperCase(),
          'error',
          3
        );
        setInProgress(false);
        setDisableBtn(false)
      }
    });
  };
  return (
    <div>
      <Spin spinning={inProgress}>
        <p className='ContainerTitleHeader'>
          <span className='PageTitle'>
            {title}{' '}
            {listVisible === 'list' && <span>({pagination.totalCount})</span>}
          </span>
          <ReuseInputField
            placeholder='Search by name...'
            className='SearchBar'
            value={search}
            onChange={handleChange}
          />
        </p>
        {listVisible === 'list' && (
          <TollAuthoritiesList
            data={tollAuthoritiesData}
            createToll={handleSave}
            showModal={showModal}
            onSelectTitle={handleTitle}
            disableSubmitBtn={disableBtn}
            stateCLear={stateCLear}
            pagination={
              pagination !== null
                ? {
                    total: parseInt(pagination.totalCount, 10),
                    current: parseInt(pagination.currentPage, 10),
                    pageSize: parseInt(pagination.perPage, 10),
                    pageSizeOptions: [5, 10, 20],
                  }
                : {}
            }
            // eslint-disable-next-line no-shadow
            tableChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
          />
        )}
      </Spin>
    </div>
  );
};

export default TollAuthorities;

/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Spin,
} from '../../common/AntdUiComponents';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import {
  freezeAccount,
  fetchEnterpriceUserById,
} from '../../api/enterprise/enterprise';
import { deleteUserById } from '../../api/user';
import { alertMessage } from '../../common/Common';
import AddressForm from '../../common/Addressform';

const UserAccounts = ({ onSelectTitle, name, userData, tableChange, id }) => {
  const history = useHistory();
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const [selectableObj, setSelectableObj] = useState('');
  const [enterPrise, setEnterprise] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [pagination, setPagination] = useState({});
  const [address, setaddress] = useState('');

  const getEnterpriseUserById = () => {
    setInProgress(true);
    fetchEnterpriceUserById(3).then((result) => {
      if (result.success) {
        setEnterprise(result.data);
        setInProgress(false);
      } else {
        // alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        setInProgress(false);
      }
    });
  };

  useEffect(() => {
    getEnterpriseUserById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnAddressChange = (addressType, element, value, obj) => {};

  const handleSubmit = (e, obj, type, sid) => {
    e.preventDefault();
    const objData = { ...obj, type };
    setSelectableObj(objData);
    setVisible(!visible);
  };

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'contactName',
      key: 'contactName',
      sortDirections: ['ascend','descend','ascend'],
      width: 250,
      sorter: (a, b) => a.contactName.localeCompare(b.firstName),
      render: (text, data) => <span>Raju</span>,
    },
    {
      title: 'Tag ID',
      dataIndex: 'id',
      key: 'id',
      sortDirections: ['ascend','descend','ascend'],
      width: 150,
      sorter: (a, b) => a.contactName.localeCompare(b.firstName),
      render: (text, data) => (
        <span>
          {/* {data.firstName} {data.lastName} */}
          2122
        </span>
      ),
    },
    {
      title: 'Account Type',
      dataIndex: 'createdUserId',
      key: 'createdUserId',
      sortDirections: ['ascend','descend','ascend'],
      width: 150,
      sorter: (a, b) => a.createdUserId.localeCompare(b.createdUserId),
      render: (text, data) => <span>Admin</span>,
    },
    {
      title: '',
      dataIndex: 'age',
      key: 'age',
      align: 'right',
      render: (text, data) => (
        <>
          <Button
            className={`btn-style enterprisebuttons btn-info  `}
            onClick={(e) => {
              handleSubmit(e, data, 'freezeUser', id);
            }}
          >
            FREEZE TAG
          </Button>
          <Button
            className="btn-style btn-success enterpriseOptionbuttons"
            onClick={(e) => {
              handleSubmit(e, data, 'sendNewTag');
            }}
          >
            SEND NEW TAG
          </Button>
        </>
      ),
    },
  ];

  const addNewUser = () => {
    history.push(`/unytag/web/enterprise/add/user`);
  };

  const handleModalCancel = (modalType) => {
    setSelectableObj('');
    setVisible(!visible);
  };

  const handleModalType = (modalType, sid) => {
    if (modalType === 'freezeUserAccount') {
      const user = localStorage.getItem('user');
      const userDetails = user !== '' ? JSON.parse(user) : {};
      freezeAccount(sid, userDetails.id).then((result) => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          getEnterpriseUserById();
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
    if (modalType === 'deleteUserAccount') {
      deleteUserById(params.id).then((result) => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          alertMessage(
            `${name.toUpperCase()} ` + `HAS BEEN DELETED`,
            'error',
            3,
          );
          history.push('/unytag/web/users');
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
  };

  const renderModal = () => {
    let title;
    let description;
    let btnText;
    let btnType;
    let shippingAddress;
    if (selectableObj.type === 'deleteUser') {
      title = 'Do you want to delete the user?';
      description =
        'If you delete the admin user without assigning another user as admin, the entire account will be deleted.';
      btnText = 'DELETE ADMIN';
      btnType = 'btn-danger';
    } else if (selectableObj.type === 'deleteUserAccount') {
      title = 'Do you want to delete the account?';
      description =
        'If you delete the account all tags attached to this account will be deactivated forever. This is irreversible.';
      btnText = 'DELETE ACCOUNT';
      btnType = 'btn-danger';
    } else if (selectableObj.type === 'freezeUser') {
      title = 'Do you want to freeze this user’s tag?';
      description =
        'The user will no longer be able to use their tag for any tolls.';
      btnText = 'FREEZE TAG';
      btnType = 'btn-info';
    } else if (selectableObj.type === 'freezeUserAccount') {
      title = 'Do you want to freeze the account?';
      description =
        'If you freeze the account all tags attached to this account will be deactivated till the account is unfrozen.';
      btnText = 'FREEZE ACCOUNT';
      btnType = 'btn-info';
    } else {
      title = 'Enter the shipping address to send the tag.';
      description =
        'The shipping address is always auto-populated with the shipping address on file but you can change it if necessary.';
      btnText = 'SHIP TAG';
      btnType = 'btn-style btn-success enterpriseOptionbuttons buttonWidth1';
      shippingAddress = '1800 Lawn Lane, Austin, TX 77001';
    }
    return (
      <Modal
        className="toll-authority"
        title={title}
        visible={visible}
        width="500px"
        footer={null}
        onCancel={() => {
          handleModalCancel(selectableObj.type);
        }}
      >
        {selectableObj.name && (
          <p className="text-center">
            {selectableObj.type === 'deleteUser'
              ? `Admin: ${selectableObj.name}`
              : `User: ${selectableObj.name}`}
          </p>
        )}
        {shippingAddress && (
          <div>
            <p className="text-center">Admin: Jones Drew</p>
            <div className="sendTagsPadding">
              <span className="sendTagUser">SHIPPING ADDRESS</span>

              <AddressForm
                autoComplete
                twoRows
                showReqFields
                address={address}
                onChange={(element, value, obj) =>
                  handleOnAddressChange('address', [element], value, obj)
                }
              />
            </div>
          </div>
        )}
        <div className="modalDescrition">
          <p>Disclaimer:</p>
          <p>{description}</p>
        </div>
        <Row type="flex" justify="center">
          <ReuseButton
            text={btnText}
            className={`btn-style ${btnType}`}
            onClick={(e) => {
              handleModalType(selectableObj.type, id);
            }}
          />
        </Row>
      </Modal>
    );
  };

  return (
    <div>
      <Spin spinning={inProgress}>
        <p className="EntTitleText">
          <Row type="flex" justify="space-between">
            <Col xs={8} className="d-flex justify-start">
              <span className="PageTitle">{name}</span>
            </Col>
            <Col xs={16} className="">
              <Button
                className="btn-style btn-danger enterprisebuttons"
                onClick={(e) => {
                  handleSubmit(e, '', 'deleteUserAccount', id);
                }}
              >
                DELETE ACCOUNT
              </Button>
              <ReuseInputField
                placeholder="Search by number, name..."
                width="800px"
                className="EntSearchBar {
                "
              />
            </Col>
          </Row>
        </p>
        <div className="Basecontainer">
          <Row gutter={30}>
            <Col span={24} className="tablePadding mar-top-20">
              <p className="TitleText  mar-bottom-20">
                
              </p>
              <p className="TitleText mar-bottom-20 addTollEntUserBtn">
                <Button
                  className="floatRight btn-style btn-info enterpriseOptionbuttons "
                  onClick={addNewUser}
                >
                  ADD NEW USER
                </Button>
              </p>
              <Table
                className="BaseTable"
                dataSource={userData}
                columns={columns}
                showHeader
                pagination={pagination}
                onChange={tableChange}
              />
            </Col>
          </Row>
        </div>

        {visible && renderModal()}
      </Spin>
    </div>
  );
};

export default UserAccounts;

import { Call, ApiUrl, ParseGeneralResponse, PageParams } from '../ApiUtils';
import AppConfig from '../../config/AppConfig';

export function fetchEnterpriseUsers(
  name,
  page = 1,
  perPage = AppConfig.perPage,
  value = '',
  id = '',order=''
) {
  const url = ApiUrl(
    `/unytag/enterprise/user?customer=${encodeURIComponent(
           name
         )}&${PageParams(page, perPage)}&search=${value}&sortBy=${id}&sortOrder=${order}`
       );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '', pagination: 'meta',adminsCount:'adminsCount' },
  });
}

export function fetchEnterpriseUserById(name,id) {
  const url = ApiUrl(`/unytag/enterprise/user/${id}?customer=${name}`);
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function addEnterpriseUser(isNew, data, id) {
  const method = isNew ? 'post' : 'put';
  const url = isNew
    ? ApiUrl(`/unytag/enterprise/user`)
    : ApiUrl(`/unytag/enterprise/user/${id}`);
  const dataParameters = data;
  return Call(method, url, dataParameters, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

export function deleteEnterpriseUserById(name, id) {
  const url = ApiUrl(`/unytag/enterprise/user/${id}`);
  return Call('delete', url, {}, ParseGeneralResponse, {
    responseDataKeys: { data: '' },
  });
}

import React, { useState } from 'react';
import { Row, Col, Form } from 'antd';
import swal from 'sweetalert';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import {
  getAdminUserDetails,
  updatePassword,
  validatePassword,
} from '../../api/profile';

const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const Password = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldMatch, setOldMatch] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');

  const [form] = Form.useForm();

  const handleChange = key => value => {
    if (key === 'newpassword') {
      setNewPassword(value);
    }
    if (key === 'confirmNewpassword') {
      setConfirmPassword(value);
    }
  };

  const handleOldPassword = () => value => {
    setOldPassword(value);
    handleoldPasswordBlur(value);
  };

  const handleoldPasswordBlur = value => {
    const formData = {};
    const user = localStorage.getItem('user');
    const userDetails = user!==''? JSON.parse(user) : { };
    getAdminUserDetails(userDetails.id).then(result => {
      if (result.success) {
        // eslint-disable-next-line no-shadow
        const { email } = result.data;
        setEmail(email);
        formData.email = email;
        formData.id = userDetails.id;
        formData.password = value;
        // eslint-disable-next-line no-shadow
        validatePassword(formData).then(result => {
          if (result.success) {
            const tokenData = result.data.resetToken;
            setToken(tokenData);
            setOldMatch(true);
          } else {
            setOldMatch(false);
          }
        });
      } else {
        swal('Oops!', 'Something Went Wrong', 'error');
      }
    });
  };

  const handleSubmit = () => {
    const formData = {};
    formData.password = newPassword;
    if (oldMatch) {
      if (re.test(String(newPassword))) {
        updatePassword(email, token, formData).then(result => {
          if (result.success) {
            setDisableBtn(false);
            setToken('');
            swal({
              title: 'Success!',
              text: 'PASSWORD UPDATED SUCCESSFULLY',
              icon: 'success',
              button: 'OK',
            }).then(() => {
              form.setFieldsValue({
                oldpassword: '',
                newpassword: '',
                confirmNewpassword: '',

              });
              setOldPassword('');
              setConfirmPassword('');
              setNewPassword('');
              setOldMatch(false)
            });
          } else {
            setDisableBtn(false);
            swal('Oops!', 'Something Went Wrong', 'error');
          }
        });
      } else {
        swal(
          'Oops!',
          'Password must contain minimum 8 characters, at least 1 letter, 1 number and 1 special character.',
          'error'
        );
      }
    } else {
      swal('Oops!', 'Your old password does not match.', 'error');
    }
  };

  return (
    <div className='LoginLayout'>
      <div className='LoginContentSize'>
        <Row className='forgetContent'>
          <Col lg={14} style={{ margin: '55px auto', textAlign: 'center' }}>
            <Col lg={15} offset={8}>
              <Form form={form} layout='vertical'>
                <Form.Item
                  name='oldpassword'
                  rules={[
                    { required: true, message: 'Please enter old password!' },
                  ]}
                  label='OLD PASSWORD'
                  className='Profilelabels'>
                  <ReuseInputField
                    type='password'
                    placeholder=''
                    height='52px'
                    value={oldPassword}
                    onChange={handleOldPassword()}
                    // onBlur={handleoldPasswordBlur}
                  />
                </Form.Item>
                <Form.Item
                  name='newpassword'
                  rules={[
                    { required: true, message: 'Please enter new password!' },
                  ]}
                  label='NEW PASSWORD'
                  className='Profilelabels'>
                  <ReuseInputField
                    type='password'
                    placeholder=''
                    height='52px'
                    value={newPassword}
                    onChange={handleChange('newpassword')}
                  />
                </Form.Item>
                <Form.Item
                  name='confirmNewpassword'
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('newpassword') === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error('Passwords do not match.')
                        );
                      },
                    }),
                  ]}
                  label='CONFIRM NEW PASSWORD'
                  dependencies={['newpassword']}
                  className='Profilelabels'>
                  <ReuseInputField
                    type='password'
                    placeholder=''
                    // icon="Mail"
                    height='52px'
                    value={confirmPassword}
                    onChange={handleChange('confirmNewpassword')}
                  />
                </Form.Item>
                <ReuseButton
                  text='CHANGE'
                  disabled={
                    oldPassword === '' ||
                    newPassword === '' ||
                    confirmPassword === '' ||
                    newPassword !== confirmPassword ||
                    disableBtn
                  }
                  className='center1auth'
                  onClick={handleSubmit}
                />
              </Form>
            </Col>
            <Col lg={6} />
          </Col>
          <Col lg={5} />
        </Row>
      </div>
    </div>
  );
};

export default Password;

const initialState = {
    links: [],
}

function breadcrumbReducer (state = initialState, action) {
    switch (action.type) {
        case "BREADCRUMB_LINKS" :
            const links = action.payload.reduce((a,b) => { 
                if (a.indexOf(b) < 0 ) a.push(b); 
                return a;
            }, []);
            return { ...state, links: links};
        default :
            return state;
    }
}

export default breadcrumbReducer;

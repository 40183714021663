/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Geocode from 'react-geocode';
import _ from 'lodash';
import { AutoComplete } from 'antd';
import { Col, Row, Input, FormItem, Select, Option } from './AntdUiComponents';
import { isEmpty } from './Common';

Geocode.setApiKey('AIzaSyCNNmfTGsBatXy77JEAcjxuHCR2WSxVhvg');
Geocode.enableDebug();
let postalCode = '';
class AddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReqFields: true,
      address: props.address,
      zipcodeError: false,
      autoAddress: '',
      disabled: props.disabled,
      defaultValue: '',
      hideFields: props.hideFields != null,
      placeHolder: props.placeHolder? props.placeHolder : 'Search address here...',
      // defaultValue: props.address,
    };
    this.getSuggestions = _.debounce(this.getSuggestions, 500);
  }

  renderAddressLabel = attr => `general.${attr}`;

  handleSelect = () => { };

  getAddress = obj => {
    const address = obj.description;
    this.setState({ defaultValue: address });
    const output = {
      country: '',
      state: '',
      city: '',
      address_line2: '',
      address_line1: '',
      lat: '',
      lng: '',
      zipcode: '',
    };

    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        Geocode.fromLatLng(latLng.lat, latLng.lng).then(
          response => {
            const Zipaddress = response.results[0].formatted_address;
            const splicedZipaddress = Zipaddress.split(',').splice(-2);
            postalCode = splicedZipaddress[0].split(' ').splice(-1);

            this.formatAddress(address, output, latLng);
          },
          // eslint-disable-next-line no-unused-vars
          error => { }
        );
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => { });
  };

  formatAddress = (address, outputs, latLng) => {
    let addressArray = address.split(',');
    const output = outputs;
    const address_line1 = addressArray.splice(0, 1).join(', ');
    addressArray = addressArray.reverse();
    // eslint-disable-next-line no-plusplus
    console.log('AddressArray:', addressArray);
    for (let i = 0; i < addressArray.length; i++) {
      output[Object.keys(output)[i]] = addressArray[i];
    }

    output.address_line1 =
    addressArray.length > 4 ?
      `${address_line1}, ${addressArray.slice(4).reverse().join(', ')}` :
      address_line1;

    output.lat = latLng.lat;
    output.lng = latLng.lng;
    output.zipcode = postalCode.toString();
    this.handleOnAddressChange('', '', output);
  };

  componentWillMount() {
    this.getInitAddress();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ address: nextProps.address }, () => {
      if (Object.keys(nextProps.address).length > 0) {
        this.setState({
          defaultValue: this.addressObjectToString(nextProps.address),
        });
      } else {
        this.setState({ defaultValue: '' });
      }
    });
  }

  onSelect = (id, suggestions) => {
    const address = suggestions.filter(x => x.placeId === id);
    if (address.length) {
      this.getAddress(address[0]);
    }
  };

  getSuggestions = value => {
    geocodeByAddress(value)
      .then(results => getLatLng(results[0]))
      .then(() => this.handleOnAddressChange('latLng', value))
      .catch(error => console.error('Error', error));
  };

  handleChange = autoAddress => {
    this.setState({ autoAddress, defaultValue: autoAddress }, () => {
      if (autoAddress === '') this.handleOnAddressChange('', '', '');
    });
  };

  getInitAddress = () => {
    this.setState({ address: this.props.address });
    this.setState({ address: this.props.address }, () => {
      if (Object.keys(this.props.address).length > 0) {
        this.setState({
          defaultValue: this.addressObjectToString(this.props.address),
        });
      } else {
        this.setState({ defaultValue: '' });
      }
    });
  };

  handleOnAddressChange(element, value, obj) {
    this.props.onChange(element, value, obj, this.state.defaultValue);
  }

  addressObjectToString = address => {
    let addressText = '';
    if (Object.keys(address).length > 0) {
      const displayAddress = (({
        address_line1,
        address_line2,
        city,
        state,
        country,
        zipcode,
      }) => ({
        address_line1,
        address_line2,
        city,
        state,
        country,
        zipcode,
      }))(address);

      const filtered = Object.values(displayAddress).filter(
        x => x !== '' && x !== null
      );
      addressText = filtered.join(', ');
    } else {
      addressText = '';
    }
    return addressText;
  };

  setDefaultValue = () => { };

  onSearch = val => {
    console.log('search:', val);
  };

  setAddressFields = (element, value) => {
    const addressdata = this.state.address ? this.state.address : {};
    addressdata[element] = value;
    this.setState({ address: addressdata }, () => {
      this.handleOnAddressChange(element, value, this.state.address);
    });
  };

  render() {
    const { address, disabled, showReqFields, hideFields, placeHolder } = this.state;
    const { addressType, showError } = this.props;
    let errors = this.props.errors; 
    errors = errors && errors.length > 0 && errors[0];
    return (
      <div>
        <PlacesAutocomplete
          value={this.state.autoAddress}
          onChange={this.handleChange}
          onClick={this.handleChange}
          onSelect={this.handleSelect}
          debounce={1000}
          setDefaultValue={this.setDefaultValue}>
          {({ getInputProps, suggestions }) => (
            <div>
              {this.props.autoComplete ? (
                <Row>
                  <Col xs={24} className={this.state.zipcodeError ? "errorAddress" :" "}>
                    <AutoComplete
                      style={{ width: '100%', marginBottom: 20}}
                      disabled={this.props.disabled}
                      placeholder={placeHolder}
                      dataSource={suggestions.map(item => ({
                        value: item.placeId,
                        text: item.description,
                      }))}
                      onSelect={value => this.onSelect(value, suggestions)}
                      onBlur={this.props.onFocus}
                      onSearch={value =>
                        getInputProps().onChange({
                          target: {
                            value,
                          },
                        })
                      }
                      onChange={value =>
                        getInputProps().onChange({
                          target: {
                            value,
                          },
                        })
                      }
                      value={this.state.defaultValue}
                    />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </div>
          )}
        </PlacesAutocomplete>
        {(this.state.defaultValue !== '' && !hideFields) && (
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12}>
              <FormItem label='Address1' require={showReqFields}>
                <Input
                  className={
                    showError && errors.search('Address_line1') >= 0
                      ? 'input-err'
                      : ''
                  }
                  value={address && !isEmpty(address.address_line1) ? address.address_line1.trim() : ''}
                  disabled={this.props.disabled}
                  onChange={e =>
                    this.setAddressFields('address_line1', e.target.value)
                  }
                  onBlur={e =>
                    this.setAddressFields('address_line1', e.target.value)
                  }
                />
              </FormItem>
              {showError && errors.search('Address_line1') >= 0 && (
                <span className='errorMessageAddress'>
                  Address1 is required in {addressType} address
                </span>
              )}
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <FormItem label='Address2' require={false}>
                <Input
                  value={address && !isEmpty(address.address_line2) ? address.address_line2.trim() : ''}
                  disabled={this.props.disabled}
                  onChange={e =>
                    this.setAddressFields('address_line2', e.target.value)
                  }
                />
              </FormItem>
            </Col>
          </Row>
        )}
        {(this.state.defaultValue !== '' && !hideFields) && (
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12}>
              <FormItem label='City' require={showReqFields}>
                <Input
                  className={
                    showError && errors.search('City') >= 0 ? 'input-err' : ''
                  }
                  value={address && !isEmpty(address.city) ? address.city.trim() : ''}
                  disabled={this.props.disabled}
                  onChange={e => this.setAddressFields('city', e.target.value)}
                  onBlur={e => this.setAddressFields('city', e.target.value)}
                />
              </FormItem>
              {showError && errors.search('City') >= 0 && (
                <span className='errorMessageAddress'>
                  City is required in {addressType} address
                </span>
              )}
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <FormItem label='State' require={showReqFields}>
                <Input
                  className={
                    showError && errors.search('State') >= 0 ? 'input-err' : ''
                  }
                  value={address && !isEmpty(address.state) ? address.state.trim() : ''}
                  disabled={this.props.disabled}
                  onChange={e => this.setAddressFields('state', e.target.value)}
                  onBlur={e => this.setAddressFields('state', e.target.value)}
                />
              </FormItem>
              {showError && errors.search('State') >= 0 && (
                <span className='errorMessageAddress'>
                  State is required in {addressType} address
                </span>
              )}
            </Col>
          </Row>
        )}
        {(this.state.defaultValue !== '' && !hideFields) && (
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12} lg={12}>
              <FormItem label='Country' require={showReqFields}>
                <Input
                  className={
                    showError && errors.search('Country') >= 0
                      ? 'input-err'
                      : ''
                  }
                  value={address && !isEmpty(address.country) ? address.country.trim() : ''}
                  disabled={this.props.disabled}
                  onChange={e =>
                    this.setAddressFields('country', e.target.value)
                  }
                  onBlur={e => this.setAddressFields('country', e.target.value)}
                />
              </FormItem>
              {showError && errors.search('Country') >= 0 && (
                <span className='errorMessageAddress'>
                  Country is required in {addressType} address
                </span>
              )}
            </Col>
            <Col sm={24} xs={24} md={12} lg={12}>
              <FormItem label='Zip Code' require={showReqFields}>
                <Input
                  className={
                    this.state.zipcodeError || (showError && errors.search('Zipcode') >= 0)
                      ? 'input-err'
                      : ''
                  }
                  value={address && !isEmpty(address.zipcode) ? address.zipcode.trim() : ''}
                  min='0'
                  onChange={e => {
                    this.setAddressFields('zipcode', e.target.value);
                    this.setState({
                      zipcodeError: /^\d{5}$/.test(e.target.value) === false,
                    });
                  }}
                  onBlur={e => this.setAddressFields('zipcode', e.target.value)}
                  disabled={this.props.disabled}
                  type={
                    address && address.zipcode === 'N.L.' ? 'text' : 'number'
                  }
                />
              </FormItem>
              {!this.state.zipcodeError &&
                showError &&
                errors.search('Zipcode') >= 0 && (
                  <span className='errorMessageAddress'>
                    Zip code is required in {addressType} address
                  </span>
                )}
              {this.state.zipcodeError && (
                <span className='errorMessageAddress'>
                  Zipcode length should be 5
                </span>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

AddressForm.defaultProps = {
  address: {},
  disabled: false,
  autoComplete: false,
  twoRows: false,
};

export default AddressForm;

import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Table,
  Icon,
  Form,
  Button
} from '../../common/AntdUiComponents';
import { alertMessage } from '../../common/Common';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import TollForm from './TollForm';
import {validateAddressForm} from '../../common/Common';
import { handleBreadcrumbLinks } from '../../reducer/actions/actions';
import { useDispatch } from 'react-redux';

const roles = ['User', 'Admin'];
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const List = ({
  onSelectTitle,
  data,
  pagination,
  tableChange,
  createToll,
  showModal,
  stateCLear,
  disableSubmitBtn,
}) => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [form] = Form.useForm();
  const [address, setAddress] = useState('');
  const [addressType,setAddressType] = useState('')
  const [addressObj,setAddressObj] = useState({})
  const [validateElements, setValidateElements] = useState([]);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    tollAuthority: '',
    email: '',
    toll: '',
    telephone: '',
    address: '',
    website:'',
    role: '',
    name:'',
    pref:'http://',
    suff:".com"
  });
  const columns = [
    {
      title: 'Name',
      dataIndex: 'tollAuthority',
      key: 'tollAuthority',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: () => (
        <span className='textFields'>
          <Icon type='arrow-right' style={{ fontSize: '30px' }} />
        </span>
      ),
      align: 'right',
    },
  ];

  useEffect(() => {
    var links = ['Overview', 'Toll Authorities'];
    dispatch(handleBreadcrumbLinks(links));
    setShowViewModal(showModal);
    setDisableBtn(disableSubmitBtn);
    if (stateCLear) {
      setState({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, stateCLear, disableSubmitBtn]);

  const handleTitleChange = (name, id) => {
    onSelectTitle(name, 'category', id);
  };

  const handleRoleChange = (e, value) => {
    setState({
      ...state,
      [e]: value,
    });
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    console.log(element,obj)
    setAddressType(addressType)
    setAddressObj(obj)
    console.log(obj)
    console.log(validateElements)

      const arr = [...new Set([...element])];
    setValidateElements(arr);
    console.log(arr)
    const addrressError = validateAddressForm(arr, obj);
    console.log(addrressError)
    if(addrressError){
      const errors = [];
      errors.push(`${addrressError}`);
      setErrors(errors)
      showError(true)
    }else{
      showError(false)
    }
    setState({
      ...state,
      [addressType]: obj,
      // [addressObj]:obj
    });
  };

  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const submitForm = e => {
    e.preventDefault();
    form.setFieldsValue({
      email: '',
      tollAuthority:'',
      role:''
    });
    if (state.tollAuthority.trim().length!==0) {
    if (re.test(String(state.email).toLowerCase())) {
      if (state.telephone.length >= 11 || state.telephone === '') {
        createToll(true, state);
      } else {
        alertMessage('PLEASE ENTER A CORRECT NUMBER', 'error', 3);
      }
    } else {
      alertMessage('PLEASE PROVIDE A VALID EMAIL ADDRESS', 'error', 3);
    }
  }else{
    alertMessage('PLEASE PROVIDE A VALID NAME', 'error', 3);
  }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisableBtn(false);
    setState({
      tollAuthority: '',
      email: '',
      toll: '',
      telephone: '',
      address: '',
      website:'',
      role: '',
      name:'',
      suff:'.com',
      pref:"http://"
    });
    setShowViewModal(true);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
    showError(false)
  };

  const onFocus = (event, newAddress) => {
    const validateFields = [
      'address_line1',
      'city',
      'state',
      'country',
      'zipcode',
    ];
    if (newAddress === '' || newAddress === undefined) {
      if (addressObj !== '') {
        addressObj.address_line1 =
        addressObj.address_line1 !== undefined ? addressObj.address_line1 : '';
        addressObj.address_line2 =
        addressObj.address_line2 !== undefined ? addressObj.address_line2 : '';
      }
      const addrressError = validateAddressForm(validateFields, addressObj);
      if (addrressError) {
        const errors = [];
        errors.push(`${addrressError}`);
        setErrors(errors);
        showError(true);
      } else {
        showError(false);
      }
      handleOnAddressChange('', validateFields, '', addressObj);
    } else {
      if (newAddress !== '') {
        newAddress.address_line1 =
          newAddress.address_line1 !== undefined
            ? newAddress.address_line1
            : '';
        newAddress.address_line2 =
          newAddress.address_line2 !== undefined
            ? newAddress.address_line2
            : '';
      }
      const addrressError = validateAddressForm(validateFields, newAddress);
      if (addrressError) {
        const errors = [];
        errors.push(`${addrressError}`);
        setErrors(errors);
        showError(true);
      } else {
        showError(false);
      }
    }
  }

  const renderViewModel = () => {
    return (
      <BaseModal width='70%' onCancel={() => handleOnModalClose()}>
        <TollForm
          handleChange={handleChange}
          handleOnAddressChange={handleOnAddressChange}
          handleRoleChange={handleRoleChange}
          disableBtn={disableBtn}
          submitForm={submitForm}
          errors={errors}
          error={error}
          state={state}
          roles={roles}
          address={addressObj}
          onFocus={onFocus}
          hideCreate={false}
          disableFields={false}
          isEditForm={false}
        />
      </BaseModal>
    );
  };

  return (
    <div className='Basecontainer'>
      <Row gutter={30}>
        <Col span={24} className='tablePadding  mar-top-20'>
          <p className='TitleText mar-bottom-20'>
            <span> Manage toll authority accounts here.</span>
          </p>
          <p className='TitleText mar-bottom-20 addTollAuthBtn'>
            <Button
              className='AddButton'
              onClick={e => {
                handleSubmit(e);
              }}>
              ADD TOLL AUTHORITY
            </Button>
          </p>
          <Table
            className='BaseTable userCategories'
            dataSource={data}
            columns={columns}
            pagination={pagination}
            onChange={tableChange}
            onRow={record => {
              return {
                onClick: () => {
                  handleTitleChange(record.tollAuthority, record.id);
                },
              };
            }}
            showHeader={false}
          />
        </Col>
      </Row>
      {showViewModal && renderViewModel()}
    </div>
  );
};

export default List;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import BaseModal from '../../utils/reusableComponents/BaseModal';
import {
  Row,
  Col,
  Table,
  Icon,
  Button,
  Upload,
  message,
} from '../../common/AntdUiComponents';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { handleBreadcrumbLinks } from '../../reducer/actions/actions';
import { uploadTollFacilitiesCSV, downloadTollFacilitiesSampleFile } from '../../api/tollFacillities';
import { useDispatch } from 'react-redux';

let fileObj = {};
let files = [];

const TollFacities = ({ onSelectTitle, data }) => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [fileName, setfileName] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const dispatch = useDispatch();
  const columns = [
    {
      title: 'Name',
      dataIndex: 'tollFacility',
      key: 'tollFacility',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, data) => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon type="arrow-right" style={{ fontSize: '30px' }} />
        </span>
      ),
      align: 'right',
    },
  ];

  useEffect(() => {
    var links = ['Overview', 'Toll Authorities', 'Facilities'];
    dispatch(handleBreadcrumbLinks(links));
  }, [])

  const handleTitleChange = (facility) => {
    onSelectTitle(facility);
  };

  const addTollFacility = (e) => {
    e.preventDefault();
    setShowViewModal(true);
  };

  const handleOnModalClose = () => {
    setShowViewModal(false);
    setfileName('')
  };

  const onChange = (info) => {
    if (info.file.status !== 'uploading') {
      setfileName(info.file.name);
      fileObj = info.fileList[0].originFileObj;
      files = info.fileList;
    }
  };

  const uploadTollFacilities = () => {
    uploadTollFacilitiesCSV(csvFile).then((result) => {
      if (result.status == 'SUCCESS') {
        message.success({
          content: `FACILITIES SUCCESFULLY SAVED!`,
          className: 'bg-green',
          duration: 5,
        });
      } else {
        setErrorCode(result.code);
        setShowViewModal(false);
        setShowErrorModal(true);
      }
      // refreshPlazasData(tollFacility);
    });
    setCsvFile(null);
    setShowViewModal(false);
  }

  const renderViewModel = () => {
    return (
      <BaseModal
        width="550px"
        onCancel={() => handleOnModalClose()}
        style={{ textAlign: 'center' }}
      >
        <Col lg={24} className="uplaodForm">
          <Upload
            showUploadList={false}
            onChange={onChange}
            beforeUpload={(file) => {
              const reader = new FileReader();
              reader.onload = (e) => {};
              reader.readAsText(file);
              setCsvFile(file);
              return false;
            }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
            <Col lg={24} className="password">
              UPLOAD EXCEL DOC
            </Col>
            <ReuseInputField
              type="text"
              placeholder=""
              height="52px"
              width="59%"
              value={fileName}
            />
            <Button text="BROWSE" className="center1auth uploadFacility">
              BROWSE
            </Button>
          </Upload>

          <Col lg={24} className="uploadContainer">
            <ReuseButton text="DOWNLOAD SAMPLE" className="center2upload" onClick={downloadTollFacilitiesSampleFile}/>
            <ReuseButton text="UPLOAD" className="center1upload" disabled={!fileName} onClick={uploadTollFacilities}/>
          </Col>
        </Col>
      </BaseModal>
    );
  };

  const errorModal = () => {
    return (
      <BaseModal
        width="550px"
        onCancel={() => setShowErrorModal(false)}
        style={{ textAlign: 'center' }}
      >
        {errorCode == 'BLUEDOT_ERROR' && (
          <>
            <h3 style={{ color: 'red' }}>Bluedot Sync Failure</h3>
            <h4>Some data returned error from Bluedot. 
              Check Bluedot Store if all the uploaded facilities has been saved.
              Please make sure all excel data are complete and correct before uploading again.
            </h4>
          </>
        )}
        {errorCode == 'FILE_INCOMPLETE' && (
          <>
            <h3 style={{ color: 'red' }}>Excel File Error</h3>
            <h4>
              Some are either incorrect or incomplete. 
              A file has been downloaded for your reference of the specific error.
            </h4>
          </>
        )}    
      </BaseModal>
    );
  };

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding  mar-top-20">
          <p className="TitleText mar-bottom-20">
            Here are the facilities asscoiated with this toll authority.
          </p>
          <p className="TitleText mar-bottom-20 addTollFacBtn">
            <Button className="AddButton tollfP" onClick={addTollFacility}>
              ADD TOLL FACILITY
            </Button>
          </p>

          <Table
            className="BaseTable userCategories"
            dataSource={data}
            columns={columns}
            showHeader={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleTitleChange(record);
                },
              };
            }}
          />
        </Col>
      </Row>
      {showViewModal && renderViewModel()}
      {showErrorModal && errorModal()}
    </div>
  );
};

export default TollFacities;

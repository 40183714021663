import React from 'react';
import { Row, Col, Card } from '../../common/AntdUiComponents';

const OverviewCard = ({ baseicon, count, category }) => {
  return (
    <Card className="OverviewCard">
      <Row gutter={16}>
        <Col xs={7} className="mar-top-8per">
          <img src={baseicon} width="47" alt="UNYTAG" />
        </Col>
        <Col xs={17}>
          <Row>
            <Col className="OverviewCount">{count}</Col>
          </Row>
          <Row>
            <Col className="Overviewcategory">{category}</Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default OverviewCard;

import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import * as qs from 'query-string';
import { Row, Col, Card, Spin, Modal, Button } from '../common/AntdUiComponents';
import OverviewCard from '../utils/reusableComponents/OverviewCard';
import reject from '../images/reject.png';
import Group from '../images/Group.png';
import loadingImg from '../images/loading.png';
import graph from '../images/graph.png';
import exclamation from '../images/exclamation.png';
import { getDashboardsListApi } from '../api/user';
import { alertMessage } from '../common/Common';
import { fetchEnterpriseUsers } from '../api/enterprise/enterpriseUser';
import { useSelector, useDispatch } from 'react-redux';
import { 
  fetchNotifications,
  markNotificationAsRead,
  deleteNotification
} from '../api/user';
import { handleNotification, handleNotifCount } from '../reducer/actions/actions';
import { handleBreadcrumbLinks } from '../reducer/actions/actions';

const Overview = () => {
  const [defectiveTags, setDefectiveTags] = useState(0);
  const [orderedTags, setOrderedTags] = useState(0);
  const [paymentIssues, setPaymentIssues] = useState(0);
  const [transactions, setTransactions] = useState(0);
  const [users, setUsers] = useState(0);
  const [entUsers, setEntUsers] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem('user');
  const userDetails = user !== '' ? JSON.parse(user) : {};
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search);
  const notificationsFromWebSocket = useSelector(state => state.notifications.notifications);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [messageId, setMessageId] = useState(null);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  const [disableLoadMoreMessageBtn, setDisableLoadMoreMessageBtn] = useState(false);

  const getDashboardsList = () => {
    setLoading(true);
    getDashboardsListApi().then(result => {
      if (result.success) {
        setLoading(false);
        setDefectiveTags(result.data.defectiveTags);
        setOrderedTags(result.data.orderedTags);
        setPaymentIssues(result.data.paymentIssues);
        setTransactions(result.data.transactions);
        setUsers(result.data.users);
      } else {
        setLoading(false);
        // alertMessage('SOMETHING WENT WRONG', 'error', 3);
      }
    });
  };

  const getUsers = () => {
    fetchEnterpriseUsers(userDetails.company, 1, 10000).then(result => {
      if (result.success) {
        const pagination = result.pagination;
        setEntUsers(pagination.totalCount);
      } else {
      }
    });
  };

  const checkIfLoggedIn = () => {
    if (parsed.fromemail == 'true') {
      localStorage.clear();
      window.location.reload();
    } else {
      getDashboardsList();
      if (userDetails.userType === 'Enterprise') {
        getUsers();
      }
    }
  };

  useEffect(() => {
    dispatch(handleBreadcrumbLinks([]));
    checkIfLoggedIn();
    getNotifications(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotifications = (clearMessages) => {
    fetchNotifications(userDetails.id, page, size).then(result => {
      if (result.success) {
        dispatch(handleNotification(result.data.notifications, clearMessages));
        dispatch(handleNotifCount(result.data.messageCount, 0));
        setPage(page + 1);
        if (result.data.notifications.length < size) {
          setDisableLoadMoreMessageBtn(true);
        }
      } else {
        alertMessage(result.errors.message.message, 'error', 3);
      }
    });
  }

  const readMessage = (notif) => {
    setVisible(true);
    setTitle(notif.title);
    setMessage(notif.message);
    setMessageId(notif.id);
    if (!notif.touched) {
      markNotificationAsRead(notif.id).then(result => {
        if (result.success) {
          const messages = Array.from(notificationsFromWebSocket);
          messages.forEach((msg) => {
            if (msg.id == notif.id) {
              msg.touched = true;
            }
          });
          dispatch(handleNotification(messages, false));
          dispatch(handleNotifCount(messages.length, -1));
        } 
      });
    }
  };

  const getredirectUrl = (userDetails) => {
    if (userDetails.userType === 'UnyTag') {
      return '/unytag/web/users'
    }
    else if (userDetails.userType === 'Toll Authority') {
      return `/unytag/web/users/${userDetails.company}`
    }
    else {
      return `/unytag/web/accounts/${userDetails.company}/${userDetails.companyId}`
    }
  }

  const renderNotifications = () => {
    return notificationsFromWebSocket.map( notif => 
      <>
      <div>
        <div className='message-row' style={{ backgroundColor: notif.touched === true ? 'white' : '#cfe2ff' }}
          xs={16}
          onClick={() => readMessage(notif)}>
          <Row xs={16}>
            <span style={{fontWeight: 'bold', fontSize: 12}}>
            {notif.title}
            </span>
          </Row>
          <Row xs={8}>
            {' '}
            <p className='message-content'>
              {notif.message}
            </p>
          </Row>
          <Row xs={8}>
            <p className='message-date'>
              {moment(notif.createdAt).fromNow()}
            </p>
          </Row>
          <br/>
        </div>
        </div>
      </>
    )
  }

  const toggleModal = () => {
    setVisible(!visible);
  };

  const renderModal = () => {
    return (
      <Modal
        className='toll-authority'
        title={[
          <h3 style={{textAlign: 'left'}}>{title}</h3>
        ]}
        visible={visible}
        width='700px'
        onOk={toggleModal}
        onCancel={() => {
          toggleModal();
        }}
        footer={[
          <Button key="submit" type="danger" onClick={toggleDeleteModal}>
           Delete
          </Button>,
          <Button key="submit" type="primary" onClick={toggleModal}>
            OK
          </Button>
        ]}
        >
        <div className='modalDescrition'>
          <p>{message}</p>
        </div>
      </Modal>
    );
  };

  const toggleDeleteModal = () => {
    setDeleteVisible(!deleteVisible);
  };

  const renderDeleteModal = () => {
    return (
      <Modal
        className='toll-authority'
        title={[
          <h3 style={{textAlign: 'center'}}>Alert!</h3>
        ]}
        visible={visible}
        width='400px'
        height='200px'
        onOk={toggleDeleteModal}
        onCancel={() => {
          toggleDeleteModal();
        }}
        footer={null}
        >
        <Row justify='center' type='flex'>
          <p>Are you sure you want to delete this message?</p>
        </Row>
        <Row justify='center' type='flex'>
          <Button key="submit" style={{margin: 5}} color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
          <Button key="submit" style={{margin: 5}} type="danger" onClick={deleteMessage}>
            Delete
          </Button>
        </Row>
      </Modal>
    );
  };

  const deleteMessage = () => {
    deleteNotification(messageId).then(result => {
      if (result.success) {
        const messages = notificationsFromWebSocket.filter((msg) => msg.id != messageId);
        const messagesForUpdate = Array.from(messages);
        dispatch(handleNotification(messagesForUpdate, true));
      } 
    });
    toggleModal();
    toggleDeleteModal();
  }

  return (
    <div>
      <Spin spinning={loading}>
        <p className='ContainerTitleHeader'>
          <span className='PageTitle'>
            Overview ({moment().format('MM/DD/YYYY')})
          </span>
        </p>
        <Row gutter={48} className='overviewBox'>
          <Col md={9}>
            <div className='cardWith'>
              <Link to='/unytag/web/allTransactions'>
                <OverviewCard
                  baseicon={Group}
                  count={transactions}
                  category='ALL TRANSACTIONS'
                />
              </Link>
            </div>
          </Col>
          <Col md={9}>
            <div className='cardWith'>
              <Link to={{pathname: '/unytag/web/orderedtags', state: {prevScreen: "overview" }}}>
                <OverviewCard
                  baseicon={loadingImg}
                  count={orderedTags}
                  category='ORDERED TAGS'
                />
              </Link>
            </div>
          </Col>
          <Col md={6} className='textRightAll'>
            <div className='cardWith'>
              <Link
                to={
                  userDetails && getredirectUrl(userDetails)
                }>
                <OverviewCard
                  baseicon={graph}
                  count={
                    userDetails && userDetails.userType === 'Enterprise'
                      ? entUsers
                      : users
                  }
                  category='USERS'
                />
              </Link>
            </div>
          </Col>
        </Row>
        <Row className='mar-top-30 overviewBox' gutter={48}>
          <Col md={9}>
            <Row>
              <Col xs={24}>
                <div className='cardWith'>
                  <Link to='/unytag/web/defectiveTags'>
                    <OverviewCard
                      baseicon={exclamation}
                      count={defectiveTags}
                      category='DEFECTIVE TAGS'
                    />
                  </Link>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='mar-top-30' xs={24}>
                <div className='cardWith'>
                  <Link to='/unytag/web/paymentIssues'>
                    <OverviewCard
                      baseicon={reject}
                      count={paymentIssues}
                      category='ISSUES WITH PAYMENT'
                    />
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={15}>
            <p className='OverviewcardTitle' style={{ height: 50, backgroundColor: '#fff', marginBottom: 0}}>Notification Center</p>
            <Card className='OverviewStatusCard' style={{overflow:'hidden', overflowY: 'scroll', width: '100%'}}>
                {renderNotifications()}
                {!disableLoadMoreMessageBtn && (
                  <Row style={{justifyContent: 'center', margin: 15}}>
                  <Button key="submit" type="primary" onClick={() => getNotifications(false)}>
                    Load More
                  </Button>
                </Row>
                )}
            </Card>
          </Col>
        </Row>
      </Spin>
      {visible && renderModal()}
      {deleteVisible && renderDeleteModal()}
    </div>
  );
};

export default Overview;

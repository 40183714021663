import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { Row, Col, Form, Spin, Select } from 'antd';
import logo1 from '../../images/Unytaglogo.png';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import ReuseButton from '../../utils/reusableComponents/ReuseButton';
import { forgotPassword ,userLogin} from '../../api/login';
import { mapValues } from 'lodash';

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ForgetPassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      inProgress: false,
      disableBtn: false,
      showError: false,
      errorMessage: '',
      default: false,
      user: {},
      users: [],
    };
  }

  componentDidMount() {}

  handleChange = key => value => {
    this.setState({ [key]: value });
    const formData = {};
    formData.email = value
    userLogin(formData).then(result => {
      if (result.success) {
        if (result.data.length > 0) {
          this.setState({ users: result.data }, () => {
            if (this.state.users.length >0) {

              this.setState(
                {
                  // eslint-disable-next-line react/no-access-state-in-setstate
                  user: this.state.users[0],
                  default: true,
                  errorMessage: '',
                  showError: false,
                },
              );
            }
          });
        } else {
          this.setState(
            {
              users: [],
              errorMessage: 'Please enter a registered email',
              showError: true,
            },
          );
        }
      } else {
        this.setState({
          users: [],
        });
      }
    });
  };

  handleSubmit = () => {
    const { history } = this.props;
    this.setState({ inProgress: true });
    const formData = {};
    formData.email = this.state.email;
    forgotPassword(formData).then(result => {
      if (result.success) {
        this.setState({ inProgress: false, disableBtn: true });
        swal({
          title: 'Success!',
          text: ` We've sent an email to you.
            Click the link in the email to reset your password.`,
          icon: 'success',
          button: 'OK',
        }).then(() => {
          history.push('/unytag/web');
        });
      } else {
        this.setState({ inProgress: false, disableBtn: false });
        swal('Oops!', result.errors.message.message, 'error');
      }
    });
  };

  render() {
    const { email, inProgress } = this.state;
    return (
      <div className='LoginLayout'>
        <Spin spinning={inProgress}>
          <div className='LoginContentSize'>
            <Row className='forgetContent'>
              <Col lg={3} className='logofPass'>
                <Link to='/unytag/web'>
                  <img
                    src={logo1}
                    alt='logo'
                    style={{ height: '18px', width: '30px' }}
                  />
                </Link>
              </Col>
              <Col lg={14} style={{ margin: 'auto auto', textAlign: 'center' }}>
                <Col lg={15} offset={6} className='ftPassword'>
                  <p className='forgotNote'>Forgot your password?</p>
                  <p className='forgothelpText'>
                    We’ll help you reset it and get back on track.
                  </p>

                  <Form onSubmit={this.handleSubmit} layout='vertical' ref={this.formRef}>

                    <Form.Item
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Please provide your email address',
                        },
                        {
                          type: 'email',
                          message: 'Please provide a valid email address',
                          validationTrigger: 'onBlur',
                        },
                      ]}
                      label='WORK EMAIL ADDRESS'
                      className='authlabels'>
                      <ReuseInputField
                        type='text'
                        className={`${this.state.showError?'textboxErrror':''}`}
                        placeholder='john@mainstreet.com'
                        height='52px'
                        value={email}
                        onChange={this.handleChange('email')}
                      />
                    </Form.Item>

                    {this.state.showError &&
                    re.test(String(email).toLowerCase()) && (
                      <p className='errorMessageFor'>
                        <span>{this.state.errorMessage}</span>
                      </p>
                    )}
                    <br />
                    <ReuseButton
                      text='RESET PASSWORD'
                      disabled={
                        email === '' ||
                        !re.test(
                          String(email).toLowerCase()
                        ) || this.state.disableBtn || this.state.errorMessage
                      }
                      htmlType='submit'
                      className='center1auth'
                      onClick={this.handleSubmit}
                    />
                  </Form>
                </Col>
                <Col lg={6} />
              </Col>
              <Col lg={5} />
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}

export default withRouter(ForgetPassword);

import Enterprises from '../containers/Enterprises';
import UserAccounts from '../components/enterprises/EnterpriseInfo';
import EnterpriseInfo from '../components/enterprises/EnterpriseInfo';
import EnterpriseUserForm from '../components/enterprises/EnterPriseUserForm';
import Overview from '../containers/Overview';
import Tags from '../containers/Tags';
import Users from '../containers/Users';
import UserInfo from '../components/users/UserInfo';
import TollAuthority from '../containers/TollAuthority';
import TollAuthorityInfo from '../components/tollAuthority/TollAuthorityInfo';
import OrderedTags from '../containers/OrderedTags';
import PaymentIssues from '../containers/PaymentIssues';
import AddUser from '../components/enterprises/AddUser';
import DefectiveTags from '../containers/DefectiveTags';
import AllTransactions from '../containers/AllTransactions';
import Support from '../containers/Support';
import Invite from '../containers/Invite';
import UsersList from '../components/tollAuthority/UsersList'
const AppRoutes = [
  {
    key: '000',
    exact: true,
    path: '/unytag/web/overview',
    name: 'Overview',
    component: Overview,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '001',
    exact: true,
    path: '/unytag/web/enterprise',
    name: 'Enterprises',
    component: Enterprises,
    access: ['unytag','enterprise'],
  },
  {
    key: '002',
    exact: true,
    path: '/unytag/web/tags',
    name: 'Tags',
    component: Tags,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '003',
    exact: true,
    path: '/unytag/web/users',
    name: 'Tags',
    component: Users,
    access: ['unytag'],
  },
  {
    key: '004',
    exact: true,
    path: '/unytag/web/',
    name: 'Overview',
    component: Overview,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '005',
    exact: true,
    path: '/unytag/web/tollauthority',
    name: 'Toll Authority',
    component: TollAuthority,
    access: ['unytag','toll authority'],
  },
  {
    key: '006',
    exact: true,
    path: '/unytag/web/orderedtags',
    name: 'Ordered Tags',
    component: OrderedTags,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '007',
    exact: true,
    path: '/unytag/web/tollauthority/category/:name/:id',
    name: 'Toll Authority',
    component: TollAuthorityInfo,
    access: ['unytag','toll authority'],
  },
  {
    key: '025',
    exact: true,
    path: '/unytag/web/tollauthority/:type/:name/:id',
    name: 'Toll Authority',
    component: TollAuthorityInfo,
    access: ['unytag','toll authority'],
  },
  {
    key: '026',
    exact: true,
    path: '/unytag/web/tollauthority/list/:type/:name/:id',
    name: 'Toll Authority',
    component: TollAuthorityInfo,
    access: ['unytag','toll authority'],
  },
  {
    key: '008',
    exact: true,
    path: '/unytag/web/enterprise/info/:name/:id',
    name: 'Toll Authority',
    component: EnterpriseInfo,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '021',
    exact: true,
    path: '/unytag/web/enterprise/:category/:name/:id',
    name: 'Toll Authority',
    component: EnterpriseInfo,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '024',
    exact: true,
    path: '/unytag/web/enterprise/:name/:id',
    name: 'Toll Authority',
    component: EnterpriseInfo,
    access: ['unytag', 'enterprise','toll authority'],
  },
  // {
  //   key: '023',
  //   exact: true,
  //   path: '/unytag/web/enterprise/profilesList/:name/:id',
  //   name: 'Profiles List',
  //   component: ProfilesList,
  //   access: ['unytag', 'enterprise'],
  // },
  {
    key: '009',
    exact: true,
    path: '/unytag/web/addEnterprise/:type',
    name: 'Add New User',
    component: AddUser,
    access: ['unytag', 'enterprise'],
  },
  {
    key: '010',
    exact: true,
    path: '/unytag/web/addEnterprise/:type/:name/:entName/:id',
    name: 'Add New User',
    component: EnterpriseUserForm,
    access: ['unytag', 'enterprise'],
  },
  {
    key: '011',
    exact: true,
    path: '/unytag/web/users/info/:name/:id',
    name: 'User Info',
    component: UserInfo,
    access: ['unytag', 'enterprise'],
  },
  {
    key: '020',
    exact: true,
    path: '/unytag/web/users/:category/:name/:id',
    name: 'Category List',
    component: UserInfo,
    access: ['unytag', 'enterprise'],
  },
  {
    key: '022',
    exact: true,
    path: '/unytag/web/paymentIssues',
    name: 'User Info',
    component: PaymentIssues,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '012',
    exact: true,
    path: '/unytag/web/defectiveTags',
    name: 'User DefectiveTags',
    component: DefectiveTags,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '013',
    exact: true,
    path: '/unytag/web/allTransactions',
    name: 'All Transactions',
    component: AllTransactions,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '014',
    exact: true,
    path: '/unytag/web/support',
    name: 'Support',
    component: Support,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '015',
    exact: true,
    path: '/invite',
    name: 'Invitation',
    component: Invite,
    access: ['unytag', 'enterprise','toll authority'],
  },
  {
    key: '016',
    exact: true,
    path: '/unytag/web/accounts/:name/:id',
    name: 'Enterprises',
    component: UserAccounts,
    access: ['enterprise', 'user'],
  },
  {
    key: '017',
    exact: true,
    path: '/unytag/web/enterprise/:name/:id/:payment',
    name: 'Toll Authority',
    component: EnterpriseInfo,
    access: ['unytag', 'enterprise'],
  },
  {
    key: '018',
    exact: true,
    path: '/unytag/web/users/:entName',
    name: 'Toll Authority Users',
    component: UsersList,
    access: ['unytag', 'enterprise'],
  },
  {
    key: '019',
    exact: true,
    path: '/unytag/web/orderedtags/:id',
    name: 'Ordered Tags',
    component: OrderedTags,
    access: ['unytag', 'enterprise','toll authority'],
  }
];
export default AppRoutes;

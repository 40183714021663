/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import UserCategory from './CategoryList';
import UserAccounts from './UserAccounts';
import TripHistory from '../../utils/reusableComponents/tripHistory/TripHistory';
import PaymentMethod from '../../utils/reusableComponents/payments/PaymentMethod';
import { Spin } from '../../common/AntdUiComponents';
import UserProfile from './UserProfile';
import { ConsoleSqlOutlined, PropertySafetyTwoTone } from '@ant-design/icons';
import createBrowserHistory from 'history/createBrowserHistory';

const UserInfo = () => {
  const params = useParams();
  const [title, setTitle] = useState(params.name);
  const [userId, setId] = useState(params.id);
  const [listVisible, setListVisible] = useState('category');
  const [inProgress] = useState(false);
  const [userData] = useState([]);
  const [searchField, setSearchField] = useState('');

  //const history = useHistory();
  const history = createBrowserHistory();
  useEffect(() => {  
    return history.listen(location => {
      if (history.action === 'POP') {
        setListVisible('category')
      }
    })
  }, [ listVisible, ])

  const handleTitle = (langValue, info, nextState, id) => {
    setTitle(langValue);
    setId(id);
    setListVisible(nextState);
    history.push(`/unytag/web/users/info/${langValue}/${id}`);
  };

  const handleSearch = value => {
    if (listVisible === 'category' || listVisible === 'Trip History & Statements') {
      setSearchField(value)
    }
  };
 
  return (
    <div>
      <Spin spinning={inProgress}>
        {listVisible !== 'Users' && listVisible !== 'Profile' && (
          <p className="ContainerTitleHeader">
            {listVisible === 'Trip History & Statements' ? (
              ''
            ) : listVisible === 'Payment Method' ? (
              <span className="PageTitle">Payment Method </span>
            ) : (
              listVisible !== 'Users' && (
                <span className="PageTitle">{title} </span>
              )
            )}
{listVisible !== 'Trip History & Statements' && <ReuseInputField placeholder="Search..." className="SearchBar"  onChange={handleSearch}/>}

          </p>
        )}
        {listVisible === 'category' ? (
          <UserCategory onSelectTitle={handleTitle} name={title} userId={userId} dataValue={searchField}/>
        ) : listVisible === 'Profile' ? (
          <UserProfile />
        ) : listVisible === 'userProfile' ? (
          ''
        ) : listVisible === 'Trip History & Statements' ? (
          <TripHistory type="user" searchField={searchField}/>
        ) : listVisible === 'Payment Method' ? (
          <PaymentMethod type='user'/>
        ) : (
          <UserAccounts userData={userData} name={title} />
        )}
      </Spin>
    </div>
  );
};

export default UserInfo;

import _ from 'lodash';
import { Call, ApiUrl, ParseGeneralResponse, PageParams } from './ApiUtils';
import AppConfig from '../config/AppConfig';
import axios from 'axios/index';

export function fetchTollFacilities(
  page = 1,
  perPage = AppConfig.perPage,
  name = '',
  id = '',
  order='',
  tollAuth=''
) {
  const url = ApiUrl(
    `/unytag/toll/facility?${PageParams(page, perPage)}&search=${encodeURIComponent(name)}&sortBy=${id}&sortOrder=${order}&tollAuthority=${tollAuth}`,
  );
  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { records: 'records', pagination: 'meta' },
  });
}

export function uploadTollFacilitiesCSV(formData) {
  const method = 'post';
  const url = ApiUrl('/unytag/bluedot/facilities/upload');
  const data= new FormData();
  data.append('csv', formData);
  const config= {
    "headers": {
      "content-type": 'multipart/form-data;'
    },
  }
  const dataObj = {
    method,
    url,
    data,
    config,
    responseType: 'blob', // important
  }
  const token = localStorage.getItem('token');
  dataObj.headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios(dataObj)
    .then((response) => {
      if (response.data.size > 100) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Failed_Facilities_Upload-${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        return {
          status: "ERROR",
          code: "FILE_INCOMPLETE",
        }
      } else {
        return {
          status: "SUCCESS",
          error: "OK"
        }
      }
    })
    .catch((error) => {
      return {
        status: "ERROR",
        code: "BLUEDOT_ERROR",
      }
    })
}

export function downloadTollFacilitiesSampleFile() {
  const method = 'get';
  const url = ApiUrl('/unytag/excel/download/sample/facilities');
  const data = {};
  const dataObj = {
    method,
    url, 
    data,
    responseType: 'blob', // important
  }
  const token = localStorage.getItem('token');
  dataObj.headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios(dataObj)
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Facilities_Download_Sample_File-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
}



/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReuseInputField from '../../utils/reusableComponents/ReuseInputField';
import TollAuthorityCategory from './CategoryList';
import TollAuthorityFacility from './TollFacilities';
import TollPlazas from './TollPlazas';
import Payment from './Payment';
import Profile from './Profile';
import { Spin } from '../../common/AntdUiComponents';
import { fetchTollFacilities } from '../../api/tollFacillities';
import { fetchTollPlazas } from '../../api/tollPlaza';
import { fetchTollAuthorityUsers } from '../../api/tollAuthority';
import createBrowserHistory from 'history/createBrowserHistory';
import { isEmpty } from '../../common/Common';
import { useSelector } from 'react-redux';
import { toLower } from 'lodash';

const TollAuthorityInfo = () => {
  const params = useParams();
  const history = createBrowserHistory();
  const [title, setTitle] = useState(params.name);
  const [id, setId] = useState(params.id);
  const [listVisible, setListVisible] = useState('category');
  const [searchField, setSearchField] = useState('');
  const [tollFacilityData, setTollFacilityData] = useState([{}]);
  const [tollPlazaData, setTollPlazaData] = useState([{}]);
  const [inProgress, setInProgress] = useState(false);
  const [pagination, setPagination] = useState({});
  const [perPage, setPerPage] = useState(20);
  const [usersData, setUsersData] = useState([]);
  const [search, setSearch] = useState('');
  const [tollFacility, setTollFacility] = useState({});
  const breadcrumbLinks = useSelector(state => state.breadcrumb_links.links);
  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  useEffect(() => {
    // getTollAuthUsers();
    if ((params.type === 'facilities' || isEmpty(params.type)) && listVisible !== 'plazas') {
      getTollFacilities('', perPage);
    }
    return history.listen(location => {
      if (history.action === 'POP') {
        window.location.reload(false);
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (breadcrumbLinks[breadcrumbLinks.length - 1] === 'Facilities') {
      setListVisible('facilities');
    }
  }, [breadcrumbLinks])

  const handleTitle = (langValue, nextState) => {
    if (nextState === 'facilities') {
      getTollFacilities('', perPage);
      localStorage.setItem('prevScreen', `/unytag/web/tollauthority/facilities/${langValue}/${id}`);
    }
    setTitle(langValue);
    setListVisible(nextState);
    if(langValue === 'Profile'){
      history.push(`/unytag/web/tollauthority/info/Profile/${id}`);
    } 
    else {
      history.push(`/unytag/web/tollauthority/facilities/${langValue}/${id}`); 
    }
  };

  // eslint-disable-next-line no-shadow
  const handleTableChange = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize);
    getTollFacilities(search, pagination.pageSize);
  };

  const getTollFacilities = (name, pageSizeCount) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;
    fetchTollFacilities(page, pageSizeCount, name, '', '', title).then(
      result => {
        if (result.success) {
          setPagination(result.pagination);
          if (result.records !== null) {
            setTollFacilityData(result.records);
          } else {
            setPagination({});
            setTollFacilityData([]);
          }
          setInProgress(false);
        } else {
          setTollFacilityData([]);
          setPagination({});
          setInProgress(false);
        }
      }
    );
  };

  const getTollAuthUsers = () => {
    fetchTollAuthorityUsers(1, 10000, params.name).then(result => {
      if (result.success) {
        setUsersData(result.records);
        setInProgress(false);
      } else {
        setUsersData([]);
        setInProgress(false);
      }
    });
  };


  const getTollPlazasByFacility = (facility, searchKey) => {
    setTitle(facility.tollFacility);
    setListVisible('plazas');
    setInProgress(true);
    setTollFacility(facility);
    const page = tableOptions.pagination.current;
    fetchTollPlazas(page, '', facility.id, '', '', searchKey).then(result => {
      if (result.success) {
        setPagination(result.pagination);
        if (result.records !== null) {
          setTollPlazaData(result.records);
        } else {
          setPagination({});
          setTollPlazaData([]);
        }
        setInProgress(false);
      } else {
        setTollPlazaData([]);
        setPagination({});
        setInProgress(false);
      }
    });
  };

  const handleTableChangeTollPlaza = (pagination, filters, sorter) => {
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize);
    getTollPlazasByFacility(tollFacility);
  };

  const handleSearch = value => {
    if (listVisible === 'category') {
      setSearchField(value);
    }
    else if(listVisible === 'plazas') {
      getTollPlazasByFacility(tollFacility, value);
    } else {
      getTollFacilities(value, perPage);
    }
  };

  return (
    <div>
      {title !== 'Profile' && (
        <p className='ContainerTitleHeader'>
          <span className='PageTitle'>
            {title !== 'Profile' && <span>{title}</span>}{' '}
            {(listVisible === 'plazas' || listVisible === 'facilities') && (
              <span>({pagination.totalCount})</span>
            )}
          </span>
          <ReuseInputField
            placeholder={
              listVisible === 'category'
                ? 'Search by title...'
                : listVisible === 'facilities' || listVisible === 'plazas'
                ? 'Search by name...'
                : ''
            }
            className='SearchBar'
            onChange={handleSearch}
          />
        </p>
      )}
      {listVisible === 'category' && params.type !== 'facilities' ? (
        <TollAuthorityCategory
          onSelectTitle={handleTitle}
          dataValue={searchField}
          recordName={title}
        />
      ) : listVisible === 'facilities' ? (
        title === 'Profile' ? (
          <Profile tollAdmins={usersData} />
        ) : (
          <TollAuthorityFacility
            onSelectTitle={getTollPlazasByFacility}
            inProgress={inProgress}
            pagination={
              pagination !== null
                ? {
                    total: parseInt(pagination.totalCount, 10),
                    current: parseInt(pagination.currentPage, 10),
                    pageSize: parseInt(pagination.perPage, 10),
                    pageSizeOptions: [5, 10, 20],
                  }
                : {}
            }
            // eslint-disable-next-line no-shadow
            tableChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            data={tollFacilityData}
          />
        )
      ) : !isEmpty(params.type) && params.type === 'facilities' && listVisible !== 'plazas'   ? (
        <TollAuthorityFacility
        onSelectTitle={getTollPlazasByFacility}
        inProgress={inProgress}
        pagination={
          pagination !== null
            ? {
                total: parseInt(pagination.totalCount, 10),
                current: parseInt(pagination.currentPage, 10),
                pageSize: parseInt(pagination.perPage, 10),
                pageSizeOptions: [5, 10, 20],
              }
            : {}
        }
        // eslint-disable-next-line no-shadow
        tableChange={(pagination, filters, sorter) =>
          handleTableChange(pagination, filters, sorter)
        }
        data={tollFacilityData}
      />
      ) : (<></>
      )}
      {listVisible === 'plazas' && (<TollPlazas
          tollFacility={tollFacility}
          inProgress={inProgress}
          refreshPlazasData={(fac) => getTollPlazasByFacility(fac)}
          pagination={
            pagination !== null
              ? {
                  total: parseInt(pagination.totalCount, 10),
                  current: parseInt(pagination.currentPage, 10),
                  pageSize: parseInt(pagination.perPage, 10),
                  pageSizeOptions: [5, 10, 20],
                }
              : {}
          }
          handleTitle={handleTitle}
          // eslint-disable-next-line no-shadow
          tableChange={(pagination, filters, sorter) =>
            handleTableChangeTollPlaza(pagination, filters, sorter)
          }
          plazaData={tollPlazaData}
        />)}
    </div>
  );
};

export default TollAuthorityInfo;

import { Call, ApiUrl, ParseGeneralResponse } from './ApiUtils';

export function userLogin(data) {
  let method;
  let url;
  let dataParameters;
  if (!data.id) {
    method = 'get';
    url = ApiUrl(`/unytag/login?email=${data.email===undefined?data:data.email}`);
    dataParameters = {};
  } else {
    method = 'post';
    url = ApiUrl('/unytag/login');
    dataParameters = data;
  }
  return Call(
    method,
    url,
    dataParameters,
    ParseGeneralResponse,
    { responseDataKeys: { data: '' } },
    false,
  );
}

export function forgotPassword(data) {
  const method = 'post';
  const url = ApiUrl('/unytag/user/forgotpassword');
  const dataParameters = data;
  return Call(
    method,
    url,
    dataParameters,
    ParseGeneralResponse,
    { responseDataKeys: { data: '' } },
    false,
  );
}

export function resetPassword(token, email, data) {
  const method = 'put';
  const url = ApiUrl(`/unytag/user/updatepassword?email=${email}&token=${token}`);
  const dataParameters = data;
  return Call(
    method,
    url,
    dataParameters,
    ParseGeneralResponse,
    { responseDataKeys: { data: '' } },
    false,
  );
}

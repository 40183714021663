/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Button, Form } from '../../common/AntdUiComponents';
import TollForm from './TollForm';
import {
  saveTollAuthority,
  fetchtollAuthorityById,
  fetchtollAuthorityUserById,
  saveTollAuthorityUser,
} from '../../api/tollAuthority';
import { alertMessage, validateAddressForm, isEmpty } from '../../common/Common';

const roles = ['User', 'Admin'];

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Profile = ({ tollAdmins }) => {
  const [inProgress, setInProgress] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [disableFields, setDisableFields] = useState(true);
  const [disableBtn, setDisableBtn] = useState(false);
  const [errors, setErrors] = useState([]);
  const [error, showError] = useState(false);
  const [tollUserId, setId] = useState('');
  const [form] = Form.useForm();
  const params = useParams();
  const [state, setState] = useState({
    name: '',
    email: '',
    toll: '',
    phone: '',
    address: '',
    role: '',
    pref: '',
    website: '',
    suff: '',
  });

  const getTollDetails = () => {
    fetchtollAuthorityById(params.id).then(result => {
      if (result.success) {
        let contactName = '';
        let phone = '';
        const { email } = result.record;
        const { tollAuthority } = result.record;
        const { telephone } = result.record;
        const { address1 } = result.record;
        const { address2 } = result.record;
        // eslint-disable-next-line no-shadow
        const { state } = result.record;

        const { city } = result.record;
        const { country } = result.record;
        const { zipCode } = result.record;
        const pref = !isEmpty(result.record.website) ? result.record.website.substring(
          0,
          result.record.website.indexOf('/')
        ) : '';
        const websiteUrl =
          !isEmpty(result.record.website)
            ? result.record.website.split('//')[1]
            : '';
        const websiteName =
          websiteUrl !== undefined && websiteUrl !== ''
            ? websiteUrl.split('.')[0]
            : '';
        const domain =
          websiteUrl !== undefined && websiteUrl !== ''
            ? websiteUrl.split('.')[1]
            : '';
        const address = {
          address_line1: address1,
          address_line2: address2,
          state,
          city,
          zipcode: zipCode,
          country,
        };
        const role = 'User';
        fetchtollAuthorityUserById(1, 10, result.record.tollAuthority).then(
          resultData => {
            if (resultData.success) {
              contactName = resultData.records[0].contactName;
              phone = resultData.records[0].phone;
              setId(resultData.records[0].id);
              const values = {
                name: contactName,
                email,
                tollAuthority,
                telephone,
                address,
                role,
                pref: pref + '//',
                website: websiteName,
                suff: '.' + domain,
                phone: phone,
              };
              setState(values);
            }
          }
        );
      }
    });
  };

  useEffect(() => {
    const isEdit = () => {
      if (params.name !== undefined) {
        setIsEditProfile(true);
      } else {
        setIsEditProfile(false);
      }
    };
    isEdit();
    getTollDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTollAdminChange = e => {
    const tollUserDetails = tollAdmins.find(user => user.contactName === e);
    console.log(tollUserDetails);
    setState({
      ...state,
      name: tollUserDetails.contactName,
      email: tollUserDetails.email,
      phone: tollUserDetails.phone,
      role: tollUserDetails.role,
    });
  };

  const handleRoleChange = (e, value) => {
    setState({
      ...state,
      [e]: value,
    });
  };

  const handleProfile = type => {
    setIsEditProfile(!isEditProfile);
    setDisableFields(!disableFields);
    if (type === 'save') {
      setDisableBtn(true);
      setInProgress(true);
      const formData = {};

      formData.address1 = state.address.address_line1.trim();
      formData.address2 = state.address.address_line2.trim();
      formData.city = state.address.city.trim();
      formData.country = state.address.country.trim();
      formData.email = state.email;
      formData.id = params.id;
      formData.state = state.address.state;
      formData.telephone = state.telephone;
      formData.tollAuthority = state.tollAuthority;
      formData.website = state.pref + state.website + state.suff;
      formData.zipCode = state.address.zipcode;

      if (re.test(String(state.email).toLowerCase())) {
          saveTollAuthority(false, formData, params.id)
            .then(result => {
              if (result.success) {
                const tollAuth = {};
                tollAuth.id = tollUserId;
                tollAuth.accountTypeId = 2;
                tollAuth.roleId = 9;
                tollAuth.country = formData.country;
                tollAuth.tollAuthority = result.data.tollAuthority;
                tollAuth.state = formData.state;
                tollAuth.email = formData.email;
                tollAuth.phone = state.phone;
                tollAuth.contactName = state.name;

                saveTollAuthorityUser(
                  false,
                  tollAuth,
                  tollUserId
                ).then(resultData => {
                  if (resultData.success) {
                    alertMessage('ALL CHANGES HAVE BEEN SAVED!');
                    setInProgress(false);
                  } else {
                    setDisableBtn(false);
                    alertMessage(
                      resultData.errors.message.message
                        .toString()
                        .toUpperCase(),
                      'error',
                      3
                    );
                    setInProgress(false);
                  }
                });
              } else {
                setDisableBtn(false);
                alertMessage(
                  result.errors.message.message.toString().toUpperCase(),
                  'error',
                  3
                );
                setInProgress(false);
              }
            })
            .catch(err => {
              console.log('err   ', err);
            });
      } else {
        setDisableBtn(false);
        setDisableFields(false);
        setIsEditProfile(false);
        alertMessage('PLEASE PROVIDE A VALID EMAIL ADDRESS', 'error', 3);
      }
    }
  };

  const handleOnAddressChange = (addressType, element, value, obj) => {
    const addrressError = validateAddressForm(obj);
    if (addrressError) {
      const errors = [];
      errors.push(`${addrressError}`);
      setErrors(errors);
      showError(true);
    } else {
      showError(false);
    }
    setState({
      ...state,
      [addressType]: obj,
    });
  };

  const handleChange = key => value => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const submitForm = e => {
    e.preventDefault();
    setInProgress(true);
    const formData = {};
    let firstName = '';
    let lastName = '';
    const nameObj = state.name.trim().split(' ');
    if (nameObj.length === 1) {
      firstName = nameObj[0];
      lastName = '';
    } else {
      firstName = nameObj[0];
      let lastnameObjArray = nameObj.slice(1);
      let lastnameObj = lastnameObjArray.join(' ');
      lastName = lastnameObj;
    }
    const name = `${firstName} ${lastName}`;
    formData.name = name.trim();
    formData.city =
      state.address.city === ''
        ? state.address.address_line1
        : state.address.city;
    formData.state = state.address.state;
    formData.streetName = state.address.address_line2;
    formData.id = params.id;
    formData.streetNumber = state.address.address_line1;
    formData.zipCode = state.address.zipcode;
    saveTollAuthority(false, formData, params.id).then(result => {
      if (result.success) {
        alertMessage('ALL CHANGES HAVE BEEN SAVED!');
        setInProgress(false);
      } else {
        if (Array.isArray(result.errors.message.message)) {
          alertMessage(
            result.errors.message.message.toString().toUpperCase(),
            'error',
            3
          );
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
        setInProgress(false);
      }
    });
    setState({});
  };

  return (
    <div>
      <p className='PageTitle'>
        <Row type='flex' justify='space-between'>
          {params.name !== 'admin' && (
            <span className='profileTitle'>{params.name}</span>
          )}
          {isEditProfile ? (
            <Button
              className='AddButton'
              onClick={() => {
                handleProfile('edit');
              }}>
              {params.name !== undefined ? 'EDIT PROFILE' : 'ADD PROFILE'}
            </Button>
          ) : (
            <Button
              className='btn-style btn-success SaveButton'
              disabled={
                state.tollAuthority === '' ||
                state.email === '' ||
                state.address.zipcode === '' ||
                state.address.state === '' ||
                state.address.country === '' ||
                state.address.city === '' ||
                state.address.country === '' ||
                state.address.address_line1 === '' ||
                state.telephone === '' ||
                state.website === '' ||
                state.name === '' ||
                state.role === '' ||
                error ||
                state.address === '' ||
                !re.test(String(state.email).toLowerCase())
              }
              onClick={() => {
                handleProfile('save');
              }}>
              SAVE
            </Button>
          )}
        </Row>
      </p>
      <div className='Basecontainer'>
        <TollForm
          handleChange={handleChange}
          handleOnAddressChange={handleOnAddressChange}
          handleRoleChange={handleRoleChange}
          submitForm={submitForm}
          handleTollAdminChange={handleTollAdminChange}
          state={state}
          roles={roles}
          tollAdmins={tollAdmins}
          errors={errors}
          error={error}
          hideCreate
          disableBtn={disableBtn}
          disableFields={disableFields}
          isEditForm={true}
        />
      </div>
    </div>
  );
};

export default Profile;

/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect,useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Spin } from '../../../common/AntdUiComponents';
import { fetchTripHistoryList } from '../../../api/user';
import { fetchEntTripHistoryList } from '../../../api/enterprise/enterprise';
import TripHistoryList from './TripHistoryList';
import { alertMessage,sorters } from '../../../common/Common';
import ReuseInputField from '../ReuseInputField';
import debounce from 'lodash.debounce';
import moment from 'moment-timezone';

const _expandedRowKeys = new Set();



const NestedTable = ({ record }) => {
  const [showTripDetails, setshowTripDetails] = useState(false);
  const showtripDetails = () => {
    setshowTripDetails(true);
  };
  const hidetripDetails = () => {
    setshowTripDetails(false);
  };

  const convertDateToTimeZone = (date, timezone) => {
    const unConvertedDate = moment(date);
    if (timezone) {
      return unConvertedDate.tz(timezone).format('MM/DD/YYYY hh:mm A');
    }
    return unConvertedDate.format('MM/DD/YYYY hh:mm A');
  };
  

  return (
    <Row>
      {!showTripDetails && (
        <u
          onClick={() => showtripDetails()}
          role="link"
          tabIndex="0"
          className="pointer"
        >
          Trip Details
        </u>
      )}
      {showTripDetails && (
        <>
          <table className='tripTable' style={{ width: '100%', marginBottom: 10 }}>
            <tr style={{paddingLeft: 0}}>
              <th style={{paddingRight: 0, backgroundColor: '#04AA6D', color: 'white'}}>Facility</th>
              <th>Plaza</th>
              <th>Transaction Date</th>
              <th>Charge</th>
            </tr>
            {record.map((data, index) => {
              return (
                <tr>
                  <td style={{ paddingLeft: 0, width: "30%" }}>{data.facility}</td>
                  <td style={{ width: "30%" }}>{data.plaza}</td>
                  <td style={{ width: "30%" }}>{convertDateToTimeZone(data.tollPassTime, data.timezone)}</td>
                  <td style={{ width: "10%" }}>{data.validated? data.charge: "PENDING"}</td>
                </tr>
              )
            })}
          </table>
          <u
            onClick={() => hidetripDetails()}
            role="link"
            tabIndex="0"
            className="pointer"
          >
            Hide Details{' '}
          </u>
        </>
      )}
    </Row>
  );
};

const TripHistory = ({ onSelectTitle, name, data, type }) => {
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const [selectableObj, setSelectableObj] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const [userData, setEnterprisesUserData] = useState([]);
  const [expandedRowKeys, setexpandedRowKeys] = useState([]);
  const [perPage,setPerPage] = useState(20)
  const [pagination, setPagination] = useState({});
  const [sortKey,setSortKey] = useState('')
  const [sortOrder,setSortOrder] = useState('')
  const [searchField,setSearchField] = useState('')


  let tableOptions = { pagination: {}, filters: {}, sorter: {} };

  useEffect(() => {
    fetchTripHistoryDetails(searchField,perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTripHistoryDetails = (searchField,pageSizeCount,sortkey,sorter) => {
    setInProgress(true);
    const page = tableOptions.pagination.current;

    if (type === 'user') {
      fetchTripHistoryList(params.id,searchField,page,
        pageSizeCount,sortkey,sorter).then((result) => {
        if (result.success) {
          const resultData = [];
          const userRecords = result.records
          const pagination = result.pagination
          setPagination(pagination);
          for (let i = 0; i < result.records.length; i += 1) {
            // eslint-disable-next-line no-param-reassign
            userRecords[i].detailedTransaction.forEach(txn => {
              txn.charge = "$".concat(txn.charge).concat("0");
            });
            result.records[i].key = result.records[i].tagId;
            resultData.push(result.records[i].tagId);
          }
          setEnterprisesUserData(userRecords);
          setexpandedRowKeys(resultData);
          setInProgress(false);
        } else {
           setPagination(null);
          setEnterprisesUserData([]);
          setInProgress(false);
        }
      });
    } else {
      fetchEntTripHistoryList(params.id,searchField,page,
        pageSizeCount,sortkey,sorter).then((result) => {
          const resultData = [];
        if (result.success) {
          console.error("OOOO", result)
          const entRecords = result.records;
          for (let i = 0; i < result.records.length; i += 1) {
            // eslint-disable-next-line no-param-reassign
              entRecords[i].detailedTransaction.forEach(txn => {
              txn.charge = txn.charge == 0? "$0" : "$".concat(txn.charge).concat("0");
            });
            result.records[i].key = result.records[i].tagId;
            resultData.push(result.records[i].tagId);
          }
          
          const pagination = result.pagination;
          setPagination(pagination);
          setexpandedRowKeys(resultData);
          setEnterprisesUserData(entRecords);
          setInProgress(false);
        } else {
          setPagination(null);
          setEnterprisesUserData([]);  
          setInProgress(false);
        }
      });
    }
  };

  const handleSubmit = (e, obj, types) => {
    e.preventDefault();
    const objData = { ...obj, types };
    setSelectableObj(objData);
    setVisible(!visible);
  };
  const downloadreport = () => {

  };

  const handleTableChange = (pagination, filters, sorter) => {
    setInProgress(true)
    setEnterprisesUserData([])
    tableOptions = { pagination, filters, sorter };
    setPerPage(pagination.pageSize)
    let sorterOrder='';
    let sorterkey=''
    sorter.field = sorter.field === 'name' ? 'firstName':sorter.field
        sorterOrder = sorters(sorter.order);
      sorterkey = sorters(sorter.order)===''?'': sorter.field;
       setSortKey(sorters(sorter.order)===''?'': sorter.field);
       setSortOrder(sorters(sorter.order))
       fetchTripHistoryDetails(searchField,pagination.pageSize,sorterkey,sorterOrder);
      // debouncedSave(searchField,pagination.pageSize,sorterkey,sorterOrder);

  };

  const _onExpand = (expanded, record) => {
    _toggleExpandByCaseId(record.key);
  };

  const onRowClick = (key) => {
    _toggleExpandByCaseId(key);
  };

  const _toggleExpandByCaseId = (caseId) => {
    _expandedRowKeys.has(caseId)
      ? _expandedRowKeys.delete(caseId)
      : _expandedRowKeys.add(caseId);
    setexpandedRowKeys(Array.from(_expandedRowKeys.values()));
  };

  const _expandRowRender = (record, index, indent) => {
    return <NestedTable record={record.detailedTransaction} />;
  };

  const handleSearch = (value) => {
    setEnterprisesUserData([]);
    setInProgress(true)
    setSearchField(value);

    debouncedSave(value,5,'','');


  }

  const debouncedSave = useCallback(
    debounce((value,page,sortBy,sortOrder) =>  {
      setEnterprisesUserData([]);
      fetchTripHistoryDetails(value,page,sortBy,sortOrder)}, 1000),
    [], // will be created only once initially
  );


  return (
    <div>
      <Spin spinning={inProgress}>
        <p className="ContainerTitleHeader">
          <span className="PageTitle">Trip History</span>
          <ReuseInputField placeholder="Search..." className="SearchBar"  onChange={handleSearch} />
        </p>
        <div className="Basecontainer">
          <TripHistoryList
          downloadStmnt={downloadreport}
            checkpossible={onRowClick}
            handleSubmit={handleSubmit}
            data={userData}
            type={type}
            pagination={
              pagination !== null
                ? {
                    total: parseInt(pagination.totalCount, 10),
                    current: parseInt(pagination.currentPage, 10),
                    pageSize: parseInt(pagination.perPage, 10),
                    pageSizeOptions: [5,10,20],
                  }
                : {}
            }
            tableChange={(pagination, filters, sorter) =>
              handleTableChange(pagination, filters, sorter)
            }
            expandable={{
              expandedRowKeys,

              onExpand: (expanded, record) => {
                return _onExpand(expanded, record);
              },
              expandedRowRender: (record, index, indent, expanded) => {
                return _expandRowRender(record, index, indent);
              },
              expandIcon: ({ expanded, onExpand, record }) => {
                return expanded ? '' : '';
              },
            }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default TripHistory;

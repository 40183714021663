import React from 'react'
import {Button} from '../../../src/common/AntdUiComponents';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportCSV = ({csvData, fileName,type,name}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (dataCsV, fileName) => {
        const formattedCsvData = dataCsV.map( function( item ){
            delete item.key
            for(var key in item){
                if(key!=='key'){
                    var upper = key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });
                    // check if it already wasn't uppercase
                    if( upper !== key ){
                        item[ upper ] = item[key];
                        delete item[key];
                    }
                }
            }
            return item;
        });
        console.log(formattedCsvData)
        const ws = XLSX.utils.json_to_sheet(formattedCsvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button variant="warning"  className=" btn-style btn-info enterpriseOptionbuttons templateText templateBtn" onClick={(e) => exportToCSV(csvData,fileName)}>{name}</Button>
    )
}

export default ExportCSV;

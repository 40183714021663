import React, { useEffect, useState } from 'react';
import {Row, Col, Table, Icon, Button, Modal} from '../../common/AntdUiComponents';
import {
  freezeAccount,
  fetchEnterpriceUserById,
  deleteEnterpriceUserById,
} from '../../api/enterprise/enterprise';
import ReuseButton from "../../utils/reusableComponents/ReuseButton";
import {alertMessage} from "../../common/Common";
import {useHistory, useParams} from 'react-router-dom';
import { handleBreadcrumbLinks } from '../../reducer/actions/actions';
import { useSelector, useDispatch} from 'react-redux';

const CategoryList = ({ onSelectTitle, entName, userId, dataValue }) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [enterPrise, setEnterprise] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectableObj, setSelectableObj] = useState('');
  const dispatch = useDispatch();
  const breadcrumbLinks = useSelector(state => state.breadcrumb_links.links);

  const dataSource = [
    {
      key: '1',
      name: 'Users',
      age: 32,
    },
    {
      key: '2',
      name: `Trip History & Statements`,
      age: 42,
    },
    {
      key: '3',
      name: 'Payment Method',
      age: 42,
    },
    {
      key: '4',
      name: 'Profile',
      age: 42,
    },
  ];

  const handleTitleChange = (name, nextState, id) => {
    var links = breadcrumbLinks.slice();
    links.push(nextState);
    dispatch(handleBreadcrumbLinks(links));
    onSelectTitle(name, 'info', nextState, id);
  };

  useEffect(() => {
    var links = ['Overview', 'Account'];
    dispatch(handleBreadcrumbLinks(links));
    if (dataValue !== '') {
      const filteredData = dataSource.filter(
        (datas) => datas.name.toLowerCase().includes(dataValue.toLowerCase()),
      );
      setData(filteredData);
    } else {
      setData(dataSource);
    }
    getEnterpriseUserById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: (text, data) => (
        <span style={{ textTransform: 'capitalize', textAlign: 'right' }}>
          <Icon
            type="arrow-right"
            style={{ fontSize: '30px' }}
            onClick={() => handleTitleChange(entName, data.name)}
          />
        </span>
      ),
      align: 'right',
    },
  ];

  const getEnterpriseUserById = () => {
    fetchEnterpriceUserById(userId).then(result => {
      if (result.success) {
        setEnterprise(result.data);
      }
    });
  };

  const handleSubmit = (e, obj, type, status) => {
    e.preventDefault();
    const objData = { ...obj, type };
    setSelectableObj(objData);
    setVisible(!visible);
  };

  const handleModalCancel = modalType => {
    setSelectableObj('');
    setVisible(!visible);
  };

  const renderModal = () => {
    let title;
    let description;
    let btnText;
    let btnType;
    // eslint-disable-next-line no-shadow
    if (selectableObj.type === 'deleteUserAccount') {
      title = 'Do you want to delete the account?';
      description =
          'If you delete the account all tags attached to this account will be deactivated forever. This is irreversible.';
      btnText = 'DELETE ACCOUNT';
      btnType = 'btn-danger';
    } else if (selectableObj.type === 'freezeUserAccount') {
      title =
          enterPrise.freeze === 0
              ? 'Do you want to freeze the account?'
              : 'Do you want to unfreeze the account?';
      description =
          enterPrise.freeze === 0
              ? 'If you freeze the account all tags attached to this account will be deactivated till the account is unfrozen.'
              : 'You are about to unfreeze this account';
      btnText =
          enterPrise.freeze === 0 ? 'FREEZE ACCOUNT' : 'UNFREEZE ACCOUNT';
      btnType = 'btn-info';
    }
    return (
        <Modal
            className='toll-authority'
            title={title}
            visible={visible}
            width='500px'
            footer={null}
            onCancel={() => {handleModalCancel(selectableObj.type);}}>
          <div className='modalDescrition'>
            <p>Disclaimer:</p>
            <p>{description}</p>
          </div>
          <Row type='flex' justify='center'>
            <ReuseButton
                text={btnText}
                className={`btn-style ${btnType}`}
                onClick={e => {
                  handleModalType(selectableObj.type, userId);
                }}
            />
          </Row>
        </Modal>
    );
  };

  const handleModalType = (modalType, accId) => {
    if (modalType === 'freezeUserAccount') {
      const user = localStorage.getItem('user');
      const userDetails = user !== '' ? JSON.parse(user) : {};
      freezeAccount(accId, userDetails.id).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          getEnterpriseUserById();
          if (result.data.freeze === 0) {
            alertMessage('THE ACCOUNT HAS BEEN UNFROZEN', 'info', 3);
            getEnterpriseUserById();
          } else {
            if(userDetails.userType === 'Enterprise'){
              getEnterpriseUserById();
            }
            alertMessage('THE ACCOUNT HAS BEEN FROZEN', 'warning', 3);
          }
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
    if (modalType === 'deleteUserAccount') {
      deleteEnterpriceUserById(accId).then(result => {
        if (result.success) {
          setSelectableObj('');
          setVisible(!visible);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          history.push('/unytag/web/');
          window.location.reload(false);
        } else {
          alertMessage(result.errors.message.message.toUpperCase(), 'error', 3);
        }
      });
    }
  };

  return (
    <div className="Basecontainer">
      <Row gutter={30}>
        <Col span={24} className="tablePadding mar-top-10">
          <p className="TitleText mar-bottom-20">
            View everything that has happened with the enterprise customer here.
          </p>
          <Table
            className="BaseTable userCategories"
            dataSource={data}
            pagination={false}
            columns={columns}
            showHeader={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleTitleChange(entName, record.name, userId);
                },
              };
            }}
          />
        </Col>
      </Row>
      <Row type="flex" justify="space-between" style={{margin: 20}}>
        <Col xs={16} className="">
          <Button
              className={`btn-style enterprisebuttons  ${
                  enterPrise.freeze === 0
                      ? 'btn-info'
                      : 'btn-freeze'
              }  `}
              onClick={e => {
                handleSubmit(e, '', 'freezeUserAccount');
              }}
          >
            {enterPrise.freeze === 0 ? 'FREEZE ACCOUNT' : 'UNFREEZE ACCOUNT'}
          </Button>
          <Button
              className="btn-style btn-danger enterprisebuttons"
              onClick={(e) => {
                handleSubmit(e, '', 'deleteUserAccount', userId);
              }}
          >
            DELETE ACCOUNT
          </Button>
        </Col>
      </Row>
      {visible && renderModal()}
    </div>
  );
};

export default CategoryList;
